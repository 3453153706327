import { forgotPasswordRequest } from "../components/forgot-password/helpers/api";
import { showForgotPasswordError, showForgotPasswordEmailError, getTranslatedAlertTextFromErrorCode } from "../components/forgot-password/helpers/alert";
import { clearAllAlerts } from "../components/helpers/alert";
import { showLoading, hideLoading } from "../components/helpers/loading";

export const ForgotPasswordPage = async () => {
  document.querySelector('#forgot-password').addEventListener('submit', async (e) => {

    e.preventDefault();
    const emailElement = document.querySelector('#email');
    const email = emailElement.value;

    clearAllAlerts();

    if (email.length > 0) {
      showLoading();

      const forgotPasswordResponse = await forgotPasswordRequest(email);

      if (forgotPasswordResponse?.status){
        window.location.href = _l('/password/forgot-done/');
      } else {
        hideLoading();

        if (forgotPasswordResponse?.error_code) {
            errorMessage = await getTranslatedAlertTextFromErrorCode(forgotPasswordResponse?.error_code)
        } else {
            errorMessage = forgotPasswordResponse?.error
        }

        showForgotPasswordError(errorMessage);
      }
    } else {
      showForgotPasswordEmailError();
    }
  });
}
