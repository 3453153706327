export const showForgotPasswordError = async (text = null) => {
  await useNamespace('common_login');
  const formErrorElement = document.querySelector('.error-box-form');
  const formErrorTextElement = formErrorElement.querySelector('.error');
  formErrorElement.classList.remove('hidden');
  if (text) {
    formErrorTextElement.innerHTML = text;
  } else {
    formErrorTextElement.innerHTML = _t('common_forgot-password:errors.forgot-password-error')
  }
}

export const showForgotPasswordEmailError = () => {
  const emailElement = document.querySelector('#email');
  const emailInputErrorElement = document.querySelector('.error-email');
  emailElement.classList.add('is-invalid');
  emailInputErrorElement.classList.remove('hidden');
}

export const getTranslatedAlertTextFromErrorCode = async (errorCode = null) => {
    await useNamespace('common_forgot-password');
    return _t('common_forgot-password:errors.forgot-password-error');
}
