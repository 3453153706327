import { tracking } from "../analytics";

export default function () {
    var header = $('.bark-header');

    var lastScrollTop = 0;
    var lastDir;
    var performedHeaderAction = false;

    var searchPlaceholder = _t("common:search-for-service-label");
    // var headerTimeout = null;

    function setHeaderLastValues(lastDirection, lastScroll) {
        lastDir = lastDirection;
        lastScrollTop = lastScroll;
    }

    function setHeaderAutocompletePosition()
    {
        var cat_field = $('#category_name_top');
        var autocompleteResults = $('.streamcomplete-body.results-categorynametop');

        if (cat_field.length && cat_field.is(':visible')) {
            autocompleteResults.css({'width': cat_field.outerWidth(), 'left': cat_field.offset().left});
        } else if (autocompleteResults.length) {
            autocompleteResults.hide();
        }
    }

    $('#category_name_top').on('focusin', function(e) {
        var t = $(this);
        $('.streamcomplete-body').addClass('abovenav');

        if (t.val().length < 1) {
            t.removeData('value streamcompleteItem').val('');
            t.data('streamcomplete').results = [];
        }

        $('#category_name_top').attr('placeholder', '');

    });

    $('#category_name_top').on('focusout', function(e) {
        var t = $(this);
        $('.streamcomplete-body').removeClass('abovenav');

        $('#category_name_top').attr('placeholder', searchPlaceholder);

    });

    $('#category_name_top').on('keyup.focus-style', function (e) {
        var t = $(this),
            container = t.parent('.category_name_top_container');
        if (t.val().length) {
            container.addClass('has-content');
            if (!t.is(":focus")) {
                t.attr('placeholder', searchPlaceholder);
                t.removeData('value streamcompleteItem').val('');
                t.data('streamcomplete').results = [];
            }
        } else {
            container.removeClass('has-content');
        }
    });

    $('.country-selection-button').on('click', function () {
        $('#countrySelectModal').modal();
    });

    $(".dropdown-menu a.dropdown-item[href$='barkpro.app.link/website']").on(
		'click',
		function () {
            tracking(
                'Experiment',
                'seller_app_dashboard_prompt - Click menu link',
                {
                    seller_profile_id: window.Bark.seller.id,
                    country_id: window.Bark?.ENV?.ccid,
                    besToken: window.Bark?.ENV?.bes_token,
                }
            );
        },
    );

    $(window).resize(function() {
        setHeaderAutocompletePosition();
    });

    $(document).scroll(function () {
        var st = $(this).scrollTop();   // @todo can I suggest an offset here. If on a sensitive mouse, looks buggy

        var dir = st > lastScrollTop ? 'down' : 'up';
        var headerOffsetTop = header.length ? header.offset().top : 0;
        var headerHeight = header.length ? header.height() : 0;

        if ($('.user-account-dropdown').hasClass('show')) {
            // If the menu is showing, don't do anything (good call Henry)
            return;
        }

        if (dir === lastDir && performedHeaderAction && st > headerHeight) {
            // If the scroll top is below the header height and the direction hasn't changed, so stop here
            setHeaderLastValues(dir, st);
            return;
        }

        performedHeaderAction = false;

        if (dir === 'down') {
            if (headerOffsetTop > headerHeight) {


                if (!performedHeaderAction) {
                    $('li.post_bark_top').fadeIn();
                    performedHeaderAction = true;
                }
            }
        } else {

            if (headerHeight > headerOffsetTop) {
                $('li.post_bark_top').fadeOut();
                $('.streamcomplete-body.results-categorynametop').hide();
                performedHeaderAction = false;
            }
        }

        setHeaderLastValues(dir, st);
    });
}
