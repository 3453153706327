export const showEnquiriesModal = (
	sellerId,
	companyName,
	companyNumber,
	companyNumberFormatted,
	projectId,
	page,
	projectResponseHashId
) => {
	if (window.BuyerApp) {
		window.BuyerApp.launchModule('enquiries-modal', '__enquiries-modal', {
			sellerId,
			companyName,
			companyNumber,
			companyNumberFormatted,
			projectId,
			page,
			projectResponseHashId,
            featureFlags: {
                'enquiries-buyer-feedback-modal': isEnquiriesBuyerFeedbackModalActive()
            }
		});
	}
};

export const isEnquiriesBuyerFeedbackModalActive = () => {
    return $('#enquiries-buyer-feedback-modal').length > 0 || $('#enquiries-buyer-feedback-modal')?.val() === "1";
}
