import { useNamespace, _t } from '../../libs/i18next';

export default async function AdditionalDetailsWithProgress() {
    const maxCharacters = 160;
    const goodCharacters = 15;
    const bestCharacterLength = 50;
    let state = {
        currentStatus: null
    }

    await useNamespace(['buyer_create-bark_additional-details']);

    const bindListeners  = () => {
        $('#additional-details-modal-with-progress .project-detail-textarea').on('keyup paste', function(e) {
            let characters = getCharactersTypedByUser();
            updateProgressBar(characters);
        });

        $('#additional-details-modal-with-progress').on('show.bs.modal', function (e) {
            const { Bark: {initModalUpload} } = window
            const $dropZoneContainer = $('#exp-dropzone-container');

            if ($dropZoneContainer.length && !$dropZoneContainer.data('dropzoned')) {
                initModalUpload(
                    '#exp-dropzone-container',
                    null,
                    '.js-additional-details-file-container',
                    '#exp-dropzone-previews',
                    '.exp-clickable-btn'
                );
                $dropZoneContainer.data({dropzoned: true});
            }
        });


        $('#exp-dropzone-container').on('modalUploadSuccess modalUploadRemovedFile', () => {
            updateViewAfterFileUpload();
        })
    }

    const getCharactersTypedByUser = () => {
        return $('#additional-details-modal-with-progress .project-detail-textarea').val().length;
    }

    const getGoodPercent = (goodCharacterSize, maxCharacterSize) => {
        return goodCharacterSize / maxCharacterSize * 100
    }

    const getBestPercent = (bestCharacterLength, maxCharacterSize) => {
        return bestCharacterLength / maxCharacterSize * 100
    }

    const getStatus = (percent) => {
        let goodPercent = getGoodPercent(goodCharacters, maxCharacters);
        let bestPercent = getBestPercent(bestCharacterLength, maxCharacters);
        let status = 'neutral';

        if (percent >= goodPercent && percent < bestPercent) {
            status = 'good'
        } else if (percent >= bestPercent) {
            status = 'best'
        }

        return status;
    }

    const makeCurveQuadratic = (percent) => {
        let percentFraction = percent / 10;
        let quadratic = (-0.1 * Math.pow(percentFraction, 2)) + (2*percentFraction);

        let progress = quadratic * 10;
        return progress;
    }

    const getProgressPercent = (characters, maxCharacters) => {
        let files = getTotalNumberOfSuccessfulFileUploads();
        let uploadScore = fileToCharacterScoreConversion(files);
        let totalScore = characters + uploadScore;

        return  totalScore <= maxCharacters ? totalScore / maxCharacters * 100 : 100;
    }

    const getTotalNumberOfSuccessfulFileUploads = () => {
        return $('#exp-dropzone-previews .dz-success').length
    }

    const fileToCharacterScoreConversion = (numberOfFiles) => {
        const characterScorePerFile = 160;

        return characterScorePerFile * numberOfFiles
    }

    const changeViewBasedOnStatus = (status, oldStatus) => {
        const $progressWrapper = $('.progress');
        const $progressCustom  = $('.progress.custom');
        const $progressBar     = $('.progress-bar');
        const $detailText      = $('.detail-text')
        const qualityScoreData = $('.js-quality-score-section')[0].dataset;

        if (status !== oldStatus) {
            $('.emoji').addClass('d-none');

            for (let property in qualityScoreData) {
                if (property.startsWith('colour')) {
                    $progressBar.removeClass(`bg-${qualityScoreData[property]}`);
                    $progressCustom.removeClass(`bg-${qualityScoreData[property]}`);
                    $progressWrapper.removeClass(`border-${qualityScoreData[property]}`)
                    $detailText.removeClass(`text-${qualityScoreData[property]}`)
                }
            }

            $detailText.removeClass('text-light-grey');

            if (status === 'good') {
                const textColour = qualityScoreData.colourGood === 'grey-100' ? 'light-grey' : qualityScoreData.colourGood;

                $('.emoji.good').removeClass('d-none');
                $progressBar.addClass(`bg-${qualityScoreData.colourGood}`);
                $progressCustom.addClass(`bg-${qualityScoreData.colourGood}`);
                $progressWrapper.addClass(`border-${qualityScoreData.colourGood}`);
                $detailText.text(qualityScoreData.copyGood)
                $detailText.addClass(`text-${textColour}`);
            } else if (status === 'best') {
                const textColour = qualityScoreData.colourBest === 'grey-100' ? 'light-grey' : qualityScoreData.colourBest;

                $('.emoji.best').removeClass('d-none');
                $progressBar.addClass(`bg-${qualityScoreData.colourBest}`);
                $progressCustom.addClass(`bg-${qualityScoreData.colourBest}`);
                $progressWrapper.addClass(`border-${qualityScoreData.colourBest}`);
                $detailText.text(qualityScoreData.copyBest);
                $detailText.addClass(`text-${textColour}`);
            } else {
                const textColour = qualityScoreData.colourDefault === 'grey-100' ? 'light-grey' : qualityScoreData.colourDefault;

                $('.emoji.neutral').removeClass('d-none');
                $progressBar.addClass(`bg-${qualityScoreData.colourDefault}`);
                $progressCustom.addClass(`bg-${qualityScoreData.colourDefault}`);
                $progressWrapper.addClass(`border-${qualityScoreData.colourDefault}`);
                $detailText.text(qualityScoreData.copyDefault);
                $detailText.addClass(`text-${textColour}`);
            }
        }
    }

    const updateProgressBar = (characters) => {
			const qualityScoreData = $('.js-quality-score-section')[0].dataset;
			let progressPercent = getProgressPercent(characters, maxCharacters);
			let status = getStatus(progressPercent);
			let { currentStatus } = state;

			changeViewBasedOnStatus(status, currentStatus);
			let quadraticProgress = makeCurveQuadratic(progressPercent);
			const quadraticProgressWidth = Math.max(
				+qualityScoreData.barMinWidth,
				quadraticProgress,
			);

			if (progressPercent <= 100) {
				$('.progress-bar')
					.attr('aria-valuenow', progressPercent)
					.width(`${quadraticProgressWidth}%`);
			}

			state.currentStatus = status;
		}

    const updateAccordionHeader = (numFiles) => {
        if (numFiles > 0) {
            $('#additional-details-modal-with-progress .accordion-title')
                .text(_t("buyer_create-bark_additional-details:attached-files-number", {count: numFiles}));
        } else {
            $('#additional-details-modal-with-progress .accordion-title').text(_t("buyer_create-bark_additional-details:attach-files-photos"));
        }
    }

    const updateViewAfterFileUpload = () => {
        let characters = getCharactersTypedByUser();
        let numFiles = getTotalNumberOfSuccessfulFileUploads();
        updateAccordionHeader(numFiles);
        updateProgressBar(characters);
    }

    const showAccordion = () => {
        $('#collapseAddExp1').addClass('show');
        $('#AddExp1').removeClass('collapsed');
    }

    $(document).ready(() => {
        if ($(window).width() >= 768) {
            showAccordion()
            $('.project-detail-textarea').attr('rows', 5);
        }

        if (window.outerHeight <= 667) {
            $('.project-detail-textarea').attr('rows', 2);
        }

        bindListeners()
    })
}
