export const initBuyerAppBranchIO = (timeoutSeconds = 10, source = null) => {
	try {
        if (!window?.branch) {
            return;
        }
        // init branchio journey
        window.branch.data((_, data) => {
			const isBuidIdentity = data?.identity?.startsWith('buid_');
			const isMarketingPageSource = source === 'marketing';

			if (isBuidIdentity || isMarketingPageSource) {
				setTimeout(function() {
					window.branch.track('pageview');
				}, timeoutSeconds * 1000);
			}

        });
	} catch (error) {
		console.log(error);
	}
};
