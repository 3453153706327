import Navigo from 'navigo';
import SellerPublicProfilePage from '../pages/seller-public-profile-page';
import { ProspectSignUpPage } from '../pages/prospect-signup-page';
import { LoginPage } from '../pages/login';
import { LoginLinkSentPage } from '../pages/link-sent';
import { LoginSendLinkPage } from '../pages/send-link';
import { ForgotPasswordPage } from '../pages/forgot-password';
import { termsAndConditionsPage } from '../pages/terms-and-conditions-page';
import { ExpiredLinkSentPage } from '../pages/expired-link-resent';
import { initChameleon, useNamespace } from '@bark/bark-core';
import { multiRouteEntrypoint } from '../helpers/multiroute-entrypoint';
import { initBuyerAppBranchIO } from '@bark/bark-core/src/helpers'

export const routerUrl = () => {
	const router = new Navigo('/', { strategy: 'ALL' });

	router
		.notFound(async () => {
			// called when there is path specified but
			// there is no route matching
		})
		.on({ '*': multiRouteEntrypoint })
		.on('/:lang/:locale/', async ({ data }) => {
			try {
				// Some of our pages do not have the proper i18n url structure and just dive straight in,
				// for example /password/forget we don't want to match those routes here.
				const { lang, locale } = data;
				if (lang.length !== 2 || locale.length !== 2) {
					return;
				}

				const { Testimonials, Home } = await import('../components/home');
				const CreateBarkHelpers = await import('../helpers/create-bark-helpers');

				Home();
				Testimonials();
				CreateBarkHelpers.default();
			} catch (e) {
				console.log(e);
			}
		})
		.on('/:lang/:locale/sellers/create', async () => {
			try {
				const { SellerHomepage } = await import('../components/seller');
				const { TestimonialVideo } = await import('../components/testimonials');

				SellerHomepage();
				TestimonialVideo();
				console.log('public sellers create page start..');
			} catch (e) {
				console.log(e);
			}
		})
		.on('/:lang/:locale/sellers/create-account/', async () => {
			try {
				await useNamespace('seller_signup');
				const { SellerSignUpJs } = await import('../components/seller');

				SellerSignUpJs();
				initChameleon();
			} catch (e) {
				console.log(e);
			}
		})
		.on('/:lang/:locale/how-it-works/sellers', async () => {
			try {
				const { SellerHomepage } = await import('../components/seller');

				SellerHomepage();
				initChameleon();
			} catch (e) {
				console.log(e);
			}
		})
		.on('/:lang/:locale/how-it-works/customers', async () => {
			try {
				const { HowItWorksForCustomers } = await import(
					'../components/how-it-works-customers'
				);
				const CreateBarkHelpers = await import('../helpers/create-bark-helpers');

				HowItWorksForCustomers();
				CreateBarkHelpers.default();
			} catch (e) {
				console.log(e);
			}
		})
		.on('/:lang/:locale/company/:companyName/:hashedSellerProfileId', async () => {
			await useNamespace(['seller_public-profile_check-availability-modal']);
			await SellerPublicProfilePage();
			initBuyerAppBranchIO();
		})
		.on('/:lang/:locale/company/:companyName/p/:hashedSellerProfileId', async () => {
			await useNamespace(['seller_public-profile_check-availability-modal']);
			await SellerPublicProfilePage('bookable');
			initBuyerAppBranchIO();
		})
		.on('/:lang/:locale/b/:companyName/:hashedSellerProfileId', async () => {
			await useNamespace(['seller_public-profile_check-availability-modal']);
			await SellerPublicProfilePage();
			initBuyerAppBranchIO();
		})
		.on('/:lang/:locale/slf/p/:companyName/:hashedSellerProfileId', async () => {
			await useNamespace(['seller_public-profile_check-availability-modal']);
			await SellerPublicProfilePage();
			initBuyerAppBranchIO();
		})
		.on('/:lang/:locale/buyers/project-complete/:abandonedProjectId/', async () => {
			await AbandonedBarkJsRoute();
		})
		.on({ '/find-work/*': ProspectSignUpPage })
		.on({ '/:lang/:locale/p/:hashedId': ProspectSignUpPage })
		.on({ '/:lang/:locale/login': LoginPage })
		.on({ '/:lang/:locale/login/send-link': LoginSendLinkPage })
		.on({ '/:lang/:locale/login/link-sent': LoginLinkSentPage })
		.on({ '/:lang/:locale/login/expired-link': ExpiredLinkSentPage })
		.on({ '/:lang/:locale/terms': termsAndConditionsPage })
		.on({ '/:lang/:locale/password/forgot': ForgotPasswordPage })
		.resolve();
};

async function AbandonedBarkJsRoute() {
	const { AbandonedBarkJs } = await import(
		'../components/abandoned-bark/legacy-scripts/abandoned-bark'
	);
	const { initLegacyCreateBark } = await import('@bark/bark-core');

	await useNamespace(['public_abandoned-bark']);

	await initLegacyCreateBark();
	AbandonedBarkJs();
}
