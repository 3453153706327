import { _t } from '../../../../libs/i18next';
import { finishNewRequestFlow } from './new-request-flow';

function validateCategory(category_id) {
	const categoryError = $('#category_error');
	categoryError.hide();
	categoryError.parent().removeClass('new-error');

	if (!Number.parseInt(category_id, 10)) {
		categoryError.text(`* ${_t('common:select-a-service')}`).show();
		categoryError.parent().addClass('new-error');
		return false;
	}

	return true;
}

// as we don't have an event that would indicate that NRF app is loaded
// let's wait till BarkRequestFlow will appear in window
const waitForBarkRequestFlow = () => {
	return new Promise((resolve, reject) => {
		let attempts = 0;
		let intervalId;

		const attemptResolution = () => {
			if (window.BarkRequestFlow !== undefined) {
				clearInterval(intervalId);
				resolve(window.BarkRequestFlow);
			} else {
				attempts += 1;
				if (attempts >= 80) {
					clearInterval(intervalId);
					reject(
						'BarkRequestFlow is not in "window" for more than eight seconds after startBark call',
					);
				}
			}
		};

		attemptResolution();
		intervalId = setInterval(attemptResolution, 100);
	});
};

export async function startBarkV2({
	locationData,
	category_id,
	country_id,
	campaign,
	user,
	bark_mode,
	matchedSellerHashes,
}) {
	if (!validateCategory(category_id)) {
		return;
	}

	try {
		await waitForBarkRequestFlow();

		const { remove, startNewRequestFlowV2 } = window.BarkRequestFlow;

		if (locationData !== null && typeof locationData === 'object') {
			if ([-1, '-1'].includes(locationData.id)) {
				locationData = null;
			}
		}

		return startNewRequestFlowV2({
			locationData,
			category_id,
			country_id,
			campaign,
			origin,
			bark_mode,
			matchedSellerHashes,
			hasModal: true,
			location: '#new-request-flow-widget',
			// onFinish: (newRequestOutput, createBarkResponse) => {
			// Dont think we need this anymore as NRF handles navigation itself, see actions.ts
			// 	finishNewRequestFlow(newRequestOutput, createBarkResponse);
			// },
			onExit: () => remove({ location: '#new-request-flow-widget' }),
			barkInfo: {
				timeZone: window.Bark.ENV.tz,
			},
			siteInfo: {
				locale: window.Bark.ENV.locale,
				lang: window.Bark.ENV.lang,
				JWT: window.Bark.ENV.JWT,
			},
			userData: user,
		});
	} catch (e) {
		bugsnagClient.notify(new Error('Failed to start NRF app V2'), { metaData: e });
	}
}
