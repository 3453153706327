import { tracking } from '../components/analytics';

export const isUSTaxComplianceActive = () => {
    return $('#tax-compliance-header').length > 0 || $('#tax-compliance-exp-v')?.val() === "1";
}

export const isUSTaxCompliant = async () => {
    try {
        const result = await getBillingAddress();
        if (result?.status && result?.data && result?.data?.address_source == 'compliance_address') {
            return true;
        }
        return false;
    } catch (e) {
        console.error(e);
        bugsnagClient && bugsnagClient.notify(
            new Error(
                `Could not retrieve the billing address from the api`,
            ),
        );
        return false;
    }
}

const complianceState = {
    googleMapNotReadyRetryCount: 0,
}

const getBillingAddress = async () => {
    try {
        return await Bark.api('seller/billing-address');
    } catch (e) {
        console.error(e);
        bugsnagClient && bugsnagClient.notify(
            new Error(`Could not retrieve the billing address from the api`)
        );
        return false;
    }
}

export const saveBusinessAddress = (context = "Sign up",  callback = null) => {
    tracking(
        'Wayfair',
        'US Tax Compliance - Business address - submit',
        {
            context: context
        },
    );
    let address_1 = $('#business_formatted_address').val().trim();

    if ($('#suite_apartment').val().trim()) {
        address_1 = $('#suite_apartment').val().trim() + ', ' +address_1;
    }
    const billingName = $('#name').length > 0 ? $('#name').val().trim() : Bark?.sellerSelfObject?.seller_name.trim();

    let payload = {
        billing_contact_name: billingName,
        billing_address_1: address_1.trim(),
        billing_address_2: $('#business_state').val().trim(),
        billing_city: $('#business_city').val().trim(),
        billing_postcode: $('#business_zipcode').val().trim(),
        place_provider_type: 'Google Places',
        place_provider_id: $('#business_place_id').val().trim(),
        address_source: 'compliance_address'
    };

    if($('#business_zipplus4')?.val()?.trim() ?? false) {
        payload.billing_postcode_suffix = $('#business_zipplus4').val().trim();
    }

    if($('#business_state')?.val()?.trim() ?? null) {
        payload.billing_state = $('#business_state').val().trim();
    }


    Bark.api('seller/billing-address', payload, function (e) {
        tracking(
            'Wayfair',
            'US Tax Compliance - Business address - success',
            {
                context: context
            },
        );
        callback && callback();
        }, function (error) {
            window.bugsnagClient.notify(error);
            tracking(
                'Wayfair',
                'US Tax Compliance - Business address - fail',
                {
                    context: context
                },
            );
            Bark.hideLoading();
        }, 'POST');
}

export const initializeGooglePlacesAutoComplete = () => {

    if (typeof google !== 'undefined' && typeof google.maps !== 'undefined' && typeof google.maps.places !== 'undefined') {
        const input = document.getElementById('street_address');
        const options = {
            componentRestrictions: {country: 'us'},
            types: ['address']
        };
        const autocompletetop = new google.maps.places.Autocomplete(input, options);
        autocompletetop.addListener('place_changed', fillInAddressTop);

        function fillInAddressTop() {
            var place = autocompletetop.getPlace();
            let zip_code_component;
            let city_component;
            let state_component;
            let zipplus4_component;

            if (place.address_components !== undefined && place.address_components.length > 0) {
                $('#street_address').val(place.address_components[0].long_name + ' ' + place.address_components[1].long_name);
                zip_code_component = place.address_components.filter((component) => {
                    return component.types?.includes('postal_code');
                })[0];
                city_component = place.address_components.filter((component) => {
                    return component.types?.includes('locality');
                })[0];
                if (!city_component) {
                    city_component = place.address_components.filter((component) => {
                        return component.types?.includes('sublocality');
                    })[0];
                    if (!city_component) {
                        city_component = place.address_components.filter((component) => {
                            return component.types?.includes('administrative_area_level_3');
                        })[0];
                        if (!city_component) {
                            tracking(
                                'Wayfair',
                                'US Tax Compliance - sublocality and administrative_area_level_3 not present'
                            );
                        } else {
                            tracking(
                                'Wayfair',
                                'US Tax Compliance - sublocality not present'
                            );
                        }
                    }
                }
                state_component = place.address_components.filter((component) => {
                    return component.types?.includes('administrative_area_level_1');
                })[0];
                if(!state_component) {
                    tracking(
                        'Wayfair',
                        'US Tax Compliance - state (administrative_area_level_1) not present'
                    );
                }

                zipplus4_component = place.address_components.filter((component) => {
                    return component.types?.includes('postal_code_suffix');
                })[0];
                if(!zipplus4_component) {
                    tracking(
                        'Wayfair',
                        'US Tax Compliance - zipplus4 (postal_code_suffix) not present'
                    );
                }
            }

            if (place.address_components === undefined || zip_code_component === undefined || place.address_components.length < 6) {
                $('#business_address_error').removeClass('d-none');
                $('#next-button-business-address').prop('disabled', true);
                $('#business_address_details').addClass('d-none');
            } else {
                $('#business_zipcode').val(zip_code_component.long_name);
                if(zipplus4_component) {
                    $('#business_zipplus4').val(zipplus4_component?.long_name ?? '');
                }
                $('#business_state').val(state_component.long_name ?? '');
                if(state_component) {
                    $('#business_state').val(state_component?.long_name ?? '');
                }
                $('#business_city').val(city_component.long_name);
                $('#business_formatted_address').val(place.address_components[0].long_name + ' ' + place.address_components[1].long_name);
                $('#business_place_id').val(place.place_id);
                $('#business_address_details').removeClass('d-none');
                $('#business_address_error').addClass('d-none');
                $('#next-button-business-address').prop('disabled', false);
            }
        }
    } else if (complianceState.googleMapNotReadyRetryCount > 25) {
        bugsnagClient.notify(
            new Error(`Waited for 5 seconds but Google Maps was still not available.`)
        );
    } else {
        complianceState.googleMapNotReadyRetryCount++;
        setTimeout(initializeGooglePlacesAutoComplete, 200);
    }
}

export const continueToBusinessAddress = (context = "Sign up") => {

    $(document).scrollTop(0);
    if (!$('#street_address').val() && !Bark.isMobile()) {
        $('#street_address').focus();
    }

    tracking(
        'Wayfair',
        'US Tax Compliance - Business address - view',
        {
            context: context
        },
    );
}
