import { tracking } from './bark-tracking';

export function init() {
	const HeapTracking = getServerSideHeapevents();

	if (HeapTracking?.length > 0) {
		HeapTracking.forEach(({ name, value, params }) => {
			tracking(name, value, params);
		});
	}
}

// functions

const getServerSideHeapevents = () => {
	const HeapTracking = (window.Bark.heapTracking = window.Bark.heapTracking || []);
	return HeapTracking;
};
