export function BarkBuyerNotifications() {
	//Hits old long polling ajax endpoint, gets dashboard status
	function getCombinedUpdates(since, frequency) {
		var data = {
			since: since,
			dashboard: true,
			notifications: true,
			messages: false,
			skip_poll: true,
		};

		$.ajax({
			type: 'POST',
			url: '/buyers/user_combined_ajax/',
			data: data,
			dataType: 'json',
			statusCode: {
				404: function () {},
			},
			timeout: 25000,
			success: function (data) {
				since = data.timestamp;
				if (data.values.length) {
					$.each(data.values, function (key, val) {
						if (val.type == 'buyer_dashboard') {
							updateBuyerDash(key, val);
						}
					});
				}

				if (data.notifications.length) {
					$.each(data.notifications, function (key, val) {
						applyNotifications(val);
					});
					updateBuyerNotificationCount();
				}
			},
		});
	}

	function updateBuyerNotificationCount(responseId) {
		if (!$('#buyer-messenger').length) {
			var notificationCount =
				$('#buyer-notifications').find('li.notification-item').length;
			$('.bark-notification-count').text(notificationCount);
			if (parseInt(notificationCount) > 0) {
				$(
					'.buyer-notification,.bark-notification-count,.notification-count-container',
				).show();
				$('.bark-main-menu-mob .bark-notification-count').css({
					display: 'inline-block',
				});
				$('.notification-count-container .text').text(
					'new notification' + (notificationCount > 1 ? 's' : '') + '!',
				);
			} else {
				$('.buyer-notification,.bark-notification-count').hide();
			}
		}

		if (
			$('#buyer-messenger').length &&
			parseInt($('.messenger-leftnav>ul').attr('data-active-id')) != parseInt(responseId)
		) {
			var obj = $(".messenger-leftnav>ul>li[data-response-id='" + responseId + "']").find(
				'.message-new',
			);
			obj.text(parseInt(obj.text()) + 1).show();
		}
	}

	function updateBuyerDash(key, val) {
		var element = $(".bark-container[data-project-id='" + val.project_id + "']");
		element
			.removeClass(
				'status_closed status_new status_finding status_contacting status_live status_rejected status_awaiting-detail',
			)
			.addClass('status_' + val.status);
		if (val.total_responses > 0 || val.seller_suggest_count > 0) {
			if (val.total_responses > 0) {
				element.find('.dashboard_response_count span.num').text(val.total_responses);
				element.find('.dashboard_response_count2').text(val.total_responses);
				element.find('.dashboard_response_count').css('display', 'inline-block');
				element.find('.dashboard_suggestion_count').css('display', 'none');
				element
					.find('.dashboard_response_wording .singular')
					[+val.total_responses === 1 ? 'show' : 'hide']();
				element
					.find('.dashboard_response_wording .plural')
					[+val.total_responses === 1 ? 'hide' : 'show']();
			} else {
				element
					.find('.dashboard_response_wording .singular')
					[+val.seller_suggest_count === 1 ? 'show' : 'hide']();
				element
					.find('.dashboard_response_wording .plural')
					[+val.seller_suggest_count === 1 ? 'hide' : 'show']();
				element.find('.dashboard_response_count').css('display', 'none');
				element.find('.dashboard_suggestion_count').css('display', 'inline-block');
			}
		}

		if (+val.total_unread === 1) {
			element.find('.dashboard_message_wording .singular').show();
			element.find('.dashboard_message_wording .plural').hide();
		} else {
			element.find('.dashboard_message_wording .singular').hide();
			element.find('.dashboard_message_wording .plural').show();
		}
		if (val.total_unread > 0) {
			element.find('.dashboard_message_wording').parent().show();
		}
		if (val.total_prospects + val.total_alerts === 1) {
			element.find('.dashboard_prospect_wording .singular').show();
			element.find('.dashboard_prospect_wording .plural').hide();
			if (val.unread_name !== null) {
				element.find('.status_company_name').text(val.unread_name);
			}
		} else {
			element.find('.dashboard_prospect_wording .singular').hide();
			element.find('.dashboard_prospect_wording .plural').show();
		}

		if (element.hasClass('status_live') && val.total_responses == 0) {
			element.find('.dashboard_response_count_block').hide();
		} else if (element.hasClass('status_live') && val.unreplied_responses > 0) {
			if (+val.unreplied_responses === 1) {
				element.find('.dashboard_unread_wording .singular').hide();
				element.find('.dashboard_unread_wording .plural').show();
			} else {
				element.find('.dashboard_unread_wording .singular').show();
				element.find('.dashboard_unread_wording .plural').hide();
			}
			element.find('.unread-message').css('display', 'inline-block');
			element.find('.bark-warning').show();
		} else {
			element.find('.unread-message').hide();
			element.find('.bark-warning').hide();
		}

		if (val.status === 'contacting' || element.hasClass('status_live')) {
			element.find('.dashboard-close-bark').css('display', 'inline-block');
			element.find('.dashboard-hired-someone').css('display', 'inline-block');
		}

		if (parseInt(val.has_hired_feedback) == 0 && val.status === 'closed')
			element.find('.dashboard-hired-someone').css('display', 'inline-block');

		if (val.out_of_hours) {
			element.find('.bark-after-hours-alert').show();
			element.find('.dashboard_response_count').css('display', 'inline-block');
			element.find('.dashboard_suggestion_count').css('display', 'none');
		} else {
			element.find('.bark-after-hours-alert').hide();
		}

		element
			.find('.contacting_prospect_count')
			.text(parseInt(val.total_prospects) + parseInt(val.total_alerts));
		if (val.total_unread) {
			element.find('.unread-message').css('display', 'inline-block');
			element
				.find('.status_message_count')
				.text(
					Bark.sprintf(
						'%d new repl%s',
						val.total_unread,
						+val.total_unread === 1 ? 'y' : 'ies',
					),
				);
		} else {
			element.find('.new-message-prompt').hide();
			element.find('.unread-message').hide();
		}

		if (val.seller_suggest_count > 0) {
			element.find('.seller-suggest-alert').show();
			if (val.seller_suggest_count == 1) {
				element.find('.dashboard_suggestion_wording .singular').show();
				element.find('.dashboard_suggestion_wording .plural').hide();
			} else {
				element.find('.dashboard_suggestion_wording .singular').hide();
				element.find('.dashboard_suggestion_wording .plural').show();
			}
			if (val.total_responses == 0) {
				element.find('.dashboard_suggestion_count').css('display', 'inline-block');
			} else {
				element.find('.dashboard_suggestion_count').css('display', 'none');
			}
			element.find('.seller_suggest_count').text(val.seller_suggest_count);
		} else {
			element.find('.seller-suggest-alert').hide();
			element.find('.dashboard_suggestion_count').css('display', 'none');
		}
	}

	function formatNotificationHtml(projectUrl, iconUrl, title, message, date) {
		var notifcation = $('.notification-template').clone();

		notifcation.find('.notification-link').attr('href', projectUrl);
		notifcation.find('.notification-icon').attr('src', iconUrl);
		notifcation.find('.title').text(title);
		notifcation.find('.message').text(message);
		if (date) {
			notifcation.find('.date').text(date);
		}

		notifcation.css({ display: 'block' });

		return notifcation.html();
	}

	function applyNotifications(data) {
		if (!data) {
			throw 'Invalid response';
		}
		var elem;
		var outputTo;
		var showMore;
		var container = $('.js-notification-list');
		var msg;
		if (!$('.show-more', container).length) {
			// Add in a show more link
			container.append('<li class="show-more">Show more</li>');
		}
		showMore = $('.show-more', container);
		if (!$('.item-consolidation', container).length) {
			$('<ul class="item-consolidation"></ul>').insertBefore(showMore);
		}
		outputTo = $('.item-consolidation', container).data({ pos: 1 });
		switch (data.type) {
			case 'quote':
				elem = $('<li />', {
					class: 'clearfix notification-item',
					'data-message-quote-id': data.message_file_id,
					'data-response-id': data.response_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						'New Quote',
						data.quote,
					),
				});
				break;
			case 'message':
				elem = $('<li />', {
					class: 'clearfix notification-item',
					'data-message-id': data.message_id,
					'data-response-id': data.response_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						data.project_title,
						data.message,
					),
				});
				break;
			case 'response':
				elem = $('<li />', {
					class: 'clearfix notification-item',
					'data-response-id': data.response_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						data.project_title,
						data.message,
					),
				});
				break;
			case 'message-later':
				msg =
					data.company_name +
					' is interested in your business and will follow up with a message and estimate later';
				elem = $('<li />', {
					class: 'clearfix notification-item',
					'data-response-id': data.response_id,
					title: msg,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						data.project_title,
						msg,
					),
				});
				break;
			case 'file':
				elem = $('<li />', {
					class: 'clearfix notification-item',
					'data-message-file-id': data.message_file_id,
					'data-response-id': data.response_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						data.project_title,
						data.filename,
					),
				});
				break;
			case 'alert':
				elem = $('<li />', {
					class: 'clearfix notification-item',
					'data-message-alert-id': data.project_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						'New Business',
						data.project_title + ' in ' + data.city_string,
					),
				});
				break;
			case 'seller_dismiss':
				elem = $('<li />', {
					class: 'clearfix notification-item',
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						data.project_title,
						data.message,
					),
				});
				break;
		}
		outputTo.prepend(elem);
		$('.js-notification-list .notification-item').hide();
		showMoreNotifications(5);
		elem.clone(true).insertAfter('.bark-mob-menu-items .back');
		elem.clone(true).appendTo('.header-notifications-mobile .notifications');
		showMore.unbind('click.showmore').on('click.showmore', function (event) {
			var nToShow = 5;
			var newPos = $('.item-consolidation').data('pos') + 1;
			$('.item-consolidation').data({ pos: newPos });
			showMoreNotifications(nToShow * newPos);
			event.stopPropagation();
		});
		$('.js-notification-list')
			.find(
				"li.notification-item[data-response-id='" +
					$('.messenger-container').attr('id') +
					"']",
			)
			.remove();
	}

	/**
	 * Show more of the notifications that a user has
	 * @param {int} max The maximum number of notifications that can be shown
	 */
	function showMoreNotifications(max) {
		var lists = $('.js-notification-list');
		lists.each(function (count, list) {
			var showMore = $('.show-more', list);
			var length = $('.notification-item', list).length;
			for (var i = 0; i < max; i++) {
				$('.notification-item', list).eq(i).show();
				if (i + 1 === length) {
					showMore.hide();
					break;
				} else {
					showMore.text('View more (' + (length - (i + 1)) + ')');
					showMore.show();
				}
			}
		});
	}

	function notifBarkAccepted(barkID) {
		var element = $(".bark-container[data-project-id='" + barkID + "']");
		element
			.removeClass(
				'status_closed status_new status_finding status_contacting status_live status_rejected status_awaiting-detail',
			)
			.addClass('status_finding');
	}

	function notifBarkReset(barkID) {
		var elem = $(".bark-container[data-project-id='" + barkID + "']");
		elem
			.removeClass(
				'status_closed status_new status_finding status_contacting status_live status_rejected status_awaiting-detail',
			)
			.addClass('status_new');
	}

	function notifBarkRelease(barkID) {
		var element = $(".bark-container[data-project-id='" + barkID + "']");
		element
			.removeClass(
				'status_closed status_new status_finding status_contacting status_live status_rejected status_awaiting-detail',
			)
			.addClass('status_contacting');
	}

	function notifBarkReject(barkID) {
		var element = $(".bark-container[data-project-id='" + barkID + "']");
		element
			.removeClass(
				'status_closed status_new status_finding status_contacting status_live status_rejected status_awaiting-detail',
			)
			.addClass('status_rejected');

		var welcomeModal = $('#postBarkWelcomeContainer.bark-welcome');
		if (welcomeModal.is(':visible')) {
			welcomeModal.foundation('reveal', 'close');
		}

		var additionalDetailsModal = $('#additionalDetailsExperimentModal');
		if (additionalDetailsModal.is(':visible')) {
			additionalDetailsModal.foundation('reveal', 'close');
		}

		if (
			$('#proparentcontainer').length &&
			parseInt($('#buyer-dashboard').data('pid')) == parseInt(barkID)
		) {
			$('.reqallrow').hide();
			$('#alertsparentcontainer').show();
			$('#alertsparentcontainer .bark-rejected .awaiting-alert').hide();
			$('#alertsparentcontainer .bark-rejected .close-alert').hide();

			$('#proparentcontainer').hide();
			$('#alertsparentcontainer .bark-rejected .rejected-alert').show();
		} else if (
			$('.buyerdash-v2').length &&
			$('.barkbox[data-id="' + barkID + '"]').length
		) {
			$('.barkbox[data-id="' + barkID + '"] .inforow').hide();
			$('.barkbox[data-id="' + barkID + '"] .alert-message')
				.html(msg_rj)
				.addClass('red')
				.show();
		}
	}

	function notifBarkUnReject(barkID) {
		var element = $(".bark-container[data-project-id='" + barkID + "']");
		element
			.removeClass(
				'status_closed status_new status_finding status_contacting status_live status_rejected status_awaiting-detail',
			)
			.addClass('status_new');

		resetV2Alerts(barkID);
	}

	function notifBarkClose(barkID, data) {
		var element = $(".bark-container[data-project-id='" + barkID + "']");
		element
			.removeClass(
				'status_closed status_new status_finding status_contacting status_live status_rejected status_awaiting-detail',
			)
			.addClass('status_closed');

		var welcomeModal = $('#postBarkWelcomeContainer.bark-welcome');
		if (welcomeModal.is(':visible')) {
			welcomeModal.foundation('reveal', 'close');
		}

		var additionalDetailsModal = $('#additionalDetailsExperimentModal');
		if (additionalDetailsModal.is(':visible')) {
			additionalDetailsModal.foundation('reveal', 'close');
		}

		if (
			$('#proparentcontainer').length &&
			parseInt($('#buyer-dashboard').data('pid')) == parseInt(barkID)
		) {
			$('#alertsparentcontainer .bark-rejected .rejected-alert').hide();
			$('#alertsparentcontainer .bark-rejected .awaiting-alert').hide();

			// show message for bark being rejected due to recent bark in same category - show similar barks and warning message
			if (
				data &&
				typeof data.reason !== 'undefined' &&
				data.reason === 'recent_bark_in_category'
			) {
				if ($('#proparentcontainer .right-container').is(':visible')) {
					$('#postBarkWelcomeContainer.closed-bark-warning').foundation('reveal', 'open');
					$('#alertsparentcontainer .bark-rejected .close-alert-recent-category').show();
					$('#proparentcontainer .right-container').hide();
				}
			} else {
				// otherwise just say the bark has been closed
				$('#proparentcontainer').hide();
				$('#alertsparentcontainer .bark-rejected .close-alert').show();
			}

			$('.reqallrow').hide();
			$('#alertsparentcontainer').show();
		} else if (
			$('.buyerdash-v2').length &&
			$('.barkbox[data-id="' + barkID + '"]').length
		) {
			$('.barkbox[data-id="' + barkID + '"] .inforow').hide();
			$('.barkbox[data-id="' + barkID + '"] .alert-message')
				.html(msg_cl)
				.addClass('orange')
				.show();
		}
	}

	function notifBarkReopen(barkID) {
		var element = $(".bark-container[data-project-id='" + barkID + "']");
		element.removeClass(
			'status_closed status_new status_finding status_contacting status_live status_rejected status_awaiting-detail',
		);

		getCombinedUpdates(0, 0);
		resetV2Alerts(barkID);
	}

	function notifBarkAwaitingDetails(barkID, data) {
		var element = $(".bark-container[data-project-id='" + barkID + "']");
		element
			.removeClass(
				'status_closed status_new status_finding status_contacting status_live status_rejected status_awaiting-detail',
			)
			.addClass('status_awaiting-detail');

		var welcomeModal = $('#postBarkWelcomeContainer.bark-welcome');
		if (welcomeModal.is(':visible')) {
			welcomeModal.foundation('reveal', 'close');
		}

		var additionalDetailsModal = $('#additionalDetailsExperimentModal');
		if (additionalDetailsModal.is(':visible')) {
			additionalDetailsModal.foundation('reveal', 'close');
		}

		if (
			$('#proparentcontainer').length &&
			parseInt($('#buyer-dashboard').data('pid')) == parseInt(barkID)
		) {
			$('.reqallrow').hide();
			$('#proparentcontainer').hide();
			$('#alertsparentcontainer').show();
			$('#alertsparentcontainer .bark-rejected .rejected-alert').hide();
			$('#alertsparentcontainer .bark-rejected .awaiting-alert').show();
			$('#alertsparentcontainer .bark-rejected .close-alert').hide();
		} else if (
			$('.buyerdash-v2').length &&
			$('.barkbox[data-id="' + barkID + '"]').length
		) {
			$('.barkbox[data-id="' + barkID + '"] .inforow').hide();
			$('.barkbox[data-id="' + barkID + '"] .alert-message')
				.html(msg_aw)
				.addClass('orange')
				.show();
		}
	}

	function resetV2Alerts(barkID) {
		if (
			$('#proparentcontainer').length &&
			parseInt($('#buyer-dashboard').data('pid')) == parseInt(barkID)
		) {
			$('#proparentcontainer').show();
			$('#alertsparentcontainer').hide();
			$('#alertsparentcontainer .bark-rejected .rejected-alert').hide();
			$('#alertsparentcontainer .bark-rejected .awaiting-alert').hide();
			$('#alertsparentcontainer .bark-rejected .close-alert').hide();
		}

		if ($('.buyerdash-v2').length && $('.barkbox[data-id="' + barkID + '"]').length) {
			$('.barkbox[data-id="' + barkID + '"] .inforow').show();
			$('.barkbox[data-id="' + barkID + '"] .alert-message')
				.html('')
				.removeClass('red')
				.removeClass('orange')
				.hide();
		}
	}

	function notifBarkRemoveAwaitingDetails(barkID, data) {
		var element = $(".bark-container[data-project-id='" + barkID + "']");
		element.removeClass('status_awaiting-detail').addClass('status_new');

		resetV2Alerts(barkID);
	}

	function incrementDashMessage(barkId) {
		var obj = $('.bark-container[data-project-id="' + barkId + '"]');
		var newCount =
			parseInt(
				obj.find('.dashboard_message_wording span.status_message_count:first').text(),
			) + 1;
		obj.find('.dashboard_message_wording span.status_message_count').text(newCount);
		obj.find('.dashboard_message_wording').parent().show();
	}

	function updateQuote(val) {
		if (
			$(".messenger-leftnav>ul>li[data-response-id='" + val.response_id + "']").length
		) {
			var html = '';
			html += '<span class="price">' + currencySymbol + stripHTML(val.quote) + '</span>';

			var html2 = '<span>Estimate:&nbsp;</span>';
			html2 += "<span class='price'>" + currencySymbol + stripHTML(val.quote) + '</span>';
			html2 += '<span class="type">&nbsp;';

			switch (val.quote_type) {
				case 'onceoff':
					html += ' one off fee';
					html2 += ' one off fee';
					break;
				case 'hour':
				case 'visit':
				case 'session':
				case 'head':
				case 'day':
				case 'week':
				case 'month':
					html += ' / ' + val.quote_type;
					html2 += ' / ' + val.quote_type;
					break;
				default:
					throw 'Unknown quote_type ' + val.quote_type;
			}
			html2 += '</span>';
			if (val.quote_detail.length) {
				html2 +=
					"<span class='quote-detail'>&nbsp;(" + stripHTML(val.quote_detail) + ')</span>';
			}

			$(".messenger-leftnav>ul>li[data-response-id='" + val.response_id + "']")
				.find('.buyer-quote')
				.html(html);
			$('.messenger-container#' + val.response_id)
				.find('.buyer-quote')
				.html(html2);
		}
	}

	function notifBarkQuote(responseId, data) {
		var data2 = {
			type: 'quote',
			response_id: responseId,
			quote_type: data.quote_type,
			quote: data.quote,
			quote_detail: data.quote_detail,
			currency_symbol: data.currency_symbol,
			project_url:
				'/buyers/messenger/' +
				data.slug +
				'/?source=notif&r=' +
				responseId +
				'#' +
				responseId,
		};
		applyNotifications(data2);
		updateBuyerNotificationCount(responseId);
		incrementDashMessage(data.project_id);
		updateQuote(data2);
	}

	function notifBarkMessage(responseId, data) {
		var data2 = {
			type: 'message',
			response_id: responseId,
			message: data.note,
			project_title: data.project_title,
			project_url:
				'/buyers/messenger/' +
				data.slug +
				'/?source=notif&r=' +
				responseId +
				'#' +
				responseId,
		};
		applyNotifications(data2);
		updateBuyerNotificationCount(responseId);
		incrementDashMessage(data.project_id);
	}

	function notifBarkFile(responseId, data) {
		var data2 = {
			type: 'file',
			response_id: responseId,
			filename: data.filename,
			slug: data.slug,
			project_slug: data.project_slug,
			project_title: data.project_title,
			project_url:
				'/buyers/messenger/' +
				data.project_slug +
				'/?source=notif&r=' +
				responseId +
				'#' +
				responseId,
		};
		applyNotifications(data2);
		updateBuyerNotificationCount(responseId);
		incrementDashMessage(data.project_id);
	}

	function getNewResponse(id) {
		// check if this is a new response - i.e. if this is a suggestion and the seller is just responding, don't do anything

		var data = {
			respid: id,
		};
		$.ajax({
			type: 'POST',
			url: '/api/rndresp/',
			data: data,
			dataType: 'json',
			timeout: 25000,
			success: function (data) {
				if (data.success) {
					var newSellerRow = $(
						'.js-project-seller[data-seller-profile-id="' + data.seller_profile_id + '"]',
					);
					if (newSellerRow.length > 0) {
						newSellerRow.replaceWith($(data.data));
					} else {
						$(data.data).prependTo('.dashboard-project-sellers');
						newSellerRow = $('.js-project-seller').first();
						newSellerRow.slideDown(660);

						if (typeof joinResponseRoom === 'function') {
							joinResponseRoom(
								newSellerRow.data('project-response-id'),
								newSellerRow.data('buyer-user-id'),
								newSellerRow.data('seller-profile-id'),
							);
						}
					}

					if (typeof setupSellerListeners === 'function') {
						// set up sockets for this response
						setupSellerListeners(newSellerRow);
					}
				}
			},
		});
	}

	function notifBarkResponse(barkID, data) {
		if (data === undefined) {
			data = false;
		}

		// We are on the buyer messenger page
		if ($('#buyer-messenger').length) {
			if (parseInt($('#buyer-messenger').attr('data-project-id')) == parseInt(barkID)) {
				addResponse(data);
			}
		} else {
			var data2 = {
				type: 'response',
				response_id: data.id,
				message: data.response_text,
				project_title: data.project_title,
				project_url:
					'/buyers/messenger/' +
					data.slug +
					'/?source=notif&r=' +
					data.id +
					'#' +
					data.id,
			};

			applyNotifications(data2);
			updateBuyerNotificationCount(data2.response_id);
			incrementDashMessage(data.project_id);
		}

		if ($('.buyer-dashboard-v2 .dashboard-project-sellers').length) {
			getNewResponse(data.id);
		}

		var obj = $('.bark-container[data-project-id="' + data.project_id + '"]');
		var orig = parseInt(obj.find('.dashboard_response_wording span.num:first').text());
		if (isNaN(orig)) {
			orig = 0;
		}
		var newCount = orig + 1;
		if (newCount == 1) {
			obj.find('.dashboard_response_wording>.plural').hide();
			obj.find('.dashboard_response_wording>.singular').show();
		} else {
			obj.find('.dashboard_response_wording>.plural').show();
			obj.find('.dashboard_response_wording>.singular').hide();
		}
		obj.find('.dashboard_response_count2').text(newCount);
		obj.find('.dashboard_response_wording span.num').text(newCount);
		obj.find('.dashboard_response_count').show();
		obj.find('.dashboard_suggestion_count').css('display', 'none');

		obj
			.removeClass(
				'status_closed status_new status_finding status_contacting status_live status_rejected',
			)
			.addClass('status_live');
	}
}
