import { setCookie } from './cookies';

export function setInitialReferrerCookie() {
	const name = 'trk_initial_referrer';
	const value = document.referrer ?? 'direct';

	setCookie({ name, value });
}

export function setBarkLandingPageCookie() {
	const name = 'trk_bark_landing_page';
	const value = window.location.href;

	setCookie({ name, value });
}
