import { getRecaptchaToken } from '../components/login/helpers/api';

export const LoginPage = async () => {
    const form = document.querySelector('#login-form');
    const submitButton = form.querySelector('button[type="submit"]');

    form.addEventListener('submit', async (e) => {
        e.preventDefault();

        // Disable the submit button to prevent multiple submissions
        submitButton.disabled = true;

        try {
			const urlParams = new URLSearchParams(window.location.search);
			const testCaptcha = urlParams.has('rct');
            const captchaToken = await getRecaptchaToken();
            let captchaInput = form.querySelector('input[name="captchaToken"]');
            let testCaptchaInput = form.querySelector('input[name="rct"]');

            if (!captchaInput) {
                captchaInput = document.createElement('input');
                captchaInput.type = 'hidden';
                captchaInput.name = 'captchaToken';
                form.appendChild(captchaInput);
            }

			if (testCaptcha && !testCaptchaInput) {
				testCaptchaInput = document.createElement('input');
				testCaptchaInput.type = 'hidden';
				testCaptchaInput.name = 'rct';
				testCaptchaInput.value = 1;
				form.appendChild(testCaptchaInput);
			}

            captchaInput.value = captchaToken;

            // Submit the form
            form.submit();
        } catch (error) {
            console.error('Error obtaining reCAPTCHA token:', error);

            // Re-enable the submit button if there was an error
            submitButton.disabled = false;
        }
    });
};
