
export const popupEqualsTrueHandler = async ({ params }) => {
	if (params && canPopup(params)) {
		let { locid: locationId, pt: postCodeType, alid, locact } = params;

		locationId = isNaN(Number(locationId)) ? -1 : Number(locationId);
		postCodeType ??= -1;

		let {
			getPayloadForPopupTrue,
			launchBarkModal,
		} = await import('@bark/bark-core');

		const thePayloadFormdata = getPayloadForPopupTrue({
			locationId,
			postCodeType,
			alid,
			locact,
		});

		launchBarkModal(thePayloadFormdata);

	}
};

function canPopup(params) {
    const formCanPopup = $('#project-create-form').attr('can-popup') !== undefined;
    const isPopupTurnedOffOnPage = $('.bark-journey-popup-off').length > 0;

    if (params) {
        if (params.autosearch) {
            return true;
        }
        if ((params.popup === 'true' || params.nrfp === 'true') && formCanPopup && !isPopupTurnedOffOnPage) {
            return true;
        }
    }
    return false;
}
