import { barkApi } from "../legacy-scripts/barklib";

const { bugsnagClient } = window;

export const getProjectListForBuyer = (queryParams, success, failure) => {
    const {perPage = 10, nextPage = null} = queryParams;

    let path = nextPage ?? 'buyer/projects/';

    return barkApi(
        path,
        {perPage},
        (data) => {
            typeof success === 'function' && success(data);
        },
        (error) => {
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, 'Failed to get projects for the buyer');
            }
        },
        'GET'
    )
}

export const getBuyerProjectSeller = (projectId, sellerId, success, failure) => {
    let path = `buyer/project/${projectId}/sellers/${sellerId}`;

    return barkApi(
        path,
        {},
        (data) => {
            typeof success === 'function' && success(data);
        },
        (error) => {
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, 'Failed to get single project seller');
            }
        },
        'GET'
    )
}

export const getNextBarkSuggestions = (success, failure) => {
    barkApi(
        'buyer/next-bark/',
        {},
        (data) => {
            success(data)
        },
        (error) => {
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, 'Failed to get next bark suggestions');
            }
        }
    );
}

export const getSellerListForProject = (projectId, options, success, failure) => {
    let { pathname, search, bpvid, filter } = options;
    // Need to remove the leading slash if we have a pathname
    return barkApi(
        pathname ? `${pathname.substring(1)}/${search}` : `buyer/project/${projectId}/sellers/`,
        { bpvid, filter },
        (res) => {
            success && success(res);
        },
        (error) => {
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, 'Failed to get seller list');
            }
        }
    )
}

export const getSuggestionsForProject = (projectId, options) => {
    return barkApi(
        `buyer/project/${projectId}/suggestions/`,
        options
    )
}

export const getProjectById = (id, success, failure) => {
    barkApi(
        `buyer/project/${id}/`,
        {},
        (res) => {
            success(res);
        },
        (error) => {
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, 'Failed to get projects');
            }
        }
    )
}

export const getProjectPromise = (id, sidh) => {
    return barkApi(
        `buyer/project/${id}/`,
        {sidh},
        () => {},
        (error) => {
            defaultFailCallback(error, 'Failed to get project promise');
        }
    )
}

export const getSelf = (success, failure) => {
    barkApi(
        'buyer/self/',
        {},
        (res) => {
            success(res);
        },
        (error) => {
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, 'Failed to self');
            }
        }
    )
}

let postResponseActionXHR = null;
export const postResponseAction = (data, success, failure) => {
    let {projectId, sellerProfileId, type, source, bpvsid} = data;

    if (postResponseActionXHR) {
        postResponseActionXHR.abort();
    }

    postResponseActionXHR = barkApi(
        `buyer/project/${projectId}/sellers/${sellerProfileId}/actions`,
        {
            action: type,
            bpvs: bpvsid,
            source: source
        },
        (res) => {
            success && success(res)
            postResponseActionXHR = null;
        },
        (error) => {
            postResponseActionXHR = null;
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, `Failed to record action of type ${type}`);
            }
        },
        'POST'
    )
}

let postBulkResponseActionXHR = null;
export const postBulkResponseAction = (data, success, failure) => {
    let {type, source, sellerProfileIdArray, projectId, bpvid, log_event = false} = data;

    if (postBulkResponseActionXHR) {
        postBulkResponseActionXHR.abort();
    }

    postBulkResponseActionXHR = barkApi(
        `buyer/project/${projectId}/actions`,
        {
            action: type,
            bpv: bpvid,
            source: source,
            spidh: sellerProfileIdArray,
            log_event: log_event
        },
        (res) => {
            success(res)
            postBulkResponseActionXHR = null;
        },
        (error) => {
            postBulkResponseActionXHR = null;
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, `Failed to record action of type ${type}`);
            }
        },
        'POST'
    )
}

export const getLastMessage = (data, success, failure) => {
	let { type, projectId } = data;
	return barkApi(
		`buyer/project/${projectId}/last-message`,
		{
			type: type,
		},
		(res) => {
			success && success(res);
		},
		(error) => {
			if (failure) {
				failure(error);
			} else {
				defaultFailCallback(error, `Failed to get last message for type ${type}`);
			}
		},
		'GET',
	);
};

let sendSellerAMessageXHR = null;
export const sendSellerAMessage = ({sellerProfileId, projectId, message, bpvs, source}, success, failure) => {
    if (sendSellerAMessageXHR) {
        sendSellerAMessageXHR.abort();
    }


    sendSellerAMessageXHR = barkApi(
        `/buyer/project/${projectId}/sellers/${sellerProfileId}/messages`,
        {message, source, bpvs},
        (res) => {
            success(res);
            sendSellerAMessageXHR = null;
        },
        (error) => {
            sendSellerAMessageXHR = null;
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, `Failed to send a buyer message`);
            }
        },
        'POST'
    )
}


let sendManySellersAMessageXHR = null;
export const sendManySellersAMessage = ({message, bpvid, source, spidhArray, projectIdHash}, success, failure) => {
    if (sendManySellersAMessageXHR) {
        sendManySellersAMessageXHR.abort()
    }

    sendManySellersAMessageXHR = barkApi(
        `/buyer/project/${projectIdHash}/messages`,
        {
            message: message,
            bpv: bpvid,
            source: source,
            spidh: spidhArray
        },
        (res) => {
            success && success(res);
            sendManySellersAMessageXHR = null;
        },
        (error) => {
            sendManySellersAMessageXHR = null;
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, `Failed to send a buyer message`);
            }
        },
        'POST'
    )
}

export const getSelfPromise = (success, failure) => {
    return barkApi(
        'buyer/self/',
        {},
        (res) => {
            success && success(res);
        },
        (error) => {
            if (failure) {
                failure(error)
            } else {
                defaultFailCallback(error, 'Failed to self');
            }
        }
    )
}

export const sendContactRequest = (
	{ projectId, sellerProfileId, bpvsId, source, type, phone, timeframe, message, pendingInteractionId },
	success,
	failure,
) => {
	return barkApi(
		`seller/contact`,
		{
			project_id: projectId,
			seller_profile_id: sellerProfileId,
			bpvs: bpvsId,
			source: source,
			type: type,
			phone: phone,
			timeframe: timeframe,
			message: message,
            pending_interaction_id: pendingInteractionId
		},
		(res) => {
			success && success(res);
		},
		(error) => {
			if (failure) {
				failure(error);
			} else {
				defaultFailCallback(
					error,
					`Failed to send contact request to seller profile ${sellerProfileId} with type ${type} from ${source}`,
				);
			}
		},
		'POST',
		{
			Accept: 'application/vnd.bark.v2+json',
		},
	);
};

const defaultFailCallback = (error, message) => {
    bugsnagClient.notify(new Error(message), {metaData: error});
}

const mockApi = (successResponse, failResponse, path, data, success, failure, method, headers, preventRefresh, contentType, processData) => {
    setTimeout(() => {
        success(successResponse);
    }, 500);
}

const getMockSuccessResponseForAnimation = () => {
    return {
        data: {
            show_animation: true
        }
    }
}

export const createEnquiry = (
	{ projectIdHash, sellerIdHash, isMarketplace },
	success,
	failure,
) => {
	return barkApi(
		`/buyer/project/${projectIdHash}/sellers/${sellerIdHash}/${
			isMarketplace ? 'marketplace-enquiry' : 'enquiry'
		}`,
		{},
		(res) => {
			success && success(res);
		},
		(error) => {
			if (failure) {
				failure(error);
			} else {
				defaultFailCallback(error, `Failed to create enquiry`);
			}
		},
		'POST',
	);
};
