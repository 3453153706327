import { useNamespace, _t } from '../../libs/i18next';
import { getFullLocale } from "../../helpers/locale";

export function safeUrl(url) {
	try {
		const urlUpdate = new URL(url);
		const removeParams = ['sli', 'slt', 'bli', 'blt'];
		removeParams.forEach((item) => {
			urlUpdate.searchParams.delete(item);
		});

		return urlUpdate.href;
	} catch (e) {
		window.bugsnagClient &&
			window.bugsnagClient.notify(new Error('Failed to build safe URL'), {
				metaData: { error: e, url },
			});
		return null;
	}
}

/**
 * The Bark global
 */
window.Bark = window.Bark || {};

export default function BarkLib() {
	/**
	 * The id of the Ajax timer
	 * @type int
	 */
	var ajaxTimer = null;

	/**
	 * The id of a timeout used to determine window.resizeEnd
	 * @type int
	 */
	var resizeTimer;

	/**
	 * window.setTimeout value
	 * @type Number
	 */
	var errorAnimationTimeout;

	/**
	 * holds a list of all Hotjar recordings that have been started on this page
	 * @type Array
	 */
	var activeHotjarRecordings = [];

	/**
	 * <b>True</b> if the window is less than the defined Bark.consts.MOBILE_WIDTH_THRESHOLD
	 */
	window.Bark.isMobileDimensions = undefined;

	/**
	 * <b>True</b> if the window is less than the defined Bark.consts.MOBILE_MODAL_THRESHOLD
	 */
	window.Bark.isMobileModalDimensions = undefined;

	/**
	 * Add an error animation
	 * @param {jQuery} elem The element for which to add an error animation
	 * @param {boolean} skipFocus If true, do not set focus in the field
	 */
	window.Bark.addErrorAnimation = function (elem, skipFocus) {
		if (typeof skipFocus == 'undefined') {
			skipFocus = false;
		}
		elem.addClass('shake-animation');
		window.Bark.scrollIntoView(elem);
		window.clearTimeout(errorAnimationTimeout);
		errorAnimationTimeout = window.setTimeout(function () {
			$('.shake-animation').removeClass('shake-animation');
			if (!skipFocus) {
				elem.focus();
			}
		}, 890);
	};

	window.Bark.apiVersionHeader = function (version) {
		return { Accept: 'application/vnd.bark.' + version + '+json' };
	};

	/**
	 *
	 * @param data
	 * @returns {number|*}
	 */
	window.Bark.booleanToInt = function (data) {
		var dataType = typeof data;
		if (data === null) {
			return data;
		}
		switch (dataType) {
			case 'boolean':
				data = data ? 1 : 0;
				break;
			case 'object':
				for (const [key, value] of Object.entries(data)) {
					data[key] = Bark.booleanToInt(value);
				}
				break;
			default:
			// do nothing
		}
		return data;
	};

	/**
	 * A wrapper for <code>$.ajax</code> for making calls to the API server
	 * @param {string} path The path without the leading slash, e.g. <code>api/end/point</code>
	 * @param {object} data [optional] An object of GET/POST parameters
	 * @param {callback} success [optional] The success callback. Use to make use of auto-refresh
	 * @param {callback} failure [optional] The failure callback. Use to make use of auto-refresh
	 * @param {object} method [optional] HTTP request method. Defaults to GET
	 * @param {object} headers [optional] An object of headers
	 * @param {boolean} preventRefresh <b>TRUE</b> to suppress refreshing
	 * @param {string} contentType
	 * @param {boolean} processData
	 * @returns {jqXHR} A jqXHR promise
	 */
	window.Bark.emailService = function (
		path,
		data,
		success,
		failure,
		method,
		headers,
		preventRefresh,
		contentType,
		processData,
	) {
		var payload = window.Bark.getPayload(
			path,
			data,
			success,
			failure,
			method,
			headers,
			preventRefresh,
			contentType,
			processData,
		);

		payload.url = payload.url = Bark.sprintf(
			'%s/%s',
			window.Bark.ENV.email_template_service_hostname,
			path,
		);

		return $.ajax(payload)
			.done(function () {
				// Test for callback and if not null, call the callback using this context
				success && success.apply(this, Array.prototype.slice.call(arguments, 0));
			})
			.fail(function (e) {
				window.Bark.apiFailRoutine(
					e,
					path,
					data,
					success,
					failure,
					method,
					headers,
					preventRefresh,
				);
			});
	};

	window.Bark.apiFailRoutine = function (
		e,
		path,
		data,
		success,
		failure,
		method,
		headers,
		preventRefresh,
	) {
		// Start off the fail routine by storing the current scope
		var ft = this;
		var failArgs = Array.prototype.slice.call(arguments, 0);

		if (e.status === 401 && window.Bark.ENV.JWT && !preventRefresh) {
			// Unauthorized - attempt to refresh the JWT tokens
			$.ajax({
				url: Bark.sprintf('%s/%s', window.Bark.ENV.api_hostname, 'auth/refresh/'),
				beforeSend: function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + window.Bark.ENV.JWT);
				},
				type: 'POST',
			})
				.done(function (e, textStatus, request) {
					var auth = (request.getResponseHeader('Authorization') || '').split(' ');

					if (auth[0].toLowerCase() === 'bearer' && typeof auth[1] !== 'undefined') {
						// This is a valid Authorization token in the form authorization: Bearer xx
						window.Bark.ENV.JWT = auth[1];
						// Call this function again, but this time make sure that we prevent a refresh attempt
						window.Bark.api(path, data, success, failure, method, headers, true);
					} else {
						// The auth response header is invalid
						failure && failure.apply(ft, failArgs);
					}
				})
				.fail(function () {
					failure && failure.apply(ft, failArgs);
				});

			return;
		}

		failure && failure.apply(ft, failArgs);
	};

	window.Bark.getPayload = function (
		path,
		data,
		success,
		failure,
		method,
		headers,
		preventRefresh,
		contentType,
		processData,
	) {
		var payload = {};
		var allHeaders = headers || {};
		var x;

		payload.data = data || null;
		payload.dataType = 'JSON';
		payload.type = method || 'GET';
		payload.processData = typeof processData === 'undefined' ? 'JSON' : processData;

		if (typeof contentType !== 'undefined') {
			payload.contentType = contentType;
		}

		if (
			window.Bark.ENV.JWT &&
			(!allHeaders.Authorization ||
				allHeaders.Authorization !== 'Bearer ' + 'Bearer ' + window.Bark.ENV.JWT)
		) {
			allHeaders.Authorization = 'Bearer ' + window.Bark.ENV.JWT;
		}

		payload.beforeSend = function (xhr) {
			for (x in allHeaders) {
				xhr.setRequestHeader(x, allHeaders[x]);
			}
		};

		return payload;
	};

	/**
	 * Emulate PHP's array_unshift function, i.e. push variables to the front of an array
	 * @syntax array_unshift(array, arg1 [, arg2, ...])
	 * @param {type} arr
	 * @returns {unresolved}
	 */
	window.Bark.array_unshift = function (arr) {
		var temp = Array.prototype.slice.call(arguments, 1);
		return temp.concat(arr);
	};
	/**
	 * Get a unique array (case and type sensitive)
	 * @param {array} arr The array to test
	 * @returns {array} The unique array
	 */
	window.Bark.array_unique = function (arr) {
		var output = [];
		var cur;
		var i;
		if (!Bark.is_array(arr)) {
			throw new Bark.ex('Parameter one of Bark.array_unique() must be an array');
		}
		for (i = 0; i < arr.length; i++) {
			cur = arr[i];
			if (!Bark.in_array(cur, output)) {
				output.push(cur);
			}
		}
		return output;
	};
	/**
	 * Clear a cookie
	 * @param {string} cookiename The name of the cookie to clear
	 * @param {string} path [optional] The path were this cookie was set
	 */
	window.Bark.clear_cookie = function (cookiename, path) {
		Bark.set_cookie(cookiename, null, -2, path);
	};
	/**
	 * Format a date using PHP date synatx
	 * @param {string} format <p> The format of the outputted date string. See the formatting options below. There are also several
	 *  predefined date constants that may be used instead, so for example <b>DATE_RSS</b> contains the format string 'D, d M Y H:i:s'.</p>
	 * @param {mixed} time Anything from which a date object can be constructed
	 * @returns {string} The formatted date
	 * @deprecated Do not use anymore and all references should be updated to use moment
	 */
	window.Bark.date = function (format, time) {
		if (!time) {
			time = new Date();
		}
		var date = new Date(time);
		var weekdays = [
			_t('common:sunday'),
			_t('common:monday'),
			_t('common:tuesday'),
			_t('common:wednesday'),
			_t('common:thursday'),
			_t('common:friday'),
			_t('common:saturday'),
		];
		var weekdaysshort = [
			_t('common:sun'),
			_t('common:mon'),
			_t('common:tue'),
			_t('common:wed'),
			_t('common:thu'),
			_t('common:fri'),
			_t('common:sat'),
		];
		var months = [
			_t('common:jan_full'),
			_t('common:feb_full'),
			_t('common:mar_full'),
			_t('common:apr_full'),
			_t('common:may_full'),
			_t('common:jun_full'),
			_t('common:jul_full'),
			_t('common:aug_full'),
			_t('common:sep_full'),
			_t('common:oct_full'),
			_t('common:nov_full'),
			_t('common:dec_full'),
		];
		var monthsshort = [
			_t('common:jan'),
			_t('common:feb'),
			_t('common:mar'),
			_t('common:apr'),
			_t('common:may'),
			_t('common:jun'),
			_t('common:jul'),
			_t('common:aug'),
			_t('common:sep'),
			_t('common:oct'),
			_t('common:nov'),
			_t('common:dec'),
		];
		var d = date.getUTCDate(); // e.g. 0 or 3
		var day = date.getUTCDay(); // e.g. 31
		var month = date.getUTCMonth(); // e.g. 0 or 11
		var hour = date.getUTCHours();
		var mins = date.getUTCMinutes();
		var secs = date.getUTCSeconds();
		var ordinal = window.Bark.getOrdinal(d); // e.g. st
		var year = date.getUTCFullYear();
		var map;
		var i;
		var key;
		var formatBits = format.split('');

		map = {
			d: (d < 10 ? '0' : '') + String(d), // e.g. 03 (01 through 31)
			D: weekdaysshort[day], // e.g. Fri
			j: d, // e.g. 1 or 14 (1 through 31)
			l: weekdays[day], // e.g. Friday
			N: d + 1, // Day of week (1 through 7)
			S: ordinal, // e.g. st, nd, rd, th
			w: d, // Day of week (0 through 6)
			F: months[month], // e.g. July
			m: (month + 1 < 10 ? '0' : '') + String(month + 1), // 01 through 12
			M: monthsshort[month], // e.g. Jul
			n: month + 1, // 1 through 12
			L: year % 4 === 0, // Leap year or not
			Y: year, // e.g. 2016
			y: String(year).replace(/.*(\d\d)/, '$1'), // e.g. 16
			a: hour < 12 ? _t('common:time.am') : _t('common:time.pm'),
			A: hour < 12 ? _t('common:time.AM') : _t('common:time.PM'),
			g: hour % 12, // 1 through 12
			G: hour, // 00 through 23
			h: (hour % 12 < 10 ? '0' : '') + String(hour % 12), // 01 through 12
			H: (hour < 10 ? '0' : '') + String(hour), // 00 through 23
			i: (mins < 10 ? '0' : '') + String(mins), // 00 through 59,
			s: (secs < 10 ? '0' : '') + String(secs),
		};
		for (i = 0; i < formatBits.length; i++) {
			key = formatBits[i];
			formatBits[i] = key in map ? map[key] : key;
		}
		return formatBits.join('');
	};
	/**
	 * Determine whether an email address exists in the system
	 * @param {string} email The email address to test
	 * @param {success} callback A callback with the parameters
	 *  result: int 1 = Email exists, 0 = email does not exist and -1 = failure
	 *  data: {
	 *      roles: {roleid: role name}
	 *      inagency: boolean
	 *  }
	 */
	(window.Bark.emailExistsInUsers = function (email, callback) {
		// Use Bark.json as it has CSRF built in
		Bark.json({
			url: '/json/email-exists-in-users/',
			data: { email: email },
		})
			.done(function (e) {
				callback && callback(e.result, e.data);
			})
			.fail(function () {
				callback && callback(-1);
			});
	}),
		/**
		 * Return the last element in an array
		 * @param {array} arr The array we will take from
		 * @returns {unknown} The last element in your array
		 */
		(window.Bark.end = function (arr) {
			return arr.slice(-1)[0];
		});
	/**
	 * Bark exception handler
	 * @param {string} message The exception message
	 * @param {string} exceptiontype [optional] The name of the exception to replace xxx in the string "Uncaught Bark::xxx - message".
	 *  defaults to 'Exception'
	 * @returns {Bark::Exception}
	 */
	window.Bark.ex = function (message, exceptiontype) {
		if (!exceptiontype) {
			exceptiontype = 'Exception';
		}
		return {
			name: 'Bark::' + exceptiontype,
			level: 'Cannot continue',
			message: message,
			htmlMessage: message,
			toString: function () {
				return ['Bark::', exceptiontype, ' - ', message].join('');
			},
		};
	};
	/**
	 * Get the value of a URL parameter
	 * @param {string} queryParam The URL parameter to search for
	 * @returns {undefined|boolean|string} The value fo the key-value pair, <b>True</b> if the URL parameter was not a key-value pair
	 *  or <b>undefined</b> if the key isn't present
	 */
	window.Bark.GET = function (queryParam) {
		var bits = location.search.replace(/\?/, '').split('&');
		var i;
		var cur;
		for (i = 0; i < bits.length; i++) {
			cur = bits[i].split('=');
			if (cur[0].toLowerCase() === queryParam.toLowerCase()) {
				cur.shift();
				// If key is empty, then the URL param was just a key, otherwise it is a key value pair.
				return cur.length ? cur.join('=') : true;
			}
		}
		return undefined;
	};
	/**
	 * Get the value of a cookie
	 * @syntax get_cookie(name_of_cookie)
	 * @author http://goo.gl/LCLNuC
	 * @param {string} cookiename The name of the cookie to retrieve
	 * @returns {mixed} The value of the cookie, or undefined if the cookie could not be found
	 */
	window.Bark.get_cookie = function (cookiename) {
		var name = cookiename + '=',
			ca = document.cookie.split(';'),
			i,
			c;
		for (i = 0; i < ca.length; i++) {
			c = ca[i].trim();
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return;
	};
	/**
	 * Retruns all cookies
	 * @returns {}
	 */
	window.Bark.get_all_cookies = function () {
		var cookies = {},
			cookieParts = document.cookie.split(';'),
			i,
			name_value;
		for (i = 0; i < cookieParts.length; i++) {
			try {
				name_value = cookieParts[i].split('=');
				// Strip the space that may be around the semicolon
				name_value[0] = name_value[0].replace(/^ /, '');
				cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(name_value[1]);
			} catch (e) {
				// Ignore
			}
		}
		return cookies;
	};
	/**
	 * Get a date object
	 * @param {mixed} date Anything from which a date can be constructed, but also has cross browser support for dates in the
	 *  format 'Y-m-d H:i:s'
	 * @returns {Date}
	 */
	window.Bark.getDate = function (date) {
		var d;
		var bits;
		if (
			Bark.is_a(date, 'string') &&
			date.trim().match(/^\d{4}.\d{1,2}.\d{1,2}.\d{2}.\d{2}(:?\d{2})?$/)
		) {
			// The string is likely in the form Y-m-d H:i:s
			// ^\d{4}.\d{2}.\d{2} means match a string that starts with four digits, followed by a character (e.g. a space), followed
			//  by two digits, followed by a separator followed by another two digits
			// .\d{2}.\d{2}(:?.\d{2})?$ Means any character '.', followed by two digits, a character (e.g. ':'), followed by an
			//  optional character and two digits
			// Split by non-integer character
			bits = date.split(/\D/);
			d = new Date(
				Date.UTC(bits[0], bits[1] - 1, bits[2], bits[3], bits[4], bits[5] || 0),
			);
		} else {
			d = new Date(Date.UTC(date));
		}
		return d;
	};
	/**
	 * Get the pixel ratio of the current device
	 * @from https://goo.gl/UH46Lh
	 * @returns {Number} The device pixel ratio
	 */
	window.Bark.getDevicePixelRatio = function () {
		var ratio = 1;
		// To account for zoom, change to use deviceXDPI instead of systemXDPI
		if (
			window.screen.systemXDPI !== undefined &&
			window.screen.logicalXDPI !== undefined &&
			window.screen.systemXDPI > window.screen.logicalXDPI
		) {
			// Only allow for values > 1
			ratio = window.screen.systemXDPI / window.screen.logicalXDPI;
		} else if (window.devicePixelRatio !== undefined) {
			ratio = window.devicePixelRatio;
		}
		return ratio;
	};
	/**
	 * Generate a xhtml element, e.g. a div element
	 * @syntax cHE.getHtml(tagname, body, htmlid, cssclass, {attribute: value});
	 * @param {string} tagname The type of element to generate
	 * @param {string} body The body to go with
	 * @param {string} id The id of this element
	 * @param {string} cssclass The css class of this element
	 * @param {object} moreattrs An object in the form {html_attribute: value, ...}
	 * @returns {html} The relevant html as interpreted by the browser
	 */
	window.Bark.getHtml = function (tagname, body, id, cssclass, moreattrs) {
		var html = document.createElement(tagname);
		if (body) {
			html.innerHTML = body;
		}
		if (id) {
			html.id = id;
		}
		if (cssclass) {
			html.className = cssclass;
		}
		setAttributes(html, moreattrs);
		return html.outerHTML;
	};
	/**
	 * Experimental: Get the network speed
	 * @returns {float} NetworkInformation.downlink value (estimated in Megabits)
	 */
	window.Bark.getNetworkSpeed = function () {
		return navigator.connection ? (navigator.connection || {}).downlink : null;
	};
	/**
	 * Experimental: Get the network effective type
	 * @returns {float} NetworkInformation.effectiveType value, one of 'slow-sg', '2g', '3g', or '4g'
	 */
	window.Bark.getNetworkType = function () {
		return navigator.connection ? (navigator.connection || {}).type : null;
	};
	/**
	 * Get the oridinal for a number
	 * @param {int} number The number from which to determine the ordinal
	 * @returns {string} e.g. th, st, nd
	 * @deprecated
	 */
	window.Bark.getOrdinal = function (number) {
		var ordinal;
		var numberAsString = String(number);

		if ((Bark?.ENV?.lang?.toLowerCase() || 'en') !== 'en') {
			// Remove ordinals if lang <> en
			return '';
		}

		switch (+numberAsString.replace(/.*(\d)$/, '$1')) {
			case 1:
				ordinal = 'st';
				break;
			case 2:
				ordinal = 'nd';
				break;
			case 3:
				ordinal = 'rd';
				break;
			default:
				ordinal = 'th';
		}
		if (numberAsString.match(/.*1(1|2|3)$/)) {
			// The number ends in 11, 12 or 13 and therefore needs the 'th' ordinal
			ordinal = 'th';
		}
		return ordinal;
	};
	/**
	 * Get the time since a date as a string
	 * @param {string|Date} date Anything from which a Date object can be constructed
	 * @notes Adapted from chat.js
	 * @returns {string} The time that has elapsed as a string, e.g. '3 days ago', 'Less than a minute ago'
	 * @deprecated
	 */
	window.Bark.getTimeElapsedAsString = function (date) {
		var d = Bark.getDate(date);
		var seconds = Math.floor((new Date() - d) / 1000);
		var interval = (seconds / 31536000).toPrecision(2);
		if (interval >= 1) {
			return Bark.sprintf('%d year%s ago', interval, interval > 2 ? 's' : '');
		}
		interval = (seconds / 2592000).toPrecision(2);
		if (interval >= 1) {
			return Bark.sprintf('%d month%s ago', interval, interval > 2 ? 's' : '');
		}
		interval = (seconds / 86400).toPrecision(2);
		if (interval >= 1) {
			return Bark.sprintf('%d day%s ago', interval, interval > 2 ? 's' : '');
		}
		interval = (seconds / 3600).toPrecision(2);
		if (interval >= 1) {
			return Bark.sprintf('%d hour%s ago', interval, interval > 2 ? 's' : '');
		}
		interval = (seconds / 60).toPrecision(2);
		if (interval < 1) {
			return 'Less than a minute ago';
		} else {
			return Bark.sprintf('%d minute%s ago', interval, interval > 1 ? 's' : '');
		}
	};
	/**
	 * Get the current time in the format +00:00
	 * @returns {string}
	 */
	window.Bark.getTimezone = function () {
		var d = new Date();
		var tz = d.getTimezoneOffset();
		var hrs = tz / -60;
		var mod = (hrs - parseInt(hrs)) % 60;
		return Bark.sprintf(
			'%s%s:%s',
			hrs < 0 ? '-' : '+',
			(hrs < 10 && hrs > -1 ? '0' : '') + String(hrs),
			(mod < 10 && mod > -1 ? '0' : '') + String(mod),
		);
	};

	window.Bark.getTrackableParameters = () => {
		const cookies = Bark.get_all_cookies();
		const noCurrentOrigin = (url) => {
			if (!url) {
				return url;
			}

			// location.origin isn't always available
			return url.replace(`${location.protocol}//${location.host}`, '');
		};
		const all = Object.assign(cookies, {
			trk_client_user_agent: navigator?.userAgent || undefined,
			trk_event_source_url: noCurrentOrigin(
				Bark.safeUrl(window?.location?.href) || undefined,
			),
			trk_event_referrer: noCurrentOrigin(Bark.safeUrl(document.referrer) || undefined),
			trk_current_bes_token: cookies['bes-token'] || undefined,
			trk_estimated_zip: Bark.ENV?.eloc?.zip || undefined,
			trk_estimated_city: Bark.ENV?.eloc?.city || undefined,
			trk_estimated_country_code: Bark.ENV?.eloc?.country_code || undefined,
			trk_estimated_isp: Bark.ENV?.eloc?.isp || undefined,
		});

		return Bark.filterTrackableParams(all);
	};

	/**
	 * Get all of the tracking
	 * @param {object} source E.g. the return of window.Bark.get_all_cookies()
	 * @returns {object}
	 */
	window.Bark.filterTrackableParams = function (source) {
		var tracking = {};
		var patterns = [
			'^utm_', // ^ means starts with
			'^trk_',
			'^fbclid',
			'^_fbp$', // $ means ends with
			'^_fbc$',
			'^campaign$',
			'^ptcode$',
		];
		var i;
		var re;

		for (var item in source) {
			for (i = 0; i < patterns.length; i++) {
				re = new RegExp(patterns[i]);

				if (item.match(re)) {
					tracking[item] = source[item];
					break;
				}
			}
		}

		return tracking;
	};
	/**
	 * Get the value of a paramter from the URL
	 * @param {string} key The URL parameter to get
	 * @returns {mixed} The value of the parameter, <b>True</b> if the URL parameter exists but it didn't have a value, or
	 *  <b>NULL</b> if the URL parameter did not exist
	 */
	window.Bark.getUrlParam = function (key) {
		var sPageURL = decodeURIComponent(window.location.search.substring(1));
		var sURLVariables = sPageURL.split('&');
		var sParameterName;
		var i;
		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');
			if (sParameterName[0] === key) {
				return sParameterName[1] === undefined ? true : sParameterName[1];
			}
		}
		return null;
	};

	window.Bark.decodeEntities = function (encodedString) {
		var textArea = document.createElement('textarea');
		textArea.innerHTML = encodedString;
		return textArea.value;
	};

	/**
	 * Get the vendor spec for the current browser
	 * @returns {object} An object in the form<pre>
	 *  {
	 *      css: string E.g. -webkit-
	 *      dom: string E.g WebKit
	 *      js: string E.g. Webkit
	 *      lowercase string E.g. webkit
	 *  }
	 */
	window.Bark.getVendor = function () {
		var styles = window.getComputedStyle(document.documentElement, ''),
			pre = (Array.prototype.slice
				.call(styles)
				.join('')
				.match(/-(moz|webkit|ms)-/) ||
				(styles.OLink === '' && ['', 'o']))[1],
			dom = 'WebKit|Moz|MS|O'.match(new RegExp('(' + pre + ')', 'i'))[1];
		return {
			css: '-' + pre + '-',
			dom: dom,
			js: pre[0].toUpperCase() + pre.substr(1),
			lowercase: pre,
		};
	};

	/**
	 * Init the inline modal uploader
	 * @param {string} element [optional] The element where the Dropzone should be initiated
	 * @param {object} options [optional] An object of options
	 * @param {string} outputSelector [optional] The id of the element to output the file ids to (defaults to .modal-upload-output)
	 * @param {string} previewSelector [optional] The id of the element to display previews in (defaults to '#previews-modal')
	 * @param {string} clickableSelector [optional] You don't want to have 2 clickable elements with the same selector as it makes it buggy.
	 * If there are 2 dropZones give it a more specific selector (defaults to '#previews-modal')
	 */
	window.Bark.initModalUpload = function (
		element,
		options,
		outputSelector,
		previewSelector,
		clickableSelector,
	) {
		var postParams = {};
		var csrf_name = $('meta[name="csrf_name"]').attr('content');
		var csrf_value = $('meta[name="csrf_value"]').attr('content');
		var selector = element || '#modalUploadFiles';
		var previewsContainer = previewSelector || '#previews-modal';
		var clickable = clickableSelector || '.fileinput-button';

		Dropzone.autoDiscover = false;
		Dropzone.prototype.defaultOptions.dictRemoveFile = _t(
			'common:modal-upload.remove-button',
		);
		Dropzone.prototype.defaultOptions.dictCancelUpload = _t(
			'common:modal-upload.cancel-button',
		);
		Dropzone.prototype.defaultOptions.dictFileTooBig = _t(
			'common:modal-upload.file-too-big-error',
		);

		if (!Bark.is_a(options, 'object')) {
			options = {};
		}

		postParams[csrf_name] = csrf_value;
		new Dropzone(
			selector,
			$.extend(
				{
					url: '/modal-upload/',
					thumbnailWidth: 70,
					parallelUploads: 6,
					params: postParams,
					thumbnailHeight: 70,
					previewsContainer: previewsContainer,
					clickable: clickable,
					addRemoveLinks: true,
					maxFiles: 20,
					maxFilesize: 10,
					accept: function (file, done) {
						var thumbnail = $('.dropzoneContainer .dz-preview .dz-image:last');
						var icon = '';
						switch (file.type) {
							case 'application/zip':
								icon = 'fa-file-archive-o';
								break;
							case 'application/vnd.ms-powerpoint':
								icon = 'fa-file-powerpoint-o';
								break;
							case 'application/vnd.ms-excel':
								icon = 'fa-file-excel-o';
								break;
							case 'application/msword':
								icon = 'fa-file-word-o';
								break;
							case 'application/pdf':
								icon = 'fa-file-pdf-o';
								break;
							case 'audio/mpeg3':
								icon = 'fa-file-audio-o';
								break;
							case 'video/avi':
								icon = 'fa-file-video-o';
								break;
							case 'image/jpeg':
							case 'image/png':
							case 'image/gif':
								break;
							default:
								icon = 'fa-file-o';
						}
						if (icon.length) {
							thumbnail.html(
								'<i class="fa ' +
									icon +
									'" style="padding-top:0.3em;font-size: 3em; color: rgb(255, 255, 255);"></i>',
							);
						}
						done();
					},
					init: function () {
						this.on('removedfile', function (file) {
							var event = new $.Event('modalUploadRemovedFile');
							event.originalArguments = arguments;
							$(selector).trigger(event);

							if (
								file.status === 'error' ||
								isEmpty(file.serverId) ||
								event.isDefaultPrevented()
							) {
								return false;
							}
							var payload = {
								id: file.serverId,
								filetype: file.filetype,
							};
							var assetInputId = file.filetype === 'image' ? 'image_id_' : 'file_id_';
							assetInputId += file.serverId;
							$('#' + assetInputId).remove();
							var postParams = {
								file: JSON.stringify(payload),
							};
							postParams[csrf_name] = csrf_value;
							$.ajax({
								url: '/modal-upload/delete/',
								type: 'POST',
								data: postParams,
							});
						});

						this.on('error', function (file, response) {
							var event = new $.Event('modalUploadError');
							event.originalArguments = arguments;
							$(selector).trigger(event);
							if (event.isDefaultPrevented()) {
								return false;
							}

							$(file.previewElement).find('.dz-progress').hide();
							if (typeof response === 'string' && response === '') {
								return false;
							}
							var jsonObj = jQuery.parseJSON(response);
							file.previewElement.querySelector('[data-dz-errormessage]').textContent =
								jsonObj.errors[0];
						});

						this.on('reset', function () {
							var event = new $.Event('modalUploadReset');
							event.originalArguments = arguments;
							$(selector).trigger(event);
							if (event.isDefaultPrevented()) {
								return false;
							}
							Bark.createBark.enableContinue();
						});
						this.on('queuecomplete', function () {
							var event = new $.Event('modalUploadQueueComplete');
							event.originalArguments = arguments;
							$(selector).trigger(event);
							if (event.isDefaultPrevented()) {
								return false;
							}
							Bark.createBark.enableContinue();
						});
						this.on('canceledmultiple', function () {
							var event = new $.Event('modalUploadCancelledMultiple');
							event.originalArguments = arguments;
							$(selector).trigger(event);
							if (event.isDefaultPrevented()) {
								return false;
							}
							Bark.createBark.enableContinue();
						});
						this.on('sending', function () {
							var event = new $.Event('modalUploadSending');
							event.originalArguments = arguments;
							$(selector).trigger(event);
							if (event.isDefaultPrevented()) {
								return false;
							}
							Bark.createBark.disableContinueForUploading();
						});

						this.on('addedfile', function (file) {
							var event = new $.Event('modalUploadAddedFile');
							event.originalArguments = arguments;
							$(selector).trigger(event);
							if (event.isDefaultPrevented()) {
								return false;
							}
							var thumbnail = $(file.previewElement).find('.dz-image');
							if (Bark.in_array(file.type, ['image/png', 'image/jpeg', 'image/gif'])) {
								$(file.previewElement).find('.dz-details').hide();
							}
							$(file.previewElement).find('.dz-size').html('');
							$('<div class="dz-overlayload"></div>').insertAfter(thumbnail).fadeIn();
						});

						this.on('complete', function (file) {
							var event = new $.Event('modalUploadComplete');
							event.originalArguments = arguments;
							$(selector).trigger(event);
							if (event.isDefaultPrevented()) {
								return false;
							}
							$(file.previewElement).find('.dz-overlayload').delay(1800).fadeOut();
						});

						this.on('success', function (file, response) {
							var event = new $.Event('modalUploadSuccess');
							event.originalArguments = arguments;
							$(selector).trigger(event);
							if (event.isDefaultPrevented()) {
								return false;
							}
							var jsonObj = jQuery.parseJSON(response);
							file.serverId = jsonObj.values.id;
							file.filetype = jsonObj.values.filetype;
							// Append the file IDs to send with the form
							var assetInputName =
								file.filetype === 'image' ? 'image_ids[]' : 'file_ids[]';
							var inputId = file.filetype === 'image' ? 'image_id_' : 'file_id_';
							inputId += file.serverId;
							$('<input>', {
								type: 'hidden',
								name: assetInputName,
								class: 'modal-uploader-hidden-input',
								'data-type': file.filetype === 'image' ? 'image' : 'file',
								id: inputId,
							})
								.appendTo(outputSelector || '.modal-upload-output')
								.val(file.serverId);
						});
					},
				},
				options,
			),
		);
	};
	/**
	 * Determine whether a value exists in an array
	 * @param {mixed} needle The item to search for
	 * @param {array} haystack The array to look in
	 * @returns {boolean} True if 'key' is in the array
	 */
	window.Bark.in_array = function (needle, haystack) {
		var i;
		haystack = haystack || [];
		for (i = 0; i < haystack.length; i++) {
			if (haystack[i] === needle) {
				return true;
			}
		}
		return false;
	};
	/**
	 * Test to see if an object is of a particular type
	 * @param {mixed} variable The object to test
	 * @param {string} expected The type expected
	 * @returns {boolean} False if the object is undefined, or a boolean depending on whether the object matches
	 */
	window.Bark.is_a = function (variable, expected) {
		if (variable === undefined) {
			// Undefined is an object in IE8
			return false;
		}
		var otype = expected.substr(0, 1).toUpperCase() + expected.substr(1).toLowerCase();
		return Object.prototype.toString.call(variable) === '[object ' + otype + ']';
	};
	/**
	 * Determine whether a given variable is an array
	 * @param {mixed} variable The object to test
	 * @returns {boolean} <b>True</b> if the object is an array
	 */
	window.Bark.is_array = function (variable) {
		return Bark.is_a(variable, 'array');
	};
	/**
	 * Determine whether a given variable is a function
	 * @param {mixed} variable The object to test
	 * @returns {boolean} <b>True</b> if the object is a function
	 */
	window.Bark.is_function = function (variable) {
		return Bark.is_a(variable, 'function');
	};
	/**
	 * Determine whether the current connection is broadband
	 * @returns {boolean} <b>True</b> if on broadband, <b>null</b> if navigator.connection is not defined (tested in Android -
	 *  is experimental technology but has been in since Android 2.2. Currently 6)
	 */
	window.Bark.isBroadband = function () {
		return navigator.connection
			? Bark.in_array((navigator.connection || {}).type, ['wifi', 'ethernet', 'wimax'])
			: null;
	};
	/**
	 * Perform an AJAX request
	 * @note CSRF data passed<br/>
	 * Defaults to JSON data-type<br/>
	 * Defaults to a POST request
	 * @param {object} settings $.ajax settings as an object, or a URL
	 * @returns {jqXHR} The jqXHR object which can then be used in conjuction with $.ajax
	 */
	window.Bark.json = function (settings) {
		if (Bark.is_a(settings, 'string')) {
			settings = { url: settings };
		}
		settings.dataType = settings.dataType || 'JSON';
		settings.type = settings.type || 'post';
		// Send cookies with cross-domain requests
		settings.xhrFields = {
			withCredentials: true,
		};
		if ($.trim(settings.type).toLowerCase() === 'post') {
			// Add in CSRF data for a post request
			settings.data = settings.data || {};
			settings.data[$('meta[name=csrf_name]').attr('content')] = $(
				'meta[name=csrf_value]',
			).attr('content');
			// Prevent cacheing by iOS Safari
			settings.headers = {
				'cache-control': 'no-cache',
			};
		}
		return $.ajax(settings).fail(function (e) {
			// @todo Implement more fail handling
			if (e.responseText === 'You must be logged in') {
				document.location.reload();
				return;
			}
			console.error(e);
			Bark.hideLoading();
		});
	};
	/**
	 * Log session data for an experiment
	 * @param {string} name The name of the experiment
	 * @param {boolean} isincontrolgroup <b>True</b> if the user is in the control group
	 * @param {mixed} more More data to log
	 * @param {function} callback A callback to run once the data has been logged
	 */
	window.Bark.logExperimentsSessionData = function (
		name,
		isincontrolgroup,
		category_id,
		variant,
		more,
		callback,
	) {
		// Use Bark.json as it has CSRF built in
		return Bark.json({
			url: '/json/esd/log/',
			data: {
				payload: JSON.stringify({
					name: name,
					isincontrolgroup: isincontrolgroup,
					more: more || null,
					category_id: category_id,
					variant: variant,
				}),
			},
		}).done(callback);
	};

	window.Bark.allocateExperimentVariant = function (experimentPercentage) {
		var randomNumber = Math.floor(Math.random() * 100) + 1;
		var variant = experimentPercentage >= randomNumber;

		return +variant;
	};

	/**
	 * Record an experiment action
	 * @param value The value to record
	 * @param sessionId The hashed experiment ID
	 * @param action (default = click_link)
	 * @param projectId
	 */
	window.Bark.recordExperimentAction = function (value, sessionId, action, projectId) {
		action = action || 'click_link';
		projectId = projectId || 0;

		Bark.json({
			url: '/api/exp-act/',
			data: {
				xpid: sessionId,
				act: action,
				actv: value,
				pid: projectId,
				pg: window.location.pathname,
			},
		});
	};

	/**
	 * Determine the number of non-inherited properties in an object
	 * @param {object} object The object to count
	 * @returns {int} The number of properties in the object
	 */
	window.Bark.objectSize = function (object) {
		var n = 0;
		for (var x in object) {
			if (object.hasOwnProperty(x)) {
				n++;
			}
		}
		return n;
	};
	/**
	 * Return the first element in an array
	 * @param {array} arr The array that we will take from
	 * @param {bolean} key True to return the key of the first element
	 * @returns {unknown} The first element in your array or the key of the first element
	 */
	window.Bark.reset = function (arr, key) {
		var x;
		if (arr[0]) {
			return key ? 0 : arr[0];
		}
		for (x in arr) {
			if (arr.hasOwnProperty(x)) {
				// Return the first one
				return key ? x : arr[x];
			}
		}
	};
	/**
	 * Set a cookie
	 * @syntax set_cookie(name_of_cookie, cookie_value, expires_in [,path])
	 * @author http://goo.gl/LCLNuC
	 * @param {string} name The name of the cookie
	 * @param {alphanumeric} value The value of the cookie
	 * @param {int} exdays In how many days should the cookie expire. If omitted, sets a session cookie.
	 * @param {string} path The path where this cookie can be read from
	 */
	window.Bark.set_cookie = function (name, value, exdays, path) {
		if (exdays > 0) {
			var d = new Date(),
				expires;
			d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
			expires = 'expires=' + d.toGMTString();
		} else {
			expires = '';
		}
		document.cookie =
			name + '=' + value + '; ' + expires + (path ? '; path=' + path : '');
	};

	/**
	 * Sets the page state in HTML5 history
	 * @param {object|null} stateObject The state object to store
	 * @param {string|null} pageHash The hash to append to the URL
	 * @param {boolean|null} replace Whether to replace the state instead of pushing it onto the stack
	 */
	window.Bark.setPageState = function (stateObject, pageHash, replace) {
		stateObject = stateObject || {};
		pageHash = pageHash || null;
		replace = replace || false;

		if (pageHash && pageHash[0] !== '#') {
			pageHash = '#' + pageHash;
		}
		if (history && history.pushState) {
			replace
				? history.replaceState(stateObject, '', pageHash)
				: history.pushState(stateObject, '', pageHash);
		} else if (pageHash) {
			location.hash = pageHash;
		}
	};

	window.Bark.alertModal = function (alertText, confirmText, confirmCallback) {
		var isFoundation = true;
		var modal = $('#barkModalAlert');

		if (!modal.length) {
			modal = $('#bark-modal-alert');
			isFoundation = !modal.length;
		}

		if (modal.length === 0 || Bark.isMobile()) {
			alert(Bark.stripHTML(alertText));
			if (confirmCallback) {
				confirmCallback();
			}
		} else {
			modal.find('.js-title').html(alertText);
			if (!confirmText) {
				confirmText = _t('common:alert-confirm-modals.ok-button');
			}

			if (isFoundation) {
				modal
					.find('.js-confirm')
					.html(confirmText)
					.unbind('click.callbackAction')
					.on('click.callbackAction', function () {
						if (confirmCallback) {
							$(document)
								.unbind('closed.fndtn.reveal')
								.on('closed.fndtn.reveal', '[data-reveal]', function () {
									$(document).unbind('closed.fndtn.reveal');
									confirmCallback();
								});
						}
						modal.foundation('reveal', 'close');
					});
				modal.foundation('reveal', 'open');
			} else {
				$('.js-confirm', modal)
					.html(confirmText)
					.off('click.callbackAction')
					.on('click.callbackAction', function () {
						if (confirmCallback) {
							modal
								.off('hidden.bs.modal.confirmModal')
								.on('hidden.bs.modal.confirmModal', function () {
									modal.off('hidden.bs.modal.confirmModal');
									confirmCallback();
								});
						}
						modal.modal('hide');
					});

				modal.modal('show');
			}
		}
	};
	/**
	 * Show a confirmation screen
	 */
	window.Bark.confirmModal = function (
		questionText,
		confirmCallback,
		cancelCallback,
		confirmText,
		cancelText,
		forceModal,
	) {
		forceModal = forceModal === undefined ? false : forceModal;

		var isFoundation = true;
		var modal = $('#barkModalConfirm');

		if (!modal.length) {
			modal = $('#bark-modal-confirm');
			isFoundation = !modal.length;
		}

		if (!forceModal && modal.length === 0) {
			if (confirm(Bark.stripHTML(questionText))) {
				if (confirmCallback) {
					confirmCallback();
				}
			} else {
				if (cancelCallback) {
					cancelCallback();
				}
			}
		} else {
			modal.find('.js-title').html(questionText);
			if (!confirmText) {
				confirmText = _t('common:alert-confirm-modals.ok-button');
			}
			if (!cancelText) {
				cancelText = _t('common:alert-confirm-modals.cancel-button');
			}

			if (isFoundation) {
				modal
					.find('.js-confirm')
					.html(confirmText)
					.unbind('click.callbackAction')
					.on('click.callbackAction', function () {
						if (confirmCallback) {
							$(document)
								.unbind('closed.fndtn.reveal')
								.on('closed.fndtn.reveal', '[data-reveal]', function () {
									$(document).unbind('closed.fndtn.reveal');
									confirmCallback();
								});
						}
						modal.foundation('reveal', 'close');
					});
				modal
					.find('.js-cancel')
					.html(cancelText)
					.unbind('click.callbackAction')
					.on('click.callbackAction', function () {
						if (cancelCallback) {
							$(document)
								.unbind('closed.fndtn.reveal')
								.on('closed.fndtn.reveal', '[data-reveal]', function () {
									$(document).unbind('closed.fndtn.reveal');
									cancelCallback();
								});
						}
						modal.foundation('reveal', 'close');
					});
				modal.foundation('reveal', 'open');
			} else {
				$('.js-confirm', modal)
					.html(confirmText)
					.off('click.callbackAction')
					.on('click.callbackAction', function () {
						if (confirmCallback) {
							modal
								.off('hidden.bs.modal.confirmModal')
								.on('hidden.bs.modal.confirmModal', function () {
									modal.off('hidden.bs.modal.confirmModal');
									confirmCallback();
								});
						}
						modal.modal('hide');
					});

				$('.js-cancel', modal)
					.html(cancelText)
					.off('click.callbackAction')
					.on('click.callbackAction', function () {
						if (cancelCallback) {
							modal
								.off('hidden.bs.modal.confirmModal')
								.on('hidden.bs.modal.confirmModal', function () {
									modal.off('hidden.bs.modal.confirmModal');
									cancelCallback();
								});
						}
						modal.modal('hide');
					});

				modal.modal('show');
			}
		}
	};

	window.Bark.isMobile = function () {
		var isMobile = false; //initiate as false
		// device detection
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent,
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4),
			)
		)
			isMobile = true;
		return isMobile;
	};

	window.Bark.triggerHotjarRecording = function (recordingName) {
		if (!window.hj) {
			return;
		}
		try {
			if (
				recordingName &&
				recordingName.length &&
				activeHotjarRecordings.indexOf(recordingName) < 0
			) {
				window.hj('trigger', recordingName);
				activeHotjarRecordings.push(recordingName);
			}
		} catch (e) {
			bugsnagClient && bugsnagClient.notify(e);
		}
	};

	window.Bark.recordHotjarEvent = function (eventName) {
		if (!window.hj) {
			return;
		}
		try {
			if (eventName && eventName.length) {
				window.hj('tagRecording', [eventName]);
			}
		} catch (e) {
			bugsnagClient && bugsnagClient.notify(e);
		}
	};

	window.Bark.recordHotjarFormStatus = function (success) {
		if (!window.hj) {
			return;
		}
		try {
			if (success) {
				window.hj('formSubmitSuccessful');
			} else {
				window.hj('formSubmitFailed');
			}
		} catch (e) {
			bugsnagClient && bugsnagClient.notify(e);
		}
	};

	/**
	 * Scroll into view
	 * @param {jQuery} elem
	 * @param {int} offset [optional] Default 100px
	 */
	window.Bark.scrollIntoView = function (elem, offset) {
		var offset;

		if (!elem.isInViewport(offset || 100)) {
			offset = elem.offset();

			$('html,body').animate({ scrollTop: offset.top - 150 }, 100);
		}
	};
	/**
	 * Show form errors
	 * @param {string} fieldname The ID of the element
	 * @param {string} message The message to show
	 * @param {boolean} hideAnimation <b>True</b> to prevent animating
	 * @param {boolean} removeErrorOnChange
	 */
	window.Bark.showFormErrors = function (
		fieldname,
		message,
		hideAnimation,
		removeErrorOnChange,
	) {
		var elem = $('#' + fieldname);

		if (!hideAnimation) {
			window.Bark.addErrorAnimation(elem);
		}

		$('#' + fieldname + '-error')
			.text(message)
			.removeClass('hide');

		if (typeof removeErrorOnChange === 'undefined' || removeErrorOnChange === false) {
			elem.addClass('axm-input-error').one('change.shorformerrors', function () {
				$(this).removeClass('axm-input-error');
				$('#' + fieldname + '-error').addClass('hide');
			});
		}
	};
	/**
	 * Show form errors in the Bootstrap site
	 * @param {string} fieldName The ID of the field which had an error
	 * @param {string} message The error message
	 * @param {string} keepErrorsOnChange [optional] True to keep errors on element change
	 */
	window.Bark.showFormErrorsBootstrap = function (
		fieldName,
		message,
		keepErrorsOnChange,
	) {
		var elem = $('#' + fieldName);

		$('#' + fieldName + '-error').text(message);

		elem.addClass('is-invalid');

		if (!keepErrorsOnChange) {
			elem.one('change.shorformerrors', function () {
				$(this).removeClass('is-invalid');
			});
		}
	};
	/**
	 * Perform a basic sprintf replacement
	 * @syntax Bark.sprintf(string Str, mixed Replacement, ...)
	 * @returns {string}
	 */
	window.Bark.sprintf = function () {
		var str = arguments[0];
		var args = Array.prototype.slice.call(arguments, 1);
		var pos = 0;
		var re = /%[^%\s]/g;
		if (!str) {
			throw Bark.ex('No string to replace', 'sprintf');
		}
		if ((str.match(re) || []).length !== args.length) {
			throw Bark.ex(
				'The number of arguments does not match the number of replacements',
				'sprintf',
			);
		}
		return str
			.replace(re, function (x) {
				var val = args[pos++];
				switch (x) {
					case '%b':
						return window.parseInt(val).toString(2);
					case '%c':
						return String.fromCharCode(parseInt(val, 10));
					case '%d':
					case '%i':
						return window.parseInt(val);
					case '%f':
						return parseFloat(val);
					case '%o':
						return val.toString(8);
					case '%s':
						return val;
					case '%u':
						return window.parseInt(val) >>> 0;
					case '%x':
						return window.parseInt(val).toString(16);
					case '%X':
						return window.parseInt(val).toString(16).toUpperCase();
				}
			})
			.replace(/%%/g, '%');
	};

	/**
	 * Strip HTML to only get the text content
	 * @param {html} html
	 * @returns {string}
	 */
	window.Bark.stripHTML = function (html) {
		var container = document.createElement('div');
		container.innerHTML = html;
		return container.textContent || container.innerText;
	};

	/**
	 * Get the unixtime for a given date, or now
	 * @param {string/int} date A date specifier in ms or as a date string. Leave empty if you want to get the unix time for now
	 * @returns {int}
	 */
	window.Bark.time = function (date) {
		if (date) {
			return Math.round(new Date(date).getTime() / 1000);
		}
		return Math.round(new Date().getTime() / 1000);
	};

	/**
	 * Remove an item from an array/object
	 * @param {array|object} obj The array|object from which we will remove the item
	 * @param {int} index The index of the item to remove
	 */
	window.Bark.unset = function (obj, index) {
		if (Bark.is_a(obj, 'object')) {
			delete obj[index];
		} else {
			if (index in obj) {
				obj.splice(index, 1);
			}
		}
	};

	/**
	 * Capitalize the first character of a string
	 * @param {string} str The string to capitalize
	 * @returns {string} The string with the first character capitalized
	 */
	window.Bark.ucfirst = function (str) {
		return (str || '').trim().charAt(0).toUpperCase() + str.slice(1);
	};

	/**
	 * Capitalize every word that appears after a white space
	 * @param {string} str The string to capitalize
	 * @returns {string} The string with the first word after every space capitalized
	 */
	window.Bark.ucwords = function (str) {
		var output = '';
		var words = (str || '').trim().split(' ');
		var i;
		for (i = 0; i < words.length; i++) {
			output += Bark.ucfirst(words[i]);
		}
		return output.join(' ');
	};

	window.Bark.validateEmailFormat = function (email) {
		if (/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)) {
			return true;
		}
		return false;
	};

	/**
	 * Validation functions
	 */
	window.Bark.validate = {
		/**
		 * Validate an email address
		 * @param {string} email The email address to validate
		 * @param {object} callbacks An object with two functions, result (called when a result has been received from the server) and
		 *  error (called when there was a problem communicating with the server)
		 */
		email: function (email, callbacks) {
			$.ajax({
				url: '/tools/validate-email/',
				type: 'post',
				dataType: 'json',
				data: { email: email },
			})
				.done(function (e) {
					callbacks.result && callbacks.result(e.result);
				})
				.error(function (e) {
					callbacks.error && callbacks.error(e);
				});
		},
		/**
		 * Validate a telephone number
		 * @deprecated Use the telephone function (below) instead
		 * @param {string} number A telephone number to validate
		 * @param {function} callback A function with one to 4 parameters: success (bool), moderate (depricated), formatted number, error message
		 * @param {boolean} categorise If true, return the phone number type
		 * @param {string|null} context seller|buyer|null
		 */
		ukTel: function (number, callback, categorise = false, context = null) {
			if ($.trim(number)) {
				var postParams = {
					phone: number,
					cid: window.Bark.ENV.ccid,
					context,
				};

				if (categorise) {
					postParams.get_type = 1;
				}

				window.Bark.api(
					'bark/phone',
					postParams,
					function (data) {
						callback(
							Boolean(data.status),
							false,
							data.phonenumber,
							data.message,
							data?.line_type,
						);
					},
					function (err) {
						var errorMessage = _t('common:validation.invalid-phone');
						try {
							errorMessage = err.responseJSON.error.message;
						} catch (ignore) {}
						callback(false, false, null, errorMessage);
					},
					'POST',
					[],
					true,
				);
			} else {
				callback(false);
			}
		},
		/**
		 * Validate a phone number.
		 * @param number
		 * @param callback A function that receives an object with these attributes: status, message, lineType, localFormat, internationalFormat
		 * @param categorise If true, return the phone number type
		 */
		telephone: function (number, callback, categorise = false) {
			number = $.trim(number);
			let responseObject = {
				status: false,
				message: '',
				lineType: '',
				localFormat: '',
				internationalFormat: '',
			};

			if (!number.length) {
				responseObject.message = _t('common:validation.phone-empty');
				callback(responseObject);
			}

			let postParams = {
				phone: number,
				cid: window.Bark.ENV.ccid,
			};

			if (categorise) {
				postParams.get_type = 1;
			}

			window.Bark.api(
				'bark/phone',
				postParams,
				(data) => {
					responseObject.status = Boolean(data.status);
					responseObject.localFormat = data.phonenumber;
					responseObject.internationalFormat = data.phonenumber_e164;
					responseObject.message = data.message;
					if (data.hasOwnProperty('line_type')) {
						responseObject.lineType = data.line_type;
					}
					callback(responseObject);
				},
				(err) => {
					responseObject.message = _t('common:validation.invalid-phone');
					try {
						responseObject.message = err.responseJSON.error.message;
					} catch (ignore) {}
					callback(responseObject);
				},
				'POST',
				[],
				true,
			);
		},

		/**
		 * Validate whether User's email is within the same Bark locale
		 * @param email
		 * @param callback
		 */
		barkEmail: function (email, callback) {
			var postParams = {};
			var csrf_name = $('meta[name="csrf_name"]').attr('content');
			var csrf_value = $('meta[name="csrf_value"]').attr('content');

			postParams[csrf_name] = csrf_value;
			postParams['email'] = email;

			$.ajax({
				url: '/check-bark-email/',
				type: 'POST',
				dataType: 'JSON',
				data: postParams,
				in_progress: function () {
					$('#inlineBarkModalSpinner').show();
					$('#inlineBarkModalContent').hide();
				},
				success: function (data) {
					callback(Boolean(data.status), data.error);
				},
				error: function (data) {
					callback(false, null);
				},
			});
		},
	};

	/**
	 * Get a date in the ymd format
	 * @param {mixed} date [optional] Anything from which a Date object can be constructed. Assumed today if left empty
	 * @returns {string} A string in the form 2016-05-19 or 2016-05-04
	 */
	window.Bark.ymd = function (date) {
		var d = date === undefined ? new Date() : new Date(date);
		var month = String(d.getUTCMonth() + 1);
		var date = String(d.getUTCDate());
		return [
			d.getUTCFullYear(),
			(month < 10 ? '0' : '') + month,
			(date < 10 ? '0' : '') + date,
		].join('-');
	};

	/**
	 * Record events
	 * @param {string} eventId
	 * @param {string} eventName
	 * @param {string} eventCategory
	 */
	(window.Bark.logEvent = function (eventId, eventName, eventCategory) {
		eventName = eventName || 'click';
		return Bark.json({
			url: '/api/event-log/',
			data: {
				event_category: eventCategory,
				event_name: eventName,
				event_id: eventId,
			},
		});
	}),
		(window.Bark.removeParamFromUrl = (param) => {
			let url = new URL(window.location.href);
			let search_params = url.searchParams;
			search_params.delete(param);
			url.search = search_params.toString();
			window.history.replaceState({}, document.title, url.toString());
		}),
		$(window).on('resize', function () {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(function () {
				$(window).trigger('resizeEnd');
			}, 250);
		});

	$(window)
		.unbind('resize.orientationListen')
		.on('resize.orientationListen', function () {
			var winwidth = $(window).width();
			if (winwidth > window.Bark.consts.MOBILE_WIDTH_THRESHOLD) {
				if (window.Bark.isMobileDimensions) {
					// The state has just changed to being in desktop dimensions mode
					$(window).trigger('exitMobileDimensions');
				}
				window.Bark.isMobileDimensions = false;
			} else {
				if (!window.Bark.isMobileDimensions) {
					// The state has just changed to being in mobile dimensions mode
					$(window).trigger('enterMobileDimensions');
				}
				window.Bark.isMobileDimensions = true;
			}
			window.Bark.isMobileModalDimensions =
				winwidth <= window.Bark.consts.MOBILE_MODAL_THRESHOLD;
		})
		.resize();

	/**
	 * Set the custom attributes
	 * @param {object(DOMElement)} obj
	 * @param {object(plain)} attrs
	 * @returns {object(DOMElement)}
	 */
	function setAttributes(obj, attrs) {
		if (Bark.is_a(attrs, 'object')) {
			for (var x in attrs) {
				if (attrs.hasOwnProperty(x)) {
					var val = attrs[x];
					if (typeof val === 'boolean') {
						// Convert booleans to their integer representations
						val = val ? 1 : 0;
					}
					obj.setAttribute(x, val);
				}
			}
		}
	}

	/**
	 * Insert an element at a position in this object
	 * @param {jQuery} element The element to reposition
	 * @param {int} index A zero-keyed index of where to place the item
	 * @returns {jQuery} This object
	 */
	$.fn.insertAt = function (element, index) {
		var children = this.children();
		if (index >= children.size()) {
			this.append(element);
			return this;
		}
		var before = children.eq(index);
		$(element).insertBefore(before);
		return this;
	};

	/**
	 * Determine whether an element is visible in the viewport
	 * @param {int} offset An offset
	 * @returns {Boolean}
	 */
	$.fn.isInViewport = function (offset) {
		var elementTop = $(this).offset().top - (offset || 0);
		var elementBottom = elementTop + $(this).outerHeight();
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();

		return elementBottom > viewportTop && elementTop < viewportBottom;
	};

	/**
	 * Determine whether an element is visible in its parent element
	 * @param {int} offset [optional] An offset so that an element is "visible" before it's visible
	 * @param {jQuery} parent [optional] The element to say is the parent element
	 * @returns {boolean}
	 */
	$.fn.isVisibleInParent = function (offset, parent) {
		var t = $(this);
		var elementTop;
		var elementBottom;
		var parentTop;
		var parentBottom;

		if (!parent) {
			parent = $(this).parent();
		}

		elementTop = t.position().top - (offset || 0);
		elementBottom = elementTop + t.outerHeight();
		parentTop = parent.scrollTop();
		parentBottom = parentTop + parent.height();

		return elementBottom > parentTop && elementTop < parentBottom;
	};

	window.isAndroid = function () {
		const ua = navigator.userAgent.toLowerCase();
		return ua.indexOf('android') > -1;
	};

	/**
	 * Get main header height
	 */
	window.Bark.getHeaderHeight = () => {
		return $('nav.bark-header').height();
	};
}

/**
 * A wrapper for <code>$.ajax</code> for making calls to the API server
 * @param {string} path The path without the leading slash, e.g. <code>api/end/point</code>
 * @param {object} data [optional] An object of GET/POST parameters
 * @param {callback} success [optional] The success callback. Use to make use of auto-refresh
 * @param {callback} failure [optional] The failure callback. Use to make use of auto-refresh
 * @param {object} method [optional] HTTP request method. Defaults to GET
 * @param {object} headers [optional] An object of headers
 * @param {boolean} preventRefresh <b>TRUE</b> to suppress refreshing
 * @param {string} contentType
 * @param {boolean} processData
 * @returns {jqXHR} A jqXHR promise
 */
export const barkApi = function (
	path,
	data,
	success,
	failure,
	method,
	headers,
	preventRefresh,
	contentType,
	processData,
) {
	var payload = {};
	var allHeaders = headers || {};
	var x;

	// Remove leading slashes
	path = (path || '').trim().replace(/^\/+/, '');
	if (path && path.startsWith('https://')) {
		payload.url = path;
	} else {
		payload.url = Bark.sprintf(
			'%s/%s',
			window.Bark.ENV.api_hostname,
			path,
		);
	}

	// if  we have a experiment overrider we should add that to the data payload
	if (window.Bark.ENV.oexp) {
		try {
			var oexp = window.Bark.ENV.oexp;
			var urlObj = new URL(payload.url);
			for (const [experimentName, experimentData] of Object.entries(oexp)) {
				for (const [attribute, value] of Object.entries(experimentData)) {
					urlObj.searchParams.set(
						'oexp[' + experimentName + '][' + attribute + ']',
						value,
					);
				}
			}
			payload.url = urlObj.toString();
		} catch (error) {
			console.log(error);
		}
	}

	data = Bark.booleanToInt(data);

	payload.data = data || null;
	payload.dataType = 'JSON';
	payload.type = method || 'GET';
	payload.processData = typeof processData === 'undefined' ? 'JSON' : processData;

	if (typeof contentType !== 'undefined') {
		payload.contentType = contentType;
	}

    const acceptLanguage = Bark.ENV.language;
    if (!allHeaders['Accept-Language']) {
		allHeaders['Accept-Language'] = acceptLanguage;
	}

	if (
		window.Bark.ENV.JWT &&
		(!allHeaders.Authorization ||
			allHeaders.Authorization !== 'Bearer ' + 'Bearer ' + window.Bark.ENV.JWT)
	) {
		allHeaders.Authorization = 'Bearer ' + window.Bark.ENV.JWT;
	}

	// if bark exp session token is not already in the headers or does not match, set it.
	if (
		window.Bark.ENV.bes_token &&
		(!allHeaders['X-BES-TOKEN'] ||
			allHeaders['X-BES-TOKEN'] !== window.Bark.ENV.bes_token)
	) {
		allHeaders['X-BES-TOKEN'] = window.Bark.ENV.bes_token;
	}

	payload.beforeSend = function (xhr) {
		for (x in allHeaders) {
			xhr.setRequestHeader(x, allHeaders[x]);
		}
	};

	return $.ajax(payload)
		.done(function () {
			// Test for callback and if not null, call the callback using this context
			success && success.apply(this, Array.prototype.slice.call(arguments, 0));
		})
		.fail(function (e) {
			// Start off the fail routine by storing the current scope
			var ft = this;
			var failArgs = Array.prototype.slice.call(arguments, 0);

			if (e.status === 401 && window.Bark.ENV.JWT && !preventRefresh) {
				// Unauthorized - attempt to refresh the JWT tokens
				$.ajax({
					url: Bark.sprintf(
						'%s/%s',
						window.Bark.ENV.api_hostname,
						'auth/refresh/',
					),
					beforeSend: function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + window.Bark.ENV.JWT);
					},
					type: 'POST',
				})
					.done(function (e, textStatus, request) {
						var auth = (request.getResponseHeader('Authorization') || '').split(' ');

						if (auth[0].toLowerCase() === 'bearer' && typeof auth[1] !== 'undefined') {
							// This is a valid Authorization token in the form authorization: Bearer xx
							window.Bark.ENV.JWT = auth[1];
							// Call this function again, but this time make sure that we prevent a refresh attempt
							window.Bark.api(path, data, success, failure, method, headers, true);
						} else {
							// The auth response header is invalid
							failure && failure.apply(ft, failArgs);
						}
					})
					.fail(function () {
						failure && failure.apply(ft, failArgs);
					});

				return;
			}

			failure && failure.apply(ft, failArgs);
		});
};

export const apiVersionHeader = function (version) {
	return { Accept: 'application/vnd.bark.' + version + '+json' };
};

let ajaxTimer = null;

/**
 * Show an animation which covers the whole page to indicate that the page is loading
 * @param {int} speed [optional] The speed of the animation (defaults to 400)
 * @param {string} ease [optional] The name of the animation easing to use (defaults to 'swing')
 * @param {string} defaultMsg [optiona] The message to display (defaults to 'Please wait')
 */
export function showLoading(
	speed = 400,
	ease = 'swing',
	defaultMsg = _t('common:loading-spinner.please-wait'),
) {
	//This is the new loading overlay.
	//This is the new loading overlay.
	var loadingOverlayV2 = $('.v2-loading-overlay');
	if (loadingOverlayV2.length > 0) {
		loadingOverlayV2.addClass('show');
		loadingOverlayV2.css({ 'z-index': 100000 });
		$('.loading-box').css({ 'z-index': 100000 });
	} else {
		var loadContent;
		var spinner;
		var text;
		if (!$('.full-screen-load').length) {
			spinner = Bark.getHtml('i', null, null, 'fa fa-spin fa-spinner');
			text =
				Bark.getHtml('span') +
				Bark.getHtml('p', _t('common:loading-spinner.too-slow-message'));
			loadContent = Bark.getHtml('div', spinner + text, null, 'full-screen-load-content');
			$('body').append(
				Bark.getHtml(
					'div',
					Bark.getHtml('div', loadContent, null, 'full-screen-load-container'),
					null,
					'full-screen-load',
				),
			);
			$('.full-screen-load').css({ opacity: 0 });
		}
		$(
			'.full-screen-load .full-screen-load-container .full-screen-load-content span',
		).text(defaultMsg);
		$('.full-screen-load-content p').hide();
		if (ajaxTimer) {
			// Clear the timer if one is in progress
			window.clearTimeout(ajaxTimer);
		}
		// After ten seconds, tell the user that it's taking long
		ajaxTimer = window.setTimeout(() => {
			$('.full-screen-load-content p').show();
		}, 10000);
		$('.full-screen-load').show().finish().animate({ opacity: 1 }, speed, ease);
	}
}

/**
 * Hide the loading animation shown using Bark.showLoading()
 * @param {int} speed [optional] The speed of the animation (defaults to 400)
 * @param {string} ease [optional] The name of the animation easing to use (defaults to 'swing')
 */
export function hideLoading(speed = 400, ease = 'swing') {
	//This is the new loading overlay.
	var loadingOverlayV2 = $('.v2-loading-overlay');
	if (loadingOverlayV2.length > 0) {
		loadingOverlayV2.removeClass('show');
		loadingOverlayV2.css({ 'z-index': -1 });
		$('.loading-box').css({ 'z-index': -1 });
	}

	if (ajaxTimer !== null) {
		window.clearTimeout(ajaxTimer);
		ajaxTimer = null;
	}
	$('.full-screen-load')
		.finish()
		.animate({ opacity: 0 }, speed, ease, () => {
			$('.full-screen-load').hide();
			$('.full-screen-load-content p').hide();
		});
}

export function exposeBarkLibFunctionsToTheWindow() {
	window.Bark.showLoading = showLoading;
	window.Bark.hideLoading = hideLoading;
	window.Bark.api = barkApi;
  window.Bark.safeUrl = safeUrl;
}
