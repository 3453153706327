import moment from 'moment';
import { AsYouType } from 'libphonenumber-js'
import { CreateBarkTracking, tracking } from "../analytics";
import AdditionalDetails from "../legacy-scripts/additional-details";
import { setupDynamicPostCodeAutocomplete } from "../autocomplete-inputs/postcode-autocomplete";

//new request flow
import {
    getNewRequestState,
    isNewRequestFlow,
    setNewRequestState,
    skipUpsellLoadingNewRequestFlow,
    getAndRemovePreSelectAnswer,
    isSkipToStep,
} from "../new-request-flow/components/create-bark/new-request-state";
import { getNextValidPrevious } from "../legacy-create-bark/helpers/questions";

//experiments
import { BrowseProjectExperiment } from "./experiments/browse-project";
import { CsatExperiment } from "./experiments/customer-satisfaction";
import AutoContinueExperiment from "./experiments/auto-continue-experiment";
import TenderBrowseExperiment from "./experiments/barkmodal-tenderbrowse/tender-browse";

//features
import {
    PhoneComplianceMessageIsEnabled,
    DisplayPhoneComplianceMessage,
    PhoneComplianceMessageRemove,
} from "./features/phone-compliance-message";
import { AdditionalDetailsDesignPicker } from "./features/additional-details";
import {
    verifyExistingBuyerIfNeeded
} from "./features/sms-login";
import {
    getServePublicProfileRequestHtml,
    handleFromPublicProfile
} from "./features/from-public-profile";

import {
  hideTermsAndConditions,
  showTermsAndConditions,
} from './features/terms-and-conditions/terms-and-conditions';

//helpers
import { skipToStep } from "./helpers/steps";
import { getPageSkipQuestionsForState } from "./helpers/flow-states";
import { useNamespace, _t } from "../../libs/i18next";

export default async function CreateBarkModal() {
    await useNamespace(['buyer_create-bark', 'buyer_request_quote-public-profile']);

    /**
     * The Bark global
     * @type window.Bark
     */
    var Bark = (window.Bark = window.Bark || {});

    var createBarkTracking = new CreateBarkTracking();

    if (Bark.LOCALE.iso2c == 'in') {
		Bark.triggerHotjarRecording('create_bark_india');
	}

    /**
     * The Create Bark namespace
     * @type window.Bark.createBark
     */
    var cb = (Bark.createBark = Bark.createBark || {});

    /**
     * @type {CreateBarkTracking}
     */
    cb.createBarkTracking = createBarkTracking;

    resetCreateBarkToInitialState();

    /**
     * Preset the values
     * @type window.Bark.createBark.presets
     */
    var presets = (cb.presets = {});

    /**
     * The payload of answers to the question
     * @type object
     */
    var payload = {};

    /**
     * A flag to prevent click spam
     * @type boolean
     */
    var submitting = false;

    /**
     * A flag to prevent duplicate calls
     * @type boolean
     */
    var logging = false;
    var errlogging = false;
    var toast = false;

    /**
     * The current tab index
     * @type number
     */
    var tabIndex = 0;

    /**
     * The device pixel ratio
     * @type int
     */
    var pixelRatio = Bark.getDevicePixelRatio();

    /**
     * Constants
     * @type object
     */
    var consts = {
        /**
         * The keydown keycode for backspace
         */
        KEY_CODE_BACKSPACE: 8,
        /**
         * The keydown keycode for the letter 'e'
         */
        KEY_CODE_E: 69,
        /**
         * The keydown keycode for ENTER
         */
        KEY_CODE_ENTER: 13,
        /**
         * The maximum number of categories that should display as thumbnails before collapsing
         */
        MAX_VIS_CATEGORIES: 10,
        /**
         * The minimum number of characters that the project detail can be if the field is required
         */
        MIN_DETAIL_LENGTH: 30,
    };

    /**
     * A log of the last navigation action a user performed. This enables the software to skip questions that are show once
     * @type string Either 'next' or 'prev'
     */
    var lastAction = "next";

    /**
     * <b>True</b> to hide the text on account pages
     * @type boolean
     */
    var hidetextonaccountpage = true;

    /**
     * Flag to determine whether pre-submitting can happen
     * @type Boolean
     */
    var canpresubmit = true;

    /**
     * The last Bark Mode set. This is because the hidden input is cleared each time the modal opens, but we need it to submit.
     * Clearing the hidden input was added in this commit: f3e3b29483b31151ffe5dd3c8922493a4a58b97d
     * @type {string}
     */
    cb.lastBarkMode = "";

    cb.continueLabel = _t('buyer_create-bark:buttons.continue-button');

    /**
     * Dispatch an event
     * @param {string} eventname The name of the event to run in the set question
     * @returns {mixed} The response of the function
     */
    cb.dispatchEvent = function (eventname) {
        var q = cb.questions[cb.pos];
        if (eventname in q) {
            return q[eventname].call(eventname);
        }
    };

    function resetCreateBarkToInitialState() {
        /**
         * Data about the current category
         */
        cb.categoryData = null;
        /**
         * The current position of the user in the questions
         */
        cb.pos = 0;

        cb.skippedQuestionPositions = [];

        /**
         * If the user has selected a parent category, the id of the subcategory that the user was on
         * @type int
         */
        cb.lastCategory = 0;

        /**
         * Flag to determine whether the tel has been validated or not
         */
        cb.validatedTel = false;

        cb.categPhotoAvail = false;

        cb.introAvail = false;

        /**
         * Bark Back Experiment
         * Whether or not to ignore a history change
         */
        cb.ignoreHistory = false;

        cb.modalCategPhoto = "";

        cb.projectDashboardPage = false;
        cb.additionalDetails = true;

        cb.submittingAbandonedBark = false;

        // Remove any overrides on the back button from TenderBrowse experiment
        $(".inline-bark-btn-back").off("click");
    }

    /**
     * Go to the next question
     * @param {boolean} suppressevent [optional] <b>True</b> to suppress an event being fired
     * @param {boolean} skipSave [optional] <b>True</b> to skip the save function because it has been done by the url
     */
    cb.next = function (suppressevent, skipSaveAndValidate) {
        $(
            ".inline-bark-modal-email-errors, .inline-bark-modal-errors"
        ).addClass("hide");
        if (!suppressevent) {
            var event = new $.Event("BarkProgressToNextQuestion");
            $(window).trigger(event);

            if (event.isDefaultPrevented()) {
                return;
            }
        }

        const isValid = skipSaveAndValidate ? true : cb.validateCurrent();

        if (isValid && cb.pos < cb.questions.length) {
            var postParams = {};
            if (cb.questions[cb.pos].name) {
                postParams["exqid"] = cb.questions[cb.pos].name;
            } else {
                postParams["exqid"] = cb.questions[cb.pos].type;
            }
            postParams["ext"] = "fw";

            cb.dispatchEvent("onChangingQuestion");

            tagManager("bark-modal:leave-page");

            if (!skipSaveAndValidate) {
                saveCurrent();
            }

            cb.checkIfBranching();

            //            postParams['ans'] = cb.questions[cb.pos].currentAnswers ? JSON.stringify(cb.questions[cb.pos].currentAnswers) : null;

            lastAction = "next";

            cb.pos++;
            cb.renderCurrent();

            postParams["cid"] = $("#category_id").val();

            if (cb.questions[cb.pos].name) {
                postParams["qid"] = cb.questions[cb.pos].name;
            } else {
                postParams["qid"] = cb.questions[cb.pos].type;
                postParams["s"] = cb.questions[cb.pos].type;
            }

            if (cb.questions[cb.pos].type == "postcode") {
                postParams["s"] = "location";
            }

            postParams["et"] = "fw";

            flowQrec(postParams);
        }
    };

    /** See this and reverseCheckIfBranching below it
     *  parses the rules for the current question and determines if any questions
     *  need to be appended or removed from the current list of questions
     *  A rule consists of:
     *  action_value = the ID/label of the question that will be appended/removed
     *  action = 'append.question' | 'hide.question'
     *  value = The current question's response value which would apply the rule
     *
     * @returns {boolean}
     */
    cb.checkIfBranching = function () {
        var q = cb.questions[cb.pos];

        try {
            if (
                typeof q.rules == "undefined" ||
                q.rules.length == 0 ||
                !q.rules_status
            )
                return false;

            var appendPos = cb.pos + 1;
            var undoAppendPos = cb.pos + 1;

            var appendedList = [];

            for (var x = 0; x < q.rules.length; x++) {
                var rule = q.rules[x];
                var qid = rule.action_value;

                if (rule.value == payload[q.name]) {
                    if (rule.action == "append.question") {
                        var alreadyExists = false;

                        // ensure it doesnt exist
                        for (var t = 0; t < cb.questions.length; t++) {
                            if (cb.questions[t].name == qid)
                                alreadyExists = true;
                        }

                        if (!alreadyExists) {
                            var branchPosition = 0;
                            for (var i = 0; i < cb.branching.length; i++) {
                                //find pos of branching question
                                if (cb.branching[i].name == qid)
                                    branchPosition = i;
                            }
                            cb.questions.splice(
                                appendPos,
                                0,
                                cb.branching[branchPosition]
                            );

                            appendedList.push(qid);

                            appendPos++;
                            setQuestionsLength();
                            q = cb.questions[cb.pos];

                            $(".inline-bark-percentage-thumb").width(
                                (q.index / cb.questionsLength) * 100 + "%"
                            );
                        }
                    } else if (rule.action == "hide.question") {
                        for (var y = 0; y < cb.questions.length; y++) {
                            if (cb.questions[y].name == qid) {
                                cb.questions.splice(y, 1);
                            }
                        }

                        setQuestionsLength();
                        $(".inline-bark-percentage-thumb").width(
                            (q.index / cb.questionsLength) * 100 + "%"
                        );
                    }
                } else {
                    // Sort of reversing the actions.
                    if (rule.action == "append.question") {
                        for (var yy = 0; yy < cb.questions.length; yy++) {
                            if (
                                cb.questions[yy].name == qid &&
                                window.jQuery.inArray(qid, appendedList) == -1
                            ) {
                                cb.questions.splice(yy, 1);
                                break;
                            }
                        }
                    } else if (rule.action == "hide.question") {
                        for (var yyy = 0; yyy < cb.orgquestions.length; yyy++) {
                            if (cb.orgquestions[yyy].name == qid) {
                                var undoAlreadyExists = false;

                                for (
                                    var xx = 0;
                                    xx < cb.questions.length;
                                    xx++
                                ) {
                                    if (cb.questions[xx].name == qid)
                                        undoAlreadyExists = true;
                                }

                                if (!undoAlreadyExists) {
                                    cb.questions.splice(
                                        undoAppendPos,
                                        0,
                                        cb.orgquestions[yyy]
                                    );
                                    undoAppendPos++;
                                    setQuestionsLength();
                                }
                            }
                        }
                    }
                } // end of if not found
            } // end of FOR loop checkinf for rules
        } catch (e) {}
    };
    /** See this and checkIfBranching above
     *  parses the rules for the current question and determines if any questions
     *  in the list were caused by the rules of this question
     *  used for going back. Undoes the action of checkIfBranching
     */
    cb.reverseCheckIfBranching = function () {
        var q = cb.questions[cb.pos];
        var rulesApplied = [];
        try {
            if (
                typeof q.rules == "undefined" ||
                q.rules.length == 0 ||
                !q.rules_status
            )
                return false;

            var appendPos = cb.pos + 1;
            var undoAppendPos = cb.pos + 1;

            var appendedList = [];

            for (var x = 0; x < q.rules.length; x++) {
                var rule = q.rules[x];
                var qid = rule.action_value;

                if (rule.value == payload[q.name]) {
                    if (rule.action == "hide.question") {
                        var alreadyExists = false;

                        // ensure it doesnt exist
                        for (var t = 0; t < cb.questions.length; t++) {
                            if (cb.questions[t].name == qid)
                                alreadyExists = true;
                        }

                        if (!alreadyExists) {
                            var branchPosition = 0;
                            for (var i = 0; i < cb.branching.length; i++) {
                                //find pos of branching question
                                if (cb.branching[i].name == qid)
                                    branchPosition = i;
                            }
                            cb.questions.splice(
                                appendPos,
                                0,
                                cb.branching[branchPosition]
                            );

                            appendedList.push(qid);

                            appendPos++;
                            setQuestionsLength();
                            q = cb.questions[cb.pos];

                            $(".inline-bark-percentage-thumb").width(
                                (q.index / cb.questionsLength) * 100 + "%"
                            );
                        }
                    } else if (rule.action == "append.question") {
                        for (var y = 0; y < cb.questions.length; y++) {
                            if (cb.questions[y].name == qid) {
                                cb.questions.splice(y, 1);
                            }
                        }

                        setQuestionsLength();
                        $(".inline-bark-percentage-thumb").width(
                            (q.index / cb.questionsLength) * 100 + "%"
                        );
                    }
                } else {
                    // Sort of reversing the actions.
                    if (rule.action == "hide.question") {
                        for (var yy = 0; yy < cb.questions.length; yy++) {
                            if (
                                cb.questions[yy].name == qid &&
                                window.jQuery.inArray(qid, appendedList) == -1
                            ) {
                                cb.questions.splice(yy, 1);
                                break;
                            }
                        }
                    } else if (rule.action == "append.question") {
                        for (var yyy = 0; yyy < cb.orgquestions.length; yyy++) {
                            if (cb.orgquestions[yyy].name == qid) {
                                var undoAlreadyExists = false;

                                for (
                                    var xx = 0;
                                    xx < cb.questions.length;
                                    xx++
                                ) {
                                    if (cb.questions[xx].name == qid)
                                        undoAlreadyExists = true;
                                }

                                if (!undoAlreadyExists) {
                                    cb.questions.splice(
                                        undoAppendPos,
                                        0,
                                        cb.orgquestions[yyy]
                                    );
                                    undoAppendPos++;
                                    setQuestionsLength();
                                }
                            }
                        }
                    }
                } // end of if not found
            } // end of FOR loop checkinf for rules
        } catch (e) {}
    };

    /**
     * Enable the Continue button
     *
     */
    cb.enableContinue = function () {
        const label = _t('buyer_create-bark:view-matches');

        $(".inline-bark-btn-continue")
            .html(cb.continueLabel)
            .prop("disabled", false)
            .removeClass("disabled");

        $(".inline-bark-btn-submit")
            .html(label)
            .prop("disabled", false)
            .removeClass("disabled");
    };

    /**
     * Disable & rename the Continue button
     * i.e. while uploading assets
     *
     */
    cb.disableContinueForUploading = function () {
        // Separate lines so that two separate instances of the spinner are generated
        $(".inline-bark-btn-continue")
            .html(Bark.getHtml("span", null, null, "fa fa-spin fa-spinner"))
            .prop("disabled", true)
            .addClass("disabled");
        $(".inline-bark-btn-submit")
            .html(Bark.getHtml("span", null, null, "fa fa-spin fa-spinner"))
            .prop("disabled", true)
            .addClass("disabled");
    };

    /**
     * Go to the previous question
     */
    cb.prev = function () {
        if (cb.pos > 0) {
            $(
                ".inline-bark-modal-email-errors, .inline-bark-modal-errors"
            ).addClass("hide");
            $(".inline-bark-buttons-container .inline-bark-btn-continue").text(
                cb.continueLabel
            );

            var postParams = {};
            if (cb.questions[cb.pos].name) {
                postParams["exqid"] = cb.questions[cb.pos].name;
            } else {
                postParams["exqid"] = cb.questions[cb.pos].type;
            }
            postParams["ext"] = "bk";

            $(".inline-bark-modal-errors").addClass("hide");

            lastAction = "prev";
            cb.dispatchEvent("onChangingQuestion");

            const { prevIndex } = getNextValidPrevious(
                cb.pos,
                cb.skippedQuestionPositions
            );

            cb.pos = prevIndex;

            cb.removeAnswersAfterCurrentPosition();
            cb.reverseCheckIfBranching();

            cb.renderCurrent();

            postParams["cid"] = $("#category_id").val();
            postParams["et"] = "bk";

            if (cb.questions[cb.pos].name) {
                postParams["qid"] = cb.questions[cb.pos].name;
            } else {
                postParams["qid"] = cb.questions[cb.pos].type;
                postParams["s"] = cb.questions[cb.pos].type;
            }

            if (cb.questions[cb.pos].type == "postcode") {
                postParams["s"] = "location";
            }

            flowQrec(postParams);

            // New Buyer Location Experiment: If they accepted the prefilled location, skip the location page
            if (
                cb.questions[cb.pos].type == "postcode" &&
                $(".confirm-location-yes").prop("checked")
            ) {
                cb.prev();
            }

            //Existing buyer stuff
            resetExistingBuyerStatus(false);
        }
    };

    /**
     * Removes all answers and question HTML for questions after the current one
     */
    cb.removeAnswersAfterCurrentPosition = function () {
        for (var p = cb.pos + 1; p < cb.questions.length; ++p) {
            $("#bark-question-" + p).remove();
        }

        var tempPayload = jQuery.extend(true, {}, payload);

        // CLEAR THE PAYLOAD ANSWERS FOR QUESTIONS AFTER THE CURRENT POS
        var answersToKeep = [];
        for (var i = 0; i <= cb.pos; i++) {
            answersToKeep.push(cb.questions[i].name);
            answersToKeep.push(cb.questions[i].name + "_other");
        }

        // Loop of every answer in payload and check if to keep or delete
        for (var key in tempPayload) {
            if (key.indexOf("-") > -1) {
                if (
                    answersToKeep.indexOf(key) < 0 &&
                    cb.questions[cb.pos].name != key
                ) {
                    delete payload[key];
                }
            }
        }

        // If we're back at the beginning, reset the questions entirely
        if (cb.pos == 0) {
            cb.questions = cb.orgquestions.slice(0);
        }
    };

    /**
     * Rewinds the browser history to before the bark started.
     * @returns {boolean}
     */
    cb.rewindHistoryToBeforeBark = function () {
        if (
            !window.history.state ||
            !window.history.state.hasOwnProperty("pos")
        ) {
            window.setTimeout(function () {
                Bark.setPageState({}, "#");
            }, 50);
            return false;
        }
        cb.ignoreHistory = true;
        history.back();
        window.setTimeout(function () {
            cb.rewindHistoryToBeforeBark();
        }, 50);
    };

    /**
     * Returns an array of human-readable answers that the user has given to the custom questions for this category.
     * @returns {Array}
     */
    cb.getHumanReadableAnswers = function () {
        var humanReadableAnswers = [];
        for (var questionIndex in cb.questions) {
            var question = cb.questions[questionIndex];
            if (!question.omitFromCount) {
                var fields = $('[name="' + question.name + '"]'),
                    answers = [];
                switch (question.type) {
                    case "range":
                        answers.push(cb.getRangeAnswerValue(question).value);
                        break;
                    case "text":
                        fields.get(0) &&
                            answers.push(sanitiseInput(fields.get(0).value));
                        break;
                    case "textarea":
                        answers.push(
                            fields.get(0).value.replace(/\n/g, "<br>")
                        );
                        break;
                    case "select":
                    case "photoselect":
                    case "checkbox":
                    case "photocheck":
                        fields.each(function () {
                            if ($(this).is(":checked")) {
                                if (
                                    $(this)
                                        .attr("id")
                                        .match(/_other$/)
                                ) {
                                    var otherValue = sanitiseInput(
                                        $(
                                            '[name="' +
                                                question.name +
                                                '_other"]'
                                        ).val()
                                    );
                                    if (otherValue) {
                                        answers.push(otherValue);
                                    }
                                } else {
                                    var labelElement = $(
                                        '[for="' + $(this).attr("id") + '"]'
                                    ).eq(0);
                                    if (labelElement) {
                                        answers.push(labelElement.text());
                                    }
                                }
                            }
                        });
                        break;
                }
                if (answers.length) {
                    humanReadableAnswers.push({
                        questionText: question.label,
                        answerText: answers.join(", "),
                    });
                }
            }
        }
        return humanReadableAnswers;
    };

    /**
     * A safe method for retrieving the content of an experiment.
     * @param string experimentName The name of the experiment
     * @returns object The content of the experiment. Returns an empty object if the experiment doesn't exist or has no content
     */
    cb.getExperimentContent = function (experimentName) {
        if (!cb.categoryData) {
            return {};
        }
        if (!("experiments" in cb.categoryData)) {
            return {};
        }
        if (!(experimentName in cb.categoryData.experiments)) {
            return {};
        }
        return cb.categoryData.experiments[experimentName].content || {};
    };

    /**
     * Add experiment data to the
     * @param {string} experiment_name
     * @param {int} variant
     * @returns {number}
     */
    cb.addExperimentData = ({ experiment_name, variant, id }) => {
        if (!cb.categoryData) {
            // It's too early so cannot do anything
            window.bugsnagClient &&
                window.bugsnagClient.notify(
                    new Error(
                        "Attempt to append experiment data before category data"
                    )
                );
            return 0;
        }

        if (!("experiments" in cb.categoryData)) {
            cb.categoryData.experiments = {};
        }

        cb.categoryData.experiments[experiment_name] = { variant, id };
    };

    /**
     * A safe method for retrieving the variant ID of an experiment.
     * @param string experimentName The name of the experiment
     * @returns number The variant number. Returns 0 if the experiment doesn't exist
     */
    cb.getExperimentVariant = function (experimentName) {
        if (!cb.categoryData) {
            return 0;
        }
        if (!("experiments" in cb.categoryData)) {
            return 0;
        }
        if (!(experimentName in cb.categoryData.experiments)) {
            return 0;
        }
        return parseInt(
            cb.categoryData.experiments[experimentName].variant,
            10
        );
    };

    /**
     * A safe method for retrieving the variant ID of an experiment.
     * @param string settingName The name of the setting
     * @returns number The variant number. Returns 0 if the experiment doesn't exist
     */
    cb.getSetting = function (settingName) {
        if (!("settings" in cb.categoryData)) {
            return null;
        }
        if (!(settingName in cb.categoryData.settings)) {
            return null;
        }
        return cb.categoryData.settings[settingName];
    };

    /**
     * A safe method for retrieving the variant ID of an experiment.
     * @param string settingName The name of the setting
     * @returns number The variant number. Returns 0 if the experiment doesn't exist
     */
    cb.getSettingBool = function (settingName) {
        var setting = cb.getSetting(settingName);

        switch (setting) {
            case true:
            case "true":
            case 1:
            case "1":
                return true;
            default:
                return false;
        }
    };

    /**
     * A safe method for retrieving the experiments_session_data ID of an experiment.
     * @param string experimentName The name of the experiment
     * @returns number The session ID. Returns 0 if the experiment doesn't exist
     */
    cb.getExperimentId = function (experimentName) {
        if (!("experiments" in cb.categoryData)) {
            return 0;
        }
        if (!(experimentName in cb.categoryData.experiments)) {
            return 0;
        }
        return cb.categoryData.experiments[experimentName].id;
    };

    /**
     * Render a multiple choice question
     * @param {object} question Object describing the current question
     * @param {int} not used here but all render functions have it
     * @returns {html}
     */
    cb.renderCheckbox = function (question, collapse, preSelectIds = []) {
        var output = "";
        var curOpt;
        var checkboxInput;
        var label;
        var inputId;
        var collapse = consts.MAX_VIS_CATEGORIES;
        var checkboxAttrs = {};

        for (var i = 0; i < question.options.length; i++) {
            curOpt = question.options[i];
            inputId = "custom_question_checkbox_" + cb.pos + "_" + curOpt.id;

            if (curOpt.id === "other") {
                var tmpOtherVal = "";

                if (Bark.is_array(presets[question.name])) {
                    for (
                        var ix = 0;
                        ix < (presets[question.name][0] || []).length;
                        ix++
                    ) {
                        if (presets[question.name][0][ix] == "other") {
                            tmpOtherVal = presets[question.name + "_other"][0];
                        }
                    }
                }

                // An 'other' field requires an input next to it.
                var otherInput = Bark.getHtml(
                    "input",
                    null,
                    inputId + "_value",
                    "inline-bark-q-label-input",
                    {
                        type: "text",
                        placeholder: _t('buyer_create-bark:inputs.other.placeholder'),
                        value: tmpOtherVal,
                        name: question.name + "_other",
                        "tab-index": ++tabIndex,
                    }
                );
                label = Bark.getHtml(
                    "label",
                    otherInput,
                    null,
                    "inline-bark-q-label other",
                    { for: inputId }
                );
            } else {
                label = Bark.getHtml(
                    "label",
                    curOpt.label,
                    null,
                    "inline-bark-q-label",
                    { for: inputId }
                );
            }

            checkboxAttrs = {
                type: "checkbox",
                class: "inline-bark-checkbox",
                name: question.name,
                "tab-index": ++tabIndex,
            };

            if (
                Bark.objectSize(presets) &&
                Bark.in_array(curOpt.id, presets[question.name] || [])
            ) {
                checkboxAttrs.checked = "checked";
            }

            if (preSelectIds.includes(curOpt.id)) {
                checkboxAttrs.checked = "checked";
            }

            if (Bark.is_array(presets[question.name])) {
                for (
                    var iy = 0;
                    iy < (presets[question.name][0] || []).length;
                    iy++
                ) {
                    if (presets[question.name][0][iy] == curOpt.id) {
                        checkboxAttrs.checked = "checked";
                    }
                }
            }

            checkboxInput = Bark.getHtml(
                "input",
                null,
                inputId,
                null,
                checkboxAttrs
            );
            output += Bark.getHtml(
                "div",
                checkboxInput + label,
                null,
                "inline-bark-q-checkbox can-highlight inline-bark-q new-project-field",
                {
                    style: i < collapse ? "" : "display:none;",
                }
            );
        }

        if (question.options.length > collapse) {
            output += Bark.getHtml(
                "div",
                Bark.getHtml("span", _t('buyer_create-bark:links.show-more-link'), null, null, {
                    "data-collapse": collapse,
                }),
                null,
                "check-showmore"
            );
        }

        if (typeof question.info !== "undefined" && question.info.length > 6) {
            output += cb.getQinfoContent(question.info);
        }

        return output;
    };

    cb.getQinfoContent = function (info) {
        var output = "";
        var content = "";

        if (isFrontendV2WithBootstrap()) {
            content = '<span class="micons-info_medium"></span> ' + info;
            output = Bark.getHtml("span", content, null, "q-info");
        } else {
            content =
                '<i class="fa fa-info-circle" aria-hidden="true"></i> ' + info;
            output = Bark.getHtml("div", content, null, "q-info");
        }

        return output;
    };

    /**
     * Render a select field
     * @param {object} question Object describing the current question
     * @param {int} collapse [optional] The number of items at which to hide more options
     * @param preSelect {array}
     * @returns {html}
     */
    cb.renderSelect = function (question, collapse, preSelectIds = []) {
        var output = "";
        var curOpt;
        var radioInput;
        var label;
        var inputId;
        var radioAttrs = {};

        if (typeof collapse == "undefined") {
            collapse = consts.MAX_VIS_CATEGORIES;
        }

        for (var i = 0; i < question.options.length; i++) {
            curOpt = question.options[i];
            inputId = "custom_question_radio_" + cb.pos + "_" + curOpt.id;
            let inputClass = "inline-bark-radio";
            let labelClass = "inline-bark-q-label";
            let divClass =
                "inline-bark-q-radio can-highlight inline-bark-q new-project-field";

            if (curOpt.id === "other") {
                var otherVal = "";

                if (
                    Bark.objectSize(presets) &&
                    Bark.in_array(curOpt.id, presets[question.name] || [])
                ) {
                    otherVal = presets[question.name + "_other"];
                } else if (preSelectIds.includes(curOpt.id)) {
                    otherVal = payload[question.name + "_other"];
                }

                // An 'other' field requires an input next to it
                var otherInput = Bark.getHtml(
                    "input",
                    null,
                    inputId + "_value",
                    "inline-bark-q-label-input inline-bark-q-radio-other",
                    {
                        type: "text",
                        placeholder: _t('buyer_create-bark:inputs.other.placeholder'),
                        name: question.name + "_other",
                        value: otherVal,
                        "tab-index": ++tabIndex,
                    }
                );
                label = Bark.getHtml(
                    "label",
                    otherInput,
                    null,
                    labelClass + " other",
                    { for: inputId }
                );
            } else {
                if (cb.AutoContinueExperiment) {
                    divClass += " auto-continue-wrapper";
                    labelClass += " auto-continue-cb";
                }
                label = Bark.getHtml("label", curOpt.label, null, labelClass, {
                    for: inputId,
                });
            }

            // Preselect any values if needed
            radioAttrs = {
                type: "radio",
                class: inputClass,
                name: question.name,
                value: curOpt.id,
                "tab-index": ++tabIndex,
            };
            if (
                Bark.objectSize(presets) &&
                Bark.in_array(curOpt.id, presets[question.name] || [])
            ) {
                radioAttrs.checked = "check";
            }

            if (preSelectIds.includes(curOpt.id)) {
                radioAttrs.checked = "check";
            }

            radioInput = Bark.getHtml("input", null, inputId, null, radioAttrs);

            output += Bark.getHtml("div", radioInput + label, null, divClass, {
                style: i < collapse ? "" : "display:none;",
            });
        }

        if (question.options.length > collapse) {
            // There are some hidden categories, add a view more button
            output += Bark.getHtml(
                "div",
                Bark.getHtml("span", _t('buyer_create-bark:links.show-more-link'), null, null, {
                    "data-collapse": collapse,
                }),
                null,
                "radio-showmore"
            );
        }

        if (typeof question.info !== "undefined" && question.info.length > 6) {
            output += cb.getQinfoContent(question.info);
        }

        return output;
    };

    /**
     * Render a text field
     * @param {object} question Object describing the current question
     * @returns {html}
     */
    cb.renderText = function (
        question,
        collapseLimit,
        preSelectIds,
        textPrefillAnswer
    ) {
        var output = "";
        const hasPreSelectIds = preSelectIds && preSelectIds !== "";
        const value = hasPreSelectIds
            ? preSelectIds
            : textPrefillAnswer
            ? textPrefillAnswer
            : "";

        let attrs = {
            name: question.name,
            type: "text",
            "tab-index": ++tabIndex,
            value,
        };

        var input = Bark.getHtml("input", null, null, null, attrs);

        output += Bark.getHtml(
            "div",
            input,
            null,
            "inline-bark-q-text inline-bark-q new-project-field"
        );

        if (typeof question.info !== "undefined" && question.info.length > 6) {
            output += cb.getQinfoContent(question.info);
        }

        return output;
    };

    /**
     * Render a text area
     * @param {object} question Object describing the current question
     * @returns {html}
     */
    cb.renderTextarea = function (question, notUsed, preSelectIds) {
        var output = "";

        const value = preSelectIds && preSelectIds !== "" ? preSelectIds : null;

        var ta = Bark.getHtml("textarea", value, null, null, {
            name: question.name,
            rows: 5,
        });

        output = Bark.getHtml(
            "div",
            ta,
            null,
            "inline-bark-q-text inline-bark-q-textarea inline-bark-q new-project-field"
        );

        if (typeof question.info !== "undefined" && question.info.length > 6) {
            output += cb.getQinfoContent(question.info);
        }

        return output;
    };

    /**
     * Render a number input
     * @param {object} question An object describing the current question
     * @returns {html}
     */
    cb.renderNumber = function (question, notUsed, preSelectIds) {
        const value = preSelectIds && preSelectIds !== "" ? preSelectIds : null;
        var opts = {
            name: question.name,
            type: "text",
            "tab-index": ++tabIndex,
            value,
        };
        var input;
        var output = "";
        var placeholderKey = 'buyer_create-bark:inputs.number.labels.default';


        if (question.min || (question.min !== "" && +question.min === 0)) {
            opts.min = +question.min;
        }
        if (
            (question.max || (question.max !== "" && +question.max === 0)) &&
            +question.min !== +question.max
        ) {
            opts.max = +question.max;
        }

        if (opts.min && opts.max) {
            placeholderKey = 'buyer_create-bark:inputs.number.labels.min-and-max';
        } else if (opts.min && !opts.max) {
            placeholderKey = 'buyer_create-bark:inputs.number.labels.min-only';
        } else if (opts.max && !opts.min) {
            placeholderKey = 'buyer_create-bark:inputs.number.labels.max-only';
        }

        opts.placeholder = _t(placeholderKey, {
            minimum_number: opts.min,
            maximum_number: opts.max
        });

        input = Bark.getHtml("input", null, null, null, opts);

        output = Bark.getHtml(
            "div",
            input,
            null,
            "inline-bark-q-text inline-bark-q-number inline-bark-q new-project-field"
        );

        if (typeof question.info !== "undefined" && question.info.length > 6) {
            output += cb.getQinfoContent(question.info);
        }

        return output;
    };

    /**
     * Render an address lookup input
     * @param {object} question An object describing the current question
     * @returns {html}
     */
    cb.renderAddressLookup = function (question) {
        var output = "";

        var input = Bark.getHtml(
            "input",
            null,
            "bark-address-lookup",
            "js-addr-lookup",
            {
                name: question.name,
                type: "text",
                "tab-index": ++tabIndex,
            }
        );
        var error = Bark.getHtml(
            "input",
            null,
            "address-lookup-error",
            "hidden d-none",
            {
                value: "",
                type: "hidden",
            }
        );
        var lookedup = Bark.getHtml(
            "input",
            null,
            "address-lookup-text-value",
            "hidden d-none",
            {
                value: "",
                type: "hidden",
            }
        );
        output += Bark.getHtml(
            "div",
            input,
            null,
            "inline-bark-q-text inline-bark-q new-project-field"
        );
        output += Bark.getHtml("div", error, null, "hidden d-none");
        output += Bark.getHtml("div", lookedup, null, "hidden d-none");

        return output;
    };

    /**
     * Render a date question
     * @param {object} question The object describing the current question
     * @returns {html}
     */
    cb.renderDate = function (question, notUsed, preSelectIds) {

        // preselect inputs
        const selected = {};
        if(preSelectIds && typeof preSelectIds === 'string'){
            if (preSelectIds !== 'unsure' && preSelectIds !== 'other') {
              selected['date'] = preSelectIds.split(',')[0]?.trim() || '';
              if (question.has_time) {
                selected['time'] = preSelectIds.split(',')[1]?.trim() || '';
              }
            }
            selected['unsure'] = preSelectIds === 'unsure';
            selected['other'] = preSelectIds === 'other';
            if(selected.other){
                selected['otherText'] = payload[question.name + "_other"];
            }
        }
        const isDateDisabled = selected.unsure || selected.other;

      var pickeropts = {
        placeholder: _t('buyer_create-bark:inputs.date-picker.placeholder'),
        'tab-index': ++tabIndex,
        'data-mindate': question.date_min || null,
        'data-maxdate': question.date_max || null,
        class: 'bark-date',
        value: selected?.date || '',
        ...isDateDisabled && {disabled: true},
      };
      var inner;
      var from;
      var to;
      var times;
      var timesLabel;
      var unsure = '';
      var na = '';
      var output = '';
      var other = '';
      var other_input = '';
      var otherinner = '';
      var naId = 'bark-na-' + question.name;
      var otherId = 'bark-other-' + question.name;
      var unsureId = 'bark-unsure-' + question.name;

      if ($('body').hasClass('mobile')) {
        pickeropts.type = 'date';
        pickeropts.class = 'bark-date no-pikaday';
      } else {
        pickeropts.readonly = 'readonly';
      }

      inner = Bark.getHtml('input', null, null, null, pickeropts);

      if (question.has_time) {
        // Allow a user to set the time
        timesLabel = Bark.getHtml(
          'p',
          _t('buyer_create-bark:inputs.date-picker.time-instruction', {
            example_time: Bark.date('H:i')
          }),
        );
        from = Bark.getHtml('input', null, null, 'bark-date-time', {
          type: 'time',
          placeholder: _t('buyer_create-bark:inputs.date-picker.from-what-time-placeholder'),
          'tab-index': ++tabIndex,
          value: selected?.time || '',
          ...isDateDisabled && {disabled: true},
        });
        to = Bark.getHtml('input', null, null, 'bark-date-time', {
          type: 'time',
          placeholder: _t('buyer_create-bark:inputs.date-picker.to-what-time-placeholder'),
          'tab-index': ++tabIndex,
          ...isDateDisabled && {disabled: true},
        });
        times = Bark.getHtml('div', _t('buyer_create-bark:inputs.date-picker.from-what-time-placeholder') + from, null, 'bark-date-time-from');
        times += Bark.getHtml('div', _t('buyer_create-bark:inputs.date-picker.to-what-time-placeholder') + to, null, 'bark-date-time-to');
        inner += Bark.getHtml(
          'div',
          timesLabel + times,
          null,
          'bark-date-time-container',
        );
      }
      if (question.allow_unsure) {
        // Allow a user to say that they don't yet know
        unsure = Bark.getHtml(
          'div',
          Bark.getHtml('input', null, unsureId, 'bark-date-unsure custom-control-input', {
            type: 'checkbox',
            'tab-index': ++tabIndex,
            ...selected.unsure && {checked: true},
          }) +
            Bark.getHtml('label', _t('buyer_create-bark:inputs.date-picker.i-am-not-sure-of-date-yet-label'), null, 'custom-control-label', {
              for: unsureId,
            }),
          null,
          'custom-control custom-checkbox',
        );
      }
      if (question.allow_na) {
        // Allow a user to say that N/A
        na = Bark.getHtml(
          'div',
          Bark.getHtml('input', null, naId, 'bark-date-na custom-control-input', {
            type: 'checkbox',
            'tab-index': ++tabIndex,
          }) +
            Bark.getHtml('label', _t('buyer_create-bark:inputs.date-picker.date-option-not-applicable'), null, 'custom-control-label', {
              for: naId,
            }),
          null,
          'custom-control custom-checkbox',
        );
      }
      if (question.allow_other) {
        // Allow a user to say other
        const isOtherPreSelected = selected.other;
        otherinner = Bark.getHtml(
          'input',
          null,
          otherId,
          'bark-date-other custom-control-input',
          {
              type: 'checkbox',
              'tab-index': ++tabIndex,
              ...isOtherPreSelected && {checked: true},
             },
        );
        otherinner += Bark.getHtml('label', _t('buyer_create-bark:inputs.other.placeholder'), null, 'custom-control-label', {
          for: otherId,
        });
        other = Bark.getHtml('div', otherinner, null, 'custom-control custom-checkbox');

        other_input = Bark.getHtml(
          'div',
          Bark.getHtml('input', null, null, null, {
            placeholder: _t('buyer_create-bark:inputs.other.placeholder'),
            type: 'text',
            'tab-index': ++tabIndex,
            value: selected?.otherText || '',
          }),
          null,
          'bark-date-other-text',
          {
            ...isOtherPreSelected && {style: 'display: block;'},
          }
        );
      }
      inner = Bark.getHtml('div', inner, null, 'bark-date-main-cont',{
        ...(selected.unsure || selected.other) && {style: 'opacity: 0.5;'}
      });

      if (isFrontendV2WithBootstrap()) {
        inner += '<br>';
      }

      output = Bark.getHtml(
        'div',
        inner + unsure + na + other + other_input,
        null,
        'inline-bark-q-date inline-bark-q inline-bark-q-text new-project-field',
      );

      if (typeof question.info !== 'undefined' && question.info.length > 6) {
        output += cb.getQinfoContent(question.info);
      }

      return output;
    };

    cb.renderUpload = function (question) {
        var top = "";
        var output = "";
        var uploadSection = "";

        if (question.has_textarea) {
            if (question.textarea_required) {
                top = `<h5>${_t('buyer_create-bark:inputs.photo-upload.question-label-with-optional', {project_question_label: question.textarea_label})}</h5>`;
            } else {
                top = `<h5>${question.textarea_label}</h5>`;
            }
            top += Bark.getHtml("textarea", null, null, null, {
                name: question.name,
                rows: 5,
                "tab-index": ++tabIndex,
            });

            output = Bark.getHtml(
                "div",
                top,
                null,
                "inline-bark-q-textarea inline-bark-q new-project-field"
            );
        }

        if (isFrontendV2WithBootstrap()) {
            uploadSection = getUploadHtml(false);
        } else {
            uploadSection +=
                "" +
                "<style>" +
                ".project-image .photo-inner {float:inherit!important;}" +
                ".new-project-intro {margin-bottom: 14px;}" +
                "</style>";

            uploadSection +=
                '<div class="dropzoneContainer">' +
                '<div id="modalUploadFiles">' +
                '<div class="fileinput-button needsclick">' +
                '<span class="fa fa-plus"></span>' +
                "</div>" +
                "</div>" +
                '<div id="previews-modal"></div>' +
                "</div>";
        }

        output += Bark.getHtml(
            "div",
            uploadSection,
            null,
            "inline-bark-q inline-bark-additional"
        );

        if (typeof question.info !== "undefined" && question.info.length > 6) {
            output += cb.getQinfoContent(question.info);
        }

        return output;
    };

    /**
     * Render the more info question
     * @param {object} question Object describing the current question
     * @returns {html}
     */
    cb.renderAdditional = function (question) {
        var i18nKey = 'buyer_create-bark:inputs.additional-details.label' + (cb.questionsLength === 1 ? '' : '-with-optional');
        var top = `<h5>${_t(i18nKey)}</h5>`;
        var output;
        var uploadSection = "";

        top += Bark.getHtml("textarea", null, "project_detail", null, {
            name: "project_detail",
            rows: 5,
            "tab-index": ++tabIndex,
        });
        if (question.show_local_checkbox) {
            var showLocalLabel = _t('buyer_create-bark:inputs.additional-details.local-professional.question-label');

            try {
                var modalText =
                    cb.categoryData.categories[cb.lastCategory].modal_text;
                if (typeof modalText == "undefined" || modalText == null)
                    modalText = {};
                showLocalLabel =
                    modalText.additional_details_someone_local.value;
            } catch (e) {}

            if (isFrontendV2WithBootstrap()) {
                top +=
                    `<h5 class="my-2">${_t('buyer_create-bark:inputs.additional-details.local-professional.question-label')}</h5>` +
                    '<div class="someone-local custom-control custom-checkbox pb-2">' +
                    '<input id="is_local" type="checkbox" name="is_local" class="custom-control-input">' +
                    `<label for="is_local" class="custom-control-label">${_t('buyer_create-bark:inputs.additional-details.local-professional.local-only-label')}</label>` +
                    "</div>";
            } else {
                // The category can be national. Create the element that allows a user to choose
                var showLocal = Bark.getHtml("h5", showLocalLabel);
                var lcb = Bark.getHtml("input", null, "is_local", null, {
                    type: "checkbox",
                    name: "is_local",
                    "tab-index": ++tabIndex,
                });
                lcb += Bark.getHtml("label", _t('buyer_create-bark:inputs.additional-details.local-professional.local-only-label'), null, null, {
                    for: "is_local",
                });
                top += Bark.getHtml(
                    "div",
                    showLocal + lcb,
                    "",
                    "someone-local"
                );
            }
        }
        output = Bark.getHtml(
            "div",
            top,
            null,
            "inline-bark-q-textarea inline-bark-q inline-bark-additional"
        );

        // Check if upload type was already shown
        var hadUpload = false;

        cb.questions.map(function (q) {
            if (q.type == "upload") {
                hadUpload = true;
            }
        });

        if (hadUpload) return output;

        uploadSection = getUploadHtml(true);

        output += Bark.getHtml(
            "div",
            uploadSection,
            null,
            "inline-bark-q inline-bark-additional"
        );

        return output;
    };

    /**
     * Renders the HTML for a list of answers the user has provided for custom questions.
     * @returns {string}
     */
    cb.renderAnswerList = function () {
        var answers = cb.getHumanReadableAnswers(),
            answerRows = answers
                .map(function (answer) {
                    return Bark.getHtml(
                        "div",
                        Bark.getHtml("p", answer.questionText, null, null) +
                            Bark.getHtml("span", answer.answerText, null, null),
                        null,
                        "answer-row"
                    );
                })
                .join("");
        return Bark.getHtml(
            "div",
            Bark.getHtml("div", answerRows, null, "answers"),
            null
        );
    };

    function getUploadHtml(hasTitle) {
        var uploadSection = "";

        // uploadSection +='' +
        //     '<style>' +
        //         '.project-image .photo-inner {float:inherit!important;}'+
        //         '.new-project-intro {margin-bottom: 14px;}'+
        //     '</style>';

        if (isFrontendV2WithBootstrap()) {
            if (hasTitle) {
                uploadSection += `<h5>${_t('buyer_create-bark:inputs.photo-upload.title')}</h5>`;
            }

            uploadSection +=
                '<div class="dropzoneContainer">' +
                '<div id="modalUploadFiles">' +
                '<div class="fileinput-button needsclick">' +
                `<span class="text-light-grey">
                    ${
                        _t('buyer_create-bark:inputs.photo-upload.drag-files-or-browse.text', {
                            browse_link: `<span class="text-primary cursor-pointer"><u>${_t('buyer_create-bark:inputs.photo-upload.drag-files-or-browse.browse-text')}</u></span>`,
                            interpolation: {escapeValue: false}
                        })
                    }
                </span>` +
                "</div>" +
                '<div id="previews-modal"></div>' +
                "</div>" +
                "</div>";
        } else {
            if (hasTitle) {
                uploadSection += `
                    <h5>
                        ${
                            _t('buyer_create-bark:inputs.photo-upload.attach-images-or-files.text', {
                                optional_label: `<span>(${_t('buyer_create-bark:inputs.photo-upload.attach-images-or-files.optional-text')})</span>`,
                                interpolation: {escapeValue: false}
                            })
                        }
                    </h5>
                `;
            }

            uploadSection +=
                '<div class="dropzoneContainer">' +
                '<div id="modalUploadFiles">' +
                '<div class="fileinput-button needsclick">' +
                '<span class="fa fa-plus"></span>' +
                "</div>" +
                "</div>" +
                '<div id="previews-modal"></div>' +
                "</div>";
        }

        return uploadSection;
    }

    /**
     * Render the upsell loading screen
     */
    cb.renderUpsellLoading = function () {
        setTimeout(cb.next, 3000);
        if ($(".reveal-modal").length) {
            return (
                '<div class="new-project-container" id="inline-bark-question"><div class="center">' +
                "<div class='uil-ripple-css' style='transform:scale(0.5);'><div></div><div></div></div>" +
                renderLoadingText() +
                "</div></div>"
            );
        } else {
            return (
                '<div class="new-project-container" id="inline-bark-question"><div class="center">' +
                "<div class='spinner-grow text-success my-4' style='width: 6em; height: 6em;'><div></div><div></div></div>" +
                renderLoadingText() +
                "</div></div>"
            );
        }
    };

    /**
     * Render the upsell success screen
     */
    cb.renderUpsell = function () {
        var content;
        if ($(".reveal-modal").length > 0) {
            // Show an image explaining how bark works
            content =
                '<div class="new-project-container" id="inline-bark-question"><div class="center"><p class="bark-pseudo-title">' +
                renderSearchResponseHeadingText() +
                '</p><i class="fa fa-check-circle" aria-hidden="true"></i></div>' +
                '<p class="inline-bark-modal-hide-p inline-bark-modal-hide-p-alt center">' +
                renderSearchResponseSubheadingText() +
                "</p></div>";
        } else {
            content =
                '<div class="new-project-container" id="inline-bark-question">' +
                '<div class="center">' +
                '<div class="d-flex success-circle align-items-center justify-content-center rounded-circle mx-auto mt-6 mb-4" aria-hidden="true">' +
                '<img src="https://d18jakcjgoan9.cloudfront.net/img/barkv2/custom-icons/modal-success-tick.png" alt="" width="51" height="40">' +
                "</div>" +
                '<h4 class="mb-3 px-3 px-sm-4 lh-md">' +
                renderSearchResponseHeadingText() +
                "</h4>" +
                "</div>" +
                "</div>";
        }
        return content;
    };

    /**
     * Render the tender / browse modal for browse experiment
     */
    cb.renderTenderBrowse = function () {
        // Get the category data
        let categoryData = cb.categoryData.categories;
        let categoryId = $("#category_id").val();
        let category = categoryData[categoryId];
        let categoryName = category?.name?.toLowerCase() ?? _t('common:default-provider-noun-plural').toLowerCase();
        let providerNoun =
            category?.provider_noun?.toLowerCase() ?? _t('common:default-provider-noun').toLowerCase();
        let findMeAProString =
            category?.find_me_a_pro_string ?? _t('buyer_create-bark:find-me-a-professional');
        let providerNounPlural =
            category?.provider_noun_plural?.toLowerCase() ?? _t('common:default-provider-noun-plural').toLowerCase();

        // Check if experiment is active and has a variant
        let variant = cb.getExperimentVariant("tender_browse_split") ?? null;
        let variantData = cb.TenderBrowseExperiment.getVariantData(
            variant,
            categoryName,
            providerNoun,
            findMeAProString,
            providerNounPlural
        );
        cb.TenderBrowseExperiment.setCategoryId(categoryId);
        cb.TenderBrowseExperiment.track(
            "Buyer - Place Bark - Tender vs Browse - View"
        );

        if (variantData && variantData.displayBottomSection == true) {
            // Add bottom section
            $("#js-tenderbrowse-bottomsection-container").html(
                variantData.bottomSectionHtml
            );
            $("#js-tenderbrowse-bottomsection-container").show();
            return variantData.variantHtml;
        } else if (variantData) {
            return variantData.variantHtml;
        } else {
            return "";
        }
    };

    /**
     * Render public profile quote step
     */
    cb.renderPublicProfileQuote = function () {

        // Get the category data
        const categoryData = cb.categoryData.categories;
        const categoryId = $("#category_id").val();
        const category = categoryData[categoryId];
        const providerNounPlural =
            category?.provider_noun_plural?.toLowerCase() ?? _t('common:default-provider-noun-plural').toLowerCase();

        return getServePublicProfileRequestHtml(
            providerNounPlural
        );
    };

    /**
     * Render the buyer's information
     * @returns {html}
     */
    cb.renderBuyerInfo = function () {
        var output = "";
        var inner = "";
        var telDesc = "";
        var tel = "";
        var name = "";
        var email = "";
        var inputClasses = "inline-bark-q-new-user";
        showTermsAndConditions();

        var iAmHappy = _t('buyer_create-bark:consent-to-marketing');
        var occasionalMarketingEmailInner =
            Bark.getHtml(
                "input",
                null,
                "inline-bark-teldesc",
                "custom-control-input",
                {
                    type: "checkbox",
                    checked: "checked",
                }
            ) +
            Bark.getHtml("label", iAmHappy, null, "custom-control-label", {
                for: "inline-bark-teldesc",
            });
        var occasionalMarketingEmail = Bark.getHtml(
            "div",
            occasionalMarketingEmailInner,
            null,
            "optout custom-control custom-checkbox"
        );

        if ((cb.sellerLoggedIn && cb.userName === null) || !cb.userLoggedIn) {
            // If the user is not logged in, ask the user for their details
            name = Bark.getHtml(
                "label",
                _t('buyer_create-bark:buyer-info.name.label'),
                null,
                "inline-bark-new-user-label",
                {
                    for: "inline-bark-new-user-name",
                }
            );

            name += Bark.getHtml(
                "input",
                null,
                "inline-bark-new-user-name",
                inputClasses,
                {
                    placeholder: _t('buyer_create-bark:buyer-info.name.placeholder'),
                    name: "name",
                    type: "text",
                    "tab-index": ++tabIndex,
                }
            );
            inner += Bark.getHtml(
                "div",
                name,
                null,
                "new-project-field inline-bark-q-cont"
            );
        }

        if (
            cb.categoryData &&
            cb.categoryData.user &&
            !cb.categoryData.user.userLoggedIn
        ) {
            email = Bark.getHtml(
                "label",
                _t('buyer_create-bark:buyer-info.email.label'),
                null,
                "inline-bark-new-user-label",
                {
                    for: "inline-bark-new-user-email",
                }
            );
            email += Bark.getHtml(
                "input",
                null,
                "inline-bark-new-user-email",
                inputClasses,
                {
                    placeholder: _t('buyer_create-bark:buyer-info.email.placeholder'),
                    name: "email",
                    type: "email",
                    "tab-index": ++tabIndex,
                }
            );

            inner += Bark.getHtml(
                "div",
                email,
                null,
                "new-project-field inline-bark-q-cont"
            );
        }

        tel = Bark.getHtml(
            "label",
            _t('buyer_create-bark:buyer-info.phone.label'),
            null,
            "inline-bark-new-user-label",
            {
                for: "inline-bark-new-user-telephone",
            }
        );

        tel += Bark.getHtml(
            "input",
            null,
            "inline-bark-new-user-telephone",
            inputClasses + " telephone-field data-hj-whitelist",
            {
                placeholder: _t('buyer_create-bark:buyer-info.phone.placeholder'),
                name: "telephone",
                value: cb.buyerTelephone || "",
                type: "tel",
                "tab-index": ++tabIndex,
            }
        );

        var modalText = cb.categoryData.categories[cb.lastCategory];
        if (typeof modalText == "undefined" || modalText == null)
            modalText = {};

        if (typeof modalText.final_phone_text !== "undefined") {
            if (modalText.final_phone_text.show && !hidetextonaccountpage) {
                telDesc = _t('buyer_create-bark:buyer-info.phone.more-info');
                tel += Bark.getHtml("div", telDesc, null, "detail-desc");
            }
        } else if (!hidetextonaccountpage) {
            var telClass = "phone-desc spacing";
            telDesc = _t('buyer_create-bark:buyer-info.phone.more-info');
            tel += Bark.getHtml("div", telDesc, null, telClass);
        }

        if (typeof modalText.final_button_text !== "undefined") {
            $(".inline-bark-buttons-container .inline-bark-btn-submit").text(
                modalText.final_button_text.value
            );
        }

        inner += Bark.getHtml(
            "div",
            tel,
            null,
            "new-project-field inline-bark-q-cont"
        );

        if (cb.showLocalCheck) {
            inner += renderLocalOnly();
        }

        output = Bark.getHtml("div", inner);

        return Bark.getHtml(
            "div",
            output,
            "inline-bark-new-user",
            "inline-bark-q"
        );
    };

    function renderLocalOnly() {
        var showLocalLabel = "";
        var localInputClasses =
            "local-exp-v5 text-left text-md-md text-xs pl-0 pl-md-1";
        var localOnlyLabel = _t('buyer_create-bark:online-or-remote-label');
        var localInputAttrs = {
            checked: true,
            type: "checkbox",
            name: "is_local",
            "tab-index": ++tabIndex,
        };

        // try {
        //     var modalText = cb.categoryData.categories[cb.lastCategory].modal_text;
        //     if (typeof modalText == 'undefined' || modalText == null) modalText = {};
        //     showLocalLabel = modalText.additional_details_someone_local.value;
        // } catch (e) {
        //
        // }

        // The category can be national. Create the element that allows a user to choose
        var showLocal = Bark.getHtml("h5", showLocalLabel);
        var lcb = Bark.getHtml(
            "input",
            null,
            "is_local",
            localInputClasses + " custom-control-input",
            localInputAttrs
        );
        lcb += Bark.getHtml(
            "label",
            localOnlyLabel,
            null,
            localInputClasses + " custom-control-label",
            { for: "is_local" }
        );

        return Bark.getHtml(
            "div",
            showLocal + lcb,
            "",
            "someone-local custom-control custom-checkbox pb-2"
        );
    }

    /**
     * Render the account details name
     * @returns {html}
     */
    cb.renderAccountName = function () {
        var output = "";
        var inner = "";
        var name = "";
        var localCheckboxClass = "";
        const nameLabel = !cb.userLoggedIn
            ? _t('buyer_create-bark:separated-buyer-info.name.label-matches-form')
            : _t('buyer_create-bark:separated-buyer-info.name.label');

        if (cb.showLocalCheck) {
            localCheckboxClass = " exp-mb-local";
        }

        showTermsAndConditions();

        name = Bark.getHtml(
            "label",
            nameLabel,
            null,
            "inline-bark-new-user-label",
            {
                for: "inline-bark-new-user-name",
            }
        );

        name += Bark.getHtml(
            "input",
            null,
            "inline-bark-new-user-name",
            "inline-bark-q-new-user",
            {
                placeholder: _t('buyer_create-bark:separated-buyer-info.name.placeholder'),
                name: "name",
                type: "text",
                "tab-index": ++tabIndex,
            }
        );
        inner += Bark.getHtml(
            "div",
            name,
            null,
            "new-project-field account-information-row inline-bark-q-cont" +
                localCheckboxClass
        );
        output = Bark.getHtml("div", inner);

        if (cb.showLocalCheck) {
            output += renderLocalOnly();
        }

        if (!cb.categoryData.user.userLoggedIn) {
            output += Bark.getHtml('div', cb.buildOptInHtml(), null, 'optout custom-control custom-checkbox');
        }

        return Bark.getHtml('div', output, 'inline-bark-acc-user', 'inline-bark-q inline-bark-q-text');
    };

    cb.buildOptInHtml = function () {
        var checkboxHtml = "";
        if (
            cb.categoryData &&
            cb.categoryData.user &&
            !cb.categoryData.user.userLoggedIn
        ) {
            checkboxHtml = Bark.getHtml(
                "input",
                null,
                "inline-bark-teldesc",
                "custom-control-input",
                {
                    type: "checkbox",
                    checked: "checked",
                }
            );
            checkboxHtml += Bark.getHtml(
                "label",
                _t('buyer_create-bark:consent-to-marketing'),
                null,
                "custom-control-label",
                {
                    for: "inline-bark-teldesc",
                }
            );
        }

        return checkboxHtml;
    };

    /**
     * Render the account details email address
     * @returns {html}
     */
    cb.renderAccountEmail = function () {
        if (toast) toast.reset();

        var output = "";
        var inner = "";
        var emailDesc = "";
        var email;
        var emailDescInner;

        email = Bark.getHtml(
            "input",
            null,
            "inline-bark-new-user-email",
            "inline-bark-q-new-user",
            {
                placeholder: _t('buyer_create-bark:separated-buyer-info.email.placeholder'),
                name: "email",
                type: "email",
                "tab-index": ++tabIndex,
            }
        );

        emailDesc = hidetextonaccountpage ? "" : _t('buyer_create-bark:separated-buyer-info.email.more-info');

        email += hidetextonaccountpage
            ? ""
            : Bark.getHtml("div", emailDesc, null, "phone-desc spacing");

        inner += Bark.getHtml(
            "div",
            email,
            null,
            "new-project-field account-information-row inline-bark-q-cont"
        );

        output = Bark.getHtml("div", inner);

        return Bark.getHtml(
            "div",
            output,
            "inline-bark-new-user",
            "inline-bark-q inline-bark-q-text"
        );
    };

    /**
     * Render the account details telephone number
     * @returns {html}
     */
    cb.renderAccountTel = function () {
        var output = "";
        var inner = "";
        var telDesc = "";
        var tel;

        tel = Bark.getHtml(
            "input",
            null,
            "inline-bark-new-user-telephone",
            "inline-bark-q-new-user telephone-field data-hj-whitelist",
            {
                placeholder: _t('buyer_create-bark:separated-buyer-info.phone.placeholder'),
                name: "telephone",
                value: cb.buyerTelephone || "",
                type: "tel",
                "tab-index": ++tabIndex,
            }
        );

        var modalText = cb.categoryData.categories[cb.lastCategory];
        if (!Bark.is_a(modalText, "object")) {
            modalText = {};
        }

        let phonePrompt = _t('buyer_create-bark:separated-buyer-info.phone.more-info');

        if (!PhoneComplianceMessageIsEnabled(cb.categoryData.features)) {
            if (typeof modalText.final_phone_text !== "undefined") {
                if (modalText.final_phone_text.show) {
                    telDesc = _t('buyer_create-bark:separated-buyer-info.phone.legal-compliance-info');
                    tel += Bark.getHtml("div", telDesc, null, "detail-desc");
                }
            } else {
                var telClass = "phone-desc spacing";
                telDesc = phonePrompt;
                tel += Bark.getHtml("div", telDesc, null, telClass);
            }
        }

        if (typeof modalText.final_button_text !== "undefined") {
            $(".inline-bark-buttons-container .inline-bark-btn-submit").text(
                modalText.final_button_text.value
            );
        }

        inner += Bark.getHtml(
            "div",
            tel,
            null,
            "new-project-field account-information-row inline-bark-q-cont"
        );

        output = Bark.getHtml("div", inner);

        return Bark.getHtml(
            "div",
            output,
            "inline-bark-new-user",
            "inline-bark-q inline-bark-q-text"
        );
    };

    cb.renderSubmitExistingBuyer = function () {
        showTermsAndConditions();
        var design = window.Handlebars.compile(
            document.getElementById('logged-out-buyer-question').innerHTML
        );

        var $continueButton = $(
            ".inline-bark-buttons-container .inline-bark-btn-continue"
        );
        $continueButton.text(_t('buyer_create-bark:buttons.submit-request-button'));
        $continueButton.addClass("js-existing-buyer-submit");

        var user = cb.loggedOutUser;
        var userId = user ? user.id : 0;

        $.post("/api/elo/", { c: "rebark_submit", n: "viewed", i: userId });

        let welcomeMessage;

        if (user.name) {
            welcomeMessage = _t('buyer_create-bark:returning-buyer.welcome-back.title.with-name', {
                buyer_public_name: user.name
            })
        } else {
            welcomeMessage = _t('buyer_create-bark:returning-buyer.welcome-back.title.default');
        }

        let htmlOutput = design({
            welcomeMessage,
            avatarUrl: user ? user.avatar : undefined,
        });

        if (cb.showLocalCheck) {
            htmlOutput += Bark.getHtml('div', renderLocalOnly(), '', 'mt-4 mx-3 text-grey-400');
        }

        return Bark.getHtml('div', htmlOutput);
    }

    cb.renderLoginExistingBuyer = function () {
        var design = window.Handlebars.compile(
            document.getElementById("bark-posted-sent-email").innerHTML
        );
        var $continueButton = $(
            ".inline-bark-buttons-container .inline-bark-btn-continue"
        );
        $continueButton.text(_t('buyer_create-bark:buttons.login-button'));

        var userId = cb.loggedOutUser ? cb.loggedOutUser.id : 0;

        $.post("/api/elo/", { c: "rebark_login", n: "viewed", i: userId });

        return design({});
    };

    /**
     * Render an autocomplete Postcode field
     * @param {object} question The current question being rendered
     */
    cb.renderConfirmLocation = function (question) {
        var output = "";
        var yesRadioAttributes = {
            type: "radio",
            "tab-index": ++tabIndex,
            name: "confirm-location",
            value: "yes",
        };

        var radioButtons =
            Bark.getHtml(
                "div",
                Bark.getHtml(
                    "input",
                    "",
                    "confirm-location-yes",
                    "inline-bark-radio confirm-location-yes",
                    yesRadioAttributes
                ) +
                    Bark.getHtml("label", _t('buyer_create-bark:yes-or-no.yes'), null, "inline-bark-q-label", {
                        for: "confirm-location-yes",
                    }),
                null,
                "inline-bark-q-radio can-highlight inline-bark-q  new-project-field confirm-location-yes-container "
            ) +
            Bark.getHtml(
                "div",
                Bark.getHtml(
                    "input",
                    "",
                    "confirm-location-no",
                    "inline-bark-radio confirm-location-no",
                    {
                        type: "radio",
                        "tab-index": ++tabIndex,
                        name: "confirm-location",
                        value: "no",
                    }
                ) +
                    Bark.getHtml("label", _t('buyer_create-bark:yes-or-no.no'), null, "inline-bark-q-label", {
                        for: "confirm-location-no",
                    }),
                null,
                "inline-bark-q-radio can-highlight inline-bark-q  new-project-field confirm-location-no-container "
            );

        return output;
    };

    /**
     * Render an autocomplete Postcode field
     * @param {object} question The current question being rendered
     */
    cb.renderPostcode = function (question) {
        var output = "";
        var loc = (window.Bark.ENV.locale || "").toLowerCase();

        // Control content
        var locationPlaceholder = _t('common:location.postcode-or-town.enter-string');

        var locationValue = "";

        var input = Bark.getHtml(
            "input",
            null,
            "postcode-auto",
            "postcode-auto",
            {
                name: question.name,
                autocomplete: "off",
                placeholder: locationPlaceholder,
                "tab-index": ++tabIndex,
                value: locationValue,
            }
        );

        output = Bark.getHtml(
            "div",
            input,
            null,
            "inline-bark-q-text inline-bark-q new-project-field"
        );

        if (typeof question.info !== "undefined" && question.info.length > 6) {
            output += cb.getQinfoContent(question.info);
        }

        return output;
    };

    cb.renderPhotocheck = function (question, collapse, preSelectIds) {
        var output = "";
        var option;
        var body;
        var input;
        var i;
        var collapse = consts.MAX_VIS_CATEGORIES;

        for (i = 0; i < question.options.length; i++) {
            option = question.options[i];

            if (typeof option.photo === "undefined" || option.photo.length < 4)
                continue;

            var inputId =
                "custom_question_checkbox_" + cb.pos + "_" + option.id;

            let attrs = {
                type: "checkbox",
                value: option.label,
                name: question.name,
                "tab-index": ++tabIndex,
            };

            if (preSelectIds.includes(option.id)) {
                attrs.checked = "check";
            }

            input = Bark.getHtml(
                "input",
                null,
                inputId,
                "photoselect-option",
                attrs
            );

            var checkmark = Bark.getHtml("i", null, null, "fa fa-check");

            body = Bark.getHtml("div", checkmark, null, "picoverlay");

            body += Bark.getHtml("div", null, null, null, {
                style: Bark.sprintf("background-image:url(%s)", option.photo),
            });

            body += Bark.getHtml("p", option.label);

            output += Bark.getHtml(
                "div",
                input +
                    Bark.getHtml("label", body, null, null, { inpid: inputId }),
                null,
                "col-6 px-2 px-md-1 mb-2 opts",
                {
                    style: i < consts.MAX_VIS_CATEGORIES ? "" : "display:none;",
                }
            );
        }

        output = Bark.getHtml(
            "div",
            output,
            null,
            "row photoselect photoselect-check pickbyimage"
        );

        if (question.options.length > collapse) {
            output += Bark.getHtml(
                "div",
                Bark.getHtml("span", _t('buyer_create-bark:links.show-more-link'), null, null, {
                    "data-collapse": collapse,
                }),
                null,
                null,
                { class: "showmorebtn phc-showmore" }
            );
        }

        if (typeof question.info !== "undefined" && question.info.length > 6) {
            output += cb.getQinfoContent(question.info);
        }
        return output;
    };

    cb.renderPhotoselect = function (question, collapse, preSelectIds) {
        var output = "";
        var option;
        var body;
        var input;
        var i;
        var collapse = consts.MAX_VIS_CATEGORIES;
        var attrs = {};
        var selectedClass = "";

        for (i = 0; i < question.options.length; i++) {
            selectedClass = "";
            option = question.options[i];
            let divClass = "col-6 px-2 px-md-1 mb-2 opts";
            let elementClass = "";

            if (typeof option.photo === "undefined" || option.photo.length < 4)
                continue;

            attrs = {
                type: "radio",
                name: question.name,
                id: question.name + "-option-" + option.id,
                value: option.id,
                "tab-index": ++tabIndex,
            };

            if (
                Bark.objectSize(presets) &&
                Bark.in_array(option.id, presets[question.name] || [])
            ) {
                attrs.checked = "check";
                selectedClass = "selectedpic";
            }

            if (preSelectIds.includes(option.id)) {
                attrs.checked = "check";
                selectedClass = "selectedpic";
            }

            input = Bark.getHtml(
                "input",
                null,
                null,
                "photoselect-option",
                attrs
            );

            if (cb.AutoContinueExperiment) {
                divClass += " auto-continue-wrapper";
                elementClass += "auto-continue-cb";
            }

            var checkmark = Bark.getHtml("i", null, null, "fa fa-check");

            body = Bark.getHtml("div", checkmark, null, "picoverlay");

            body += Bark.getHtml("div", null, null, elementClass, {
                style: Bark.sprintf("background-image:url(%s)", option.photo),
            });

            body += Bark.getHtml("p", option.label, null, elementClass);

            output += Bark.getHtml(
                "div",
                input +
                    Bark.getHtml("label", body, null, null, {
                        inpid: question.name + "-option-" + option.id,
                    }),
                null,
                divClass + " " + selectedClass,
                {
                    style: i < consts.MAX_VIS_CATEGORIES ? "" : "display:none;",
                }
            );
        }

        output = Bark.getHtml(
            "div",
            output,
            null,
            "row photoselect photoselect-radio pickbyimage"
        );

        if (question.options.length > collapse) {
            output += Bark.getHtml(
                "div",
                Bark.getHtml("span", _t('buyer_create-bark:links.show-more-link'), null, null, {
                    "data-collapse": collapse,
                }),
                null,
                null,
                { class: "showmorebtn phc-showmore" }
            );
        }

        if (typeof question.info !== "undefined" && question.info.length > 6) {
            output += cb.getQinfoContent(question.info);
        }

        return output;
    };

    /**
     * Render the question that allows a user to select a subcategory
     * @param {object} question Object describing the current question
     * @returns {html}
     */
    cb.renderCategorySelection = function (question) {
        var output = "";
        var btn = "";
        var i;
        var category;
        var body;
        var input;

        btn += Bark.getHtml("button", cb.continueLabel, null, "next-category-chosen");

        if (question.thumbnailsAll) {
            for (i = 0; i < question.options.length; i++) {
                category = question.options[i];
                input = Bark.getHtml(
                    "input",
                    null,
                    null,
                    "choose-category-option",
                    {
                        type: "radio",
                        name: "choose-category-option",
                        id: "category-option-" + category.id,
                        value: category.id,
                        "tab-index": ++tabIndex,
                    }
                );
                body = Bark.getHtml("div", null, null, null, {
                    style: Bark.sprintf(
                        "background-image:url(%s)",
                        category.thumbnail[pixelRatio === 1 ? "1x" : "2x"]
                    ),
                });
                body += Bark.getHtml("p", category.label);
                output += Bark.getHtml(
                    "div",
                    input +
                        Bark.getHtml("label", body, null, null, {
                            for: "category-option-" + category.id,
                        }),
                    null,
                    "opts",
                    {
                        style:
                            i < consts.MAX_VIS_CATEGORIES
                                ? ""
                                : "display:none;",
                    }
                );
            }
            if (question.options.length > consts.MAX_VIS_CATEGORIES) {
                // There are some hidden categories, add a view more button
                output += Bark.getHtml(
                    "div",
                    Bark.getHtml("span", _t('buyer_create-bark:links.show-more-link')),
                    null,
                    "choose-category-showmore"
                );
            }
        } else {
            //get pre selected
            const q = cb.questions[cb.pos];
            const {
                QuestionPrefills,
                location: { search: queryString },
            } = window;
            const { preSelectIds } = QuestionPrefills.checkForQuestionPrefill(
                queryString,
                q,
                cb.getPageSkippedQuestions()
            );

            //render select
            output = cb.renderSelect(
                question,
                consts.MAX_VIS_CATEGORIES,
                preSelectIds
            );
        }
        output += Bark.getHtml("div", btn, null, 'btns float-right"');
        return Bark.getHtml(
            "div",
            output,
            null,
            "choose-category" + (question.thumbnailsAll ? " pickbyimage" : "")
        );
    };

    /**
     * Render the introduction to the Bark modal journey
     * @returns {html}
     */
    cb.renderIntro = function () {
        var modalText = cb.categoryData.categories[cb.lastCategory].modal_text;
        if (typeof modalText == "undefined" || modalText == null)
            modalText = {};
        var title = _t('buyer_create-bark:intro.title');

        if (typeof modalText.intro_heading !== "undefined") {
            title = modalText.intro_heading.value;
        }

        var output;
        output = Bark.getHtml("span", null, null, "bark-icon-logo");
        output += Bark.getHtml("h2", title);

        output += Bark.getHtml(
            "div",
            Bark.getHtml(
                "button",
                Bark.getHtml("i", null, null, "fa fa-chevron-left") + _t('buyer_create-bark:buttons.back-button'),
                null,
                "inline-bark-btn-back"
            ),
            null,
            "intro-btn"
        );
        output += Bark.getHtml(
            "div",
            Bark.getHtml(
                "button",
                _t('buyer_create-bark:buttons.next-button') + Bark.getHtml("i", null, null, "fa fa-chevron-right"),
                null,
                "inline-bark-btn-continue"
            ),
            null,
            "intro-btn"
        );

        return Bark.getHtml("div", output, null, "inline-bark-modal-variant2");
    };

    cb.renderModalExperiment = function (question) {
        if (!cb.categPhotoAvail) {
            $(".modal-category-experiment-photo").html("");
            $("#inlineBarkModal .inline-bark-modal-hide-h1").css({
                "padding-top": "1em",
            });
        }

        if (cb.categPhotoAvail && cb.modalCategPhoto != "") {
            if (
                (!cb.introAvail && question == 0) ||
                (cb.introAvail && question == 1)
            ) {
                $(".modal-category-experiment-photo").slideDown(600);
                $("#inlineBarkModal .inline-bark-modal-hide-h1").css({
                    "padding-top": "0.3em",
                });
                cb.questions[question].hideProgress = true;
            } else {
                $(".modal-category-experiment-photo").slideUp(600);
                $("#inlineBarkModal .inline-bark-modal-hide-h1").css({
                    "padding-top": "1em",
                });
            }
        }
    };

    function logGoogleRemarketing() {
        try {
            var locationId = null;
            var intendedLocation = +(
                window.Bark.GET("trk_alid_int") || ""
            ).trim();
            var usersPhysicalLocation = +(
                window.Bark.GET("trk_alid_phy") || ""
            ).trim();
            var itemId;
            var itemIdWithoutLocation;

            if (!window.googleRemarketingAccountId || cb.userLoggedIn) {
                return;
            }

            if (usersPhysicalLocation > 0) {
                locationId = usersPhysicalLocation;
            }

            if (intendedLocation > 0) {
                locationId = intendedLocation;
            }

            itemIdWithoutLocation =
                window.Bark.ENV.ccid + "-" + cb.lastCategory;
            itemId = itemId + (locationId ? "-g-" + locationId : "");

            gtag("event", "page_view", {
                send_to: window.googleRemarketingAccountId,
                items: [
                    {
                        id: itemId,
                        location_id: locationId,
                        google_business_vertical: "custom",
                        dynx_pagetype: "others", // home , searchresults, offerdetail, conversionintent, conversion , others
                        dynx_itemid: itemId,
                        dynx_totalvalue: 0,
                    },
                ],
            });

            if (itemIdWithoutLocation !== itemId) {
                // There are two entries, one with a location and one without
                gtag("event", "page_view", {
                    send_to: window.googleRemarketingAccountId,
                    items: [
                        {
                            id: itemIdWithoutLocation,
                            location_id: null,
                            google_business_vertical: "custom",
                            dynx_pagetype: "others", // home , searchresults, offerdetail, conversionintent, conversion , others
                            dynx_itemid: itemId,
                            dynx_totalvalue: 0,
                        },
                    ],
                });
            }

            // Also send something without a location
        } catch (ex) {
            window.bugsnagClient && window.bugsnagClient.notify(ex);
        }
    }

    /**
     * Track events with GTM
     * @param {string} eventName The name of the event
     * @param {object} data [optional] Any extra data to pass through (most is passed through already)
     */
    function tagManager(eventName, data = {}) {
        if (!window.dataLayer) {
            return;
        }
        try {
            var intendedLocation = +(
                window.Bark.GET("trk_alid_int") || ""
            ).trim();
            var usersPhysicalLocation = +(
                window.Bark.GET("trk_alid_phy") || ""
            ).trim();
            var locationId;

            if (usersPhysicalLocation > 0) {
                locationId = usersPhysicalLocation;
            }

            if (intendedLocation > 0) {
                locationId = intendedLocation;
            }

            window.dataLayer.push(
                $.extend(
                    {
                        event: eventName,
                        country_id: window.Bark.ENV.ccid || null, // This isn't being picked up
                        bark_country_id: window.Bark.ENV.ccid || null,
                        marketing_location_id: locationId
                            ? "g-" + locationId
                            : null,
                        country_category:
                            (window.Bark.ENV.ccid || 0) +
                            "-" +
                            (cb.lastCategory || 0),
                        country_category_location:
                            (window.Bark.ENV.ccid || 0) +
                            "-" +
                            (cb.lastCategory || 0) +
                            (locationId ? "-g-" + locationId : ""),
                        lang: window.Bark.ENV.lang || null,
                        locale: window.Bark.ENV.locale || null,
                        campaign: window.Bark.ENV.campaign || null,
                        category_id: cb.lastCategory || null,
                        category_url: cb.categoryUrl || null,
                        n_questions: cb.questionsLength || null,
                        logged_in: !!cb.userLoggedIn,
                        is_seller: !!cb.sellerLoggedIn,
                        current_position: cb.pos || null,
                        question_name:
                            ((cb.questions || {})[cb.pos] || {}).name || null,
                        question_type:
                            ((cb.questions || {})[cb.pos] || {}).type || null,
                        question_name_type:
                            ((cb.questions || {})[cb.pos] || {}).name ||
                            ((cb.questions || {})[cb.pos] || {}).type ||
                            null,
                        pre_bark_id: cb.preBarkId || null,
                    },
                    data
                )
            );
        } catch (ex) {
            window.bugsnagClient && window.bugsnagClient.notify(ex);
        }
    }

    cb.tagManager = tagManager;

    /**
     * Render the current question
     */
    cb.renderCurrent = function () {
        var q = cb.questions[cb.pos];
        if (!q) {
          window.bugsnagClient.notify(new Error('no questions for this position'), {
            metaData: { questions: cb.questions, position: cb.pos },
          });
        }
        var questionTypeId = q.type;
        var func = "render" + Bark.ucfirst(questionTypeId);
        var elemId = "bark-question-" + cb.pos;
        var elem = $("#" + elemId);
        var gaPath = q.gaPath || "q" + (cb.pos + 1);
        var categoryId = $("#category_id").val();
        var locale = (window.Bark.ENV.locale || "").toLowerCase();


        hideTermsAndConditions();
        $("#inlineBarkModalContent").attr(
            "data-modal-type",
            questionTypeId.toLowerCase()
        );
        const pageSkipQuestion = getPageSkipQuestionsForState();
        const pageSkipQuestionOther =
            (pageSkipQuestion && pageSkipQuestion[q.name + "_other"]?.value) ||
            "";
        const {
            QuestionPrefills,
            location: { search: queryString },
        } = window;
        const {
            preSelectIds,
            skipQuestion,
            textPrefillAnswerConfirm,
            textPrefillAnswerSkip,
        } = QuestionPrefills.checkForQuestionPrefill(
            queryString,
            q,
            pageSkipQuestion
        );
        let browseSkipQuestion = false;
        if (cb.browseModalSkipQuestions) {
            browseSkipQuestion = pageSkipQuestion[q.name];
        }


        if (skipQuestion || browseSkipQuestion) {
            if (browseSkipQuestion) {
                payload[q.name] = browseSkipQuestion;
                if (pageSkipQuestion[`${q.name}_other`]) {
                    payload[`${q.name}_other`] =
                        pageSkipQuestion[`${q.name}_other`];
                }
            } else {
                let prefillAnswers = QuestionPrefills.createPayloadFromIds(
                    q.name,
                    preSelectIds,
                    textPrefillAnswerSkip,
                    q.type,
                    pageSkipQuestionOther
                );
                payload = { ...payload, ...prefillAnswers };
            }

            cb.skippedQuestionPositions.push(cb.pos);

            if (q.type === "photocheck") recordQuestionSuccessInHeap(q, true);

            // If we skip the last question the top bar will get stuck because render current never gets called for the last time
            if (q.index === cb.questionsLength) {
                $(".inline-bark-percentage-thumb").width("100%");
            }

            return cb.next(false, true);
        }

        cb.renderModalExperiment(cb.pos);

        if (cb.logRemarketing) {
            if (!cb.loggedRemarketing) {
                // Only log once
                cb.loggedRemarketing = true;
                logGoogleRemarketing();
            }
        } else {
            // Don't log on the first screen
            cb.logRemarketing = true;
        }

        // @todo do properly
        if (q.type === "buyerInfo") {
            elem.remove();
            elem = $("#" + elemId);
        }
        try {
            if (categoryId in cb.categoryData.categories) {
                var submitOnStep = cb.userQuestionsCount();

                // the next step is usually the loading screen which only gets shown once which means it skips back a step
                // which causes the event to fire twice, so we only show on the next step
                if (
                    cb.questions[submitOnStep].showOnce &&
                    cb.questions[submitOnStep].shown
                ) {
                    // only increment if the current step has already been shown before. Stops the event firing twice when
                    // it skips to the next step
                    if (!(cb.pos === submitOnStep + 1 && !q.shown)) {
                        submitOnStep += 1;
                    }
                }

                if (cb.pos === submitOnStep) {
                    Bark.json({
                        url: "/api/tsug/",
                        data: {
                            payload: JSON.stringify(payload),
                            cid: $("#category_id").val(),
                            pid: $("#postcode_id").val(),
                            ptype: $("#postcode_type").val(),
                            preBarkId: cb.preBarkId,
                            preProcessingFired: cb.preProcessingFired,
                        },
                    });
                }
            }
        } catch (e) {}

        $(".inline-bark-buttons-container .inline-bark-btn-continue").text(
            cb.continueLabel
        );

        if (q.type === "upsell") {
            try {
                var modalText =
                    cb.categoryData.categories[cb.lastCategory].modal_text;
                if (typeof modalText == "undefined" || modalText == null)
                    modalText = {};
                if (typeof modalText.final_button_continue !== "undefined")
                    $(
                        ".inline-bark-buttons-container .inline-bark-btn-continue"
                    ).text(cb.continueLabel);
            } catch (e) {}
        }

        if (
            (q.showOnce && q.shown) ||
            (q.showOnce && skipUpsellLoadingNewRequestFlow())
        ) {
            // If an item should be shown once and it has already been shown, skip it
            cb[lastAction]();
            return;
        }
        // Use the GA url passed
        ga(
            "send",
            "pageview",
            Bark.sprintf(
                "%s/%s/buyer-modal/%s/%s/%s/",
                Bark?.ENV?.lang?.toLowerCase() || 'en',
                locale,
                categoryId,
                cb.categoryUrl,
                gaPath
            )
        );
        tagManager(
            "virtual_page_view",
            {
                "page": Bark.sprintf(
                    "%s/%s/buyer-modal/%s/%s/%s/",
                    Bark?.ENV?.lang?.toLowerCase() || 'en',
                    locale,
                    categoryId,
                    cb.categoryUrl,
                    gaPath
                )
            });
        tagManager("bark-modal:enter-page");

        let experimentParams = getExperimentTrackingData(cb.categoryData);
        let preSelectString =
            preSelectIds.length > 0 ? preSelectIds.join() : null;

        let params = {
            question_number_total: cb.questionsLength,
            step_number: cb.pos + 1,
            prefill_answer_ids: preSelectString,
            ...experimentParams,
        };

        createBarkTracking.logModalPageEvent(
            categoryId,
            cb.preBarkId,
            questionTypeId,
            "view",
            q,
            params
        );

        if (!isFrontendV2WithBootstrap()) {
            $("body,html").animate({ scrollTop: 0 }, 800);
        }

        q.shown = true;

        if (cb[func]) {
            $(".inline-bark-main-content .bark-modal-question").hide();
            if (!elem.length) {
                $(".inline-bark-main-content").append(
                    Bark.getHtml(
                        "div",
                        cb[func](
                            q,
                            undefined,
                            isNewRequestFlow()
                                ? getAndRemovePreSelectAnswer(q.name)
                                : preSelectIds,
                            textPrefillAnswerConfirm
                        ),
                        elemId,
                        "bark-modal-question"
                    )
                );
                elem = $("#" + elemId);
            }

            try {
                var shldFocus = !elem
                    .find(":input:first")
                    .hasClass("js-no-autofocus");

                if (q.type === "additional") {
                    shldFocus = false;
                } else if (cb.userLoggedIn && q.type == "buyerInfo") {
                    shldFocus = false;
                }

                if (shldFocus) elem.find(":input:first").focus();
            } catch (e) {
                elem.find(":input:first").focus();
            }

            elem.show();
        } else {
            throw new Bark.ex(Bark.sprintf("Unknown render function %s", func));
        }

        $("#inlineBarkModal .inline-bark-modal-hide-h1").html(
            (q.label ? q.label : "") +
                (q.labelExtra ? Bark.getHtml("span", q.labelExtra) : "")
        );

        if (cb.CsatExperiment) {
            cb.CsatExperiment.hide();
            let step = q.type;
            let offset = 1;
            let qkey = submitOnStep - 1;
            if (
                qkey in cb.questions &&
                cb.questions[qkey].type === "postcode"
            ) {
                offset = 2;
            }
            if (cb.pos === submitOnStep - offset) {
                step = "lastCategoryQuestion";
            }
            cb.CsatExperiment.displayCsatMeasurement(step);
        }

        if (PhoneComplianceMessageIsEnabled(cb.categoryData?.features)) {
            DisplayPhoneComplianceMessage(q.type, cb.categoryData.features, $('#inlineBarkModal'));
        }

        if (q.index) {
            $(".inline-bark-percentage-thumb").width(
                (q.index / cb.questionsLength) * 100 + "%"
            );
        }
        $("#inlineBarkModal .inline-bark-header")[
            q.hideTop ? "hide" : "show"
        ]();
        $(".inline-bark-percentage-track-new")[q.hideProgress ? "hide" : "show"]();
        $("#inlineBarkModal .inline-bark-modal-hide-hr")[
            q.hideRuler ? "hide" : "show"
        ]();
        $("#inlineBarkModal .inline-bark-border")[
            q.hideBorder ? "hide" : "show"
        ]();
        if (q.hideNavigation) {
            $(".inline-bark-buttons-container")
                .not(".inline-bark-buttons-container.close-modal")
                .hide();
        } else {
            // Determine which buttons should show
            $(".inline-bark-buttons-container").show();

            //hide back button
            const { hasPrevious } = getNextValidPrevious(cb.pos, cb.skippedQuestionPositions);
            const showBackButton = (cb.pos && hasPrevious);
            $('.inline-bark-btn-back')[showBackButton ? 'removeClass' : 'addClass']('hide');

            // New Buyer Experiment: For variant 4, hide the continue button on the confirm-location page
            if (cb.shouldShowSubmitButton(q, cb.pos, cb.questions.length)) {
                // This is the final question: show 'Post Bark' button instead of 'continue'
                $(".inline-bark-btn-continue").addClass("hide");
                $(".inline-bark-btn-submit").removeClass("hide");
            } else {
                // Show continue instead
                $(".inline-bark-btn-continue").removeClass("hide");
                $(".inline-bark-btn-submit").addClass("hide");
            }
        }

        if (q.type === "intro" && q.hideBack) {
            $("div.intro-btn .inline-bark-btn-back").hide();
        } else if (q.type === "loginExistingBuyer") {
            $(".inline-bark-btn-back").addClass("hide");
        } else if (q.type === "intro") {
            $("div.intro-btn .inline-bark-btn-back").show();
        }

        if (
            q.type === "accountEmail" &&
            cb?.TenderBrowseExperiment?.getIsActive()
        ) {
            // Override the 'back' button on the email capture screen to go back to the tenderbrowse choice
            $(".inline-bark-buttons-container .inline-bark-btn-back").addClass(
                "js-tenderbrowse-back-from-email-capture"
            );
            $(".inline-bark-buttons-container .js-tenderbrowse-back").addClass(
                "js-tenderbrowse-back-from-email-capture"
            );
        } else {
            //remove the override
            $(
                ".inline-bark-buttons-container .inline-bark-btn-back"
            ).removeClass("js-tenderbrowse-back-from-email-capture");
            $(
                ".inline-bark-buttons-container .js-tenderbrowse-back"
            ).removeClass("js-tenderbrowse-back-from-email-capture");
        }
        // same here?
        if (
            (q.type === "accountTel" || q.type === "accountName") &&
            cb?.TenderBrowseExperiment?.getIsActive()
        ) {
            $(".inline-bark-buttons-container .inline-bark-btn-back")
                .addClass("js-tenderbrowse-back")
                .removeClass("inline-bark-btn-back");
        } else {
            $(".inline-bark-buttons-container .js-tenderbrowse-back")
                .addClass("inline-bark-btn-back")
                .removeClass("js-tenderbrowse-back");
        }

        if (
            q.type === "tenderBrowse" &&
            cb?.TenderBrowseExperiment?.getIsActive() &&
            cb?.TenderBrowseExperiment?.getShouldShowBottomContainer()
        ) {
            // Re-show the tenderbrowse bottom section if it's been hidden
            $("#js-tenderbrowse-bottomsection-container").show();
        } else {
            $("#js-tenderbrowse-bottomsection-container").hide();
        }

        if (q.type === 'publicProfileQuote') {
            handleFromPublicProfile(payload);
        }

        cb.bindEvents();
        cb.AutoContinueExperiment?.bindEvents(elem);
        cb.dispatchEvent("onEnterQuestion");
    };

    cb.shouldShowSubmitButton = function (
        currentQuestion,
        currentPosition,
        totalLengthOfQuestions
    ) {
        if (currentQuestion.type === "accountEmail") {
            return false;
        }

        if (cb.isLoggedOutExistingBuyer) {
            return false;
        }

        if (currentPosition + 1 === totalLengthOfQuestions) {
            return true;
        }

        return false;
    };

    cb.validatePhotocheck = function () {
        var q = cb.questions[cb.pos];
        var answered = false;
        var scope = $("#bark-question-" + cb.pos);

        $(".photoselect-option", scope).each(function () {
            if (this.checked) {
                answered = true;
            }
        });

        if (q.required && !answered) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.select-one-option'))
                .removeClass("hide");
            scope.addClass("new-error");

            if (
                !isFullyInViewport(
                    document.querySelector(".inline-bark-modal-errors")
                )
            ) {
                $("#inlineBarkModal").animate({ scrollTop: 0 }, 800);
            }

            return false;
        }

        return true;
    };

    /**
     * Validate a question comprised of checkboxes
     * @returns {boolean} <b>True</b> on success
     */
    cb.validateCheckbox = function () {
        var q = cb.questions[cb.pos];
        var answered = false;
        var otherError = false;
        var other;
        var scope = $("#bark-question-" + cb.pos);

        $(".inline-bark-checkbox", scope).each(function () {
            if (this.checked) {
                answered = true;
                if (this.id.match(/_other$/)) {
                    // This option is 'other'. Determine whether the adjacent input is empty and store the input so that an error style
                    //  can be added in the event of an error
                    other = $(Bark.sprintf("#%s_value", this.id), scope);
                    otherError = !other.val().trim().length;
                }
            }
        });
        if (q.required && !answered) {
            // This is a required question that has not been answered
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.select-an-option'))
                .removeClass("hide");
            scope.addClass("new-error");

            if (
                !isFullyInViewport(
                    document.querySelector(".inline-bark-modal-errors")
                )
            ) {
                $("#inlineBarkModal").animate({ scrollTop: 0 }, 800);
            }
            return false;
        }
        if (otherError) {
            // The 'other' field is empty
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.set-other-value'))
                .removeClass("hide");
            other.closest(".inline-bark-q").addClass("new-error");

            if (
                !isFullyInViewport(
                    document.querySelector(".inline-bark-modal-errors")
                )
            ) {
                $("#inlineBarkModal").animate({ scrollTop: 0 }, 800);
            }
            return false;
        }
        return true;
    };

    cb.validatePhotoselect = function () {
        var q = cb.questions[cb.pos];
        var scope = $("#bark-question-" + cb.pos);
        var selVal = (
            $(".photoselect-option:checked", scope).val() || ""
        ).trim();

        if (q.required && !selVal.length) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.select-an-option'))
                .removeClass("hide");
            scope.addClass("new-error");

            if (
                !isFullyInViewport(
                    document.querySelector(".inline-bark-modal-errors")
                )
            ) {
                $("#inlineBarkModal").animate({ scrollTop: 0 }, 800);
            }

            return false;
        }

        return true;
    };

    /**
     * Validate a question with a select box
     * @returns {boolean} <b>True</b> on success
     */
    cb.validateSelect = function () {
        var q = cb.questions[cb.pos];
        var scope = $("#bark-question-" + cb.pos);
        var selVal = (
            $(".inline-bark-radio:checked", scope).val() || ""
        ).trim();
        var other = $(".inline-bark-q-radio-other", scope);
        var errorDiv;

        if (q.required && !selVal.length) {
            // This is a required question which has not been answered
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.select-an-option'))
                .removeClass("hide");
            scope.addClass("new-error");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        if (selVal === "other" && !other.val().trim().length) {
            // The option 'other' was selected, but the value for it was not input
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.set-other-value'))
                .removeClass("hide");
            other.addClass("new-error").addClass("is-invalid");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        return true;
    };

    cb.validateIntent = cb.validateSelect;

    /**
     * Validate a location confirmation question
     * @returns {boolean} <b>True</b> on success
     */
    cb.validateConfirmLocation = function () {
        var scope = $("#bark-question-" + cb.pos);
        var selVal = (
            $(".inline-bark-radio:checked", scope).val() || ""
        ).trim();
        if (!selVal.length) {
            // This is a required question which has not been answered
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.select-an-option'))
                .removeClass("hide");
            scope.addClass("new-error");

            var errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        return true;
    };

    /**
     * Validate a postcode within a text box
     *
     * @returns {boolean} <b>True</b> on success
     */
    cb.validatePostcode = function () {
        var err_msg = _t('common:location.postcode-or-town.error-message');

        var i = 0;
        var cur;
        var q = cb.questions[cb.pos];
        var scope = $("#bark-question-" + cb.pos);

        var pcode;

        var loc = (window.Bark.ENV.locale || "").toLowerCase();

        if (loc == "ie") {
            pcode = $("#postcode_id").val();

            if (pcode && pcode.length > 2) {
                pcode = 10; // TEMP workaround
            }
        } else {
            pcode = parseInt($("#postcode_id").val());
        }

        var errorDiv;

        if (
            q.required &&
            !$(".inline-bark-q-text input", scope).val().trim().length
        ) {
            setTimeout(function () {
                var postParams = {};
                postParams["cid"] = $("#category_id").val();

                if (cb.questions[cb.pos].name) {
                    postParams["qid"] = cb.questions[cb.pos].name;
                } else {
                    postParams["qid"] = cb.questions[cb.pos].type;
                }
                postParams["type"] = "loc-err";
                postParams["errtype"] = "required";
                postParams["errvalue"] = $(".inline-bark-q-text input", scope)
                    .val()
                    .trim();
                flowQErrRec(postParams);
            }, 300);

            $(".inline-bark-modal-errors").text(err_msg).removeClass("hide");
            scope.addClass("new-error");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }

        if (pcode < 1 || isNaN(pcode)) {
            setTimeout(function () {
                var postParams = {};
                postParams["cid"] = $("#category_id").val();

                if (cb.questions[cb.pos].name) {
                    postParams["qid"] = cb.questions[cb.pos].name;
                } else {
                    postParams["qid"] = cb.questions[cb.pos].type;
                }
                postParams["type"] = "loc-err";
                postParams["errtype"] = "not-valid";
                postParams["errvalue"] = $(".inline-bark-q-text input", scope)
                    .val()
                    .trim();
                flowQErrRec(postParams);
            }, 300);

            $(".inline-bark-modal-errors").text(err_msg).removeClass("hide");
            scope.addClass("new-error");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }

        for (i = 0; i < cb.questions.length; i++) {
            cur = cb.questions[i];
            if (cur.type === "buyerInfo") {
                // Reset the question
                cb.questions[i] = getBuyerInfoQuestion(false);
                break;
            }
        }

        return true;
    };

    cb.validateUpload = function () {
        var q = cb.questions[cb.pos];
        var scope = $("#bark-question-" + cb.pos);

        if (
            q.has_textarea &&
            q.textarea_required &&
            !$(".inline-bark-q-textarea textarea", scope).val().trim().length
        ) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.enter-value'))
                .removeClass("hide");
            scope.addClass("new-error");

            var errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        return true;
    };

    /**
     * Validate a question with a text box
     * @returns {boolean} <b>True</b> on success
     */
    cb.validateText = function () {
        var q = cb.questions[cb.pos];
        var scope = $("#bark-question-" + cb.pos);
        if (
            q.required &&
            !$(".inline-bark-q-text input", scope).val().trim().length
        ) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.enter-value'))
                .removeClass("hide");
            scope.addClass("new-error");

            var errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        return true;
    };

    /**
     * Validate a question with address lookup
     * @returns {boolean} <b>True</b> on success
     */
    cb.validateAddressLookup = function () {
        var q = cb.questions[cb.pos];
        var errorText = false;
        var scope = $("#bark-question-" + cb.pos);
        // users can edit the text of the address google found, but only to a limited extent.
        // We don't want them to replace their exact address with "New york city" and get away with it
        var diff = Math.abs(
            $("#address-lookup-text-value").val().length -
                $("#bark-address-lookup").val().length
        );
        if ($("#address-lookup-error").val().length) {
            errorText = $("#address-lookup-error").val();
        } else if (
            q.required &&
            !$(".inline-bark-q-text input", scope).val().trim().length
        ) {
            errorText = _t('buyer_create-bark:error-messages.enter-value');
        } else if ($("#address-lookup-text-value").val().length === 0) {
            // it's possible that validation was started before the place was located by autocomplete.
            errorText = _t('buyer_create-bark:error-messages.choose-address-from-list');
        } else if (diff > 5) {
            errorText = _t('buyer_create-bark:error-messages.choose-address-from-dropdown');
        }
        if (errorText) {
            scope.addClass("new-error");
            $(".inline-bark-modal-errors").text(errorText).removeClass("hide");
            var errorDiv = document.querySelector(".inline-bark-modal-errors");
            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }
            return false;
        }
        return true;
    };

    /**
     * Validate a textarea
     * @returns {boolean} <b>True</b> if validated okay
     */
    cb.validateTextarea = function () {
        var q = cb.questions[cb.pos];
        var scope = $("#bark-question-" + cb.pos);
        if (
            q.required &&
            !$(".inline-bark-q-textarea textarea", scope).val().trim().length
        ) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.enter-value'))
                .removeClass("hide");
            scope.addClass("new-error");

            var errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        return true;
    };

    /**
     * Validate a number input
     * @returns {boolean} <b>True</b> if validated okay
     */
    cb.validateNumber = function () {
        var q = cb.questions[cb.pos];
        var scope = $("#bark-question-" + cb.pos);
        var val = $(".inline-bark-q-number input", scope).val().trim();
        var errorDiv;

        if (!val.length && q.required) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.enter-value'))
                .removeClass("hide");
            scope.addClass("new-error");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        val = val.match(/[^0-9e-]+/) ? NaN : parseFloat(val);
        if (isNaN(val)) {
            var suggested = Bark.date("j");
            if (q.min && suggested < q.min) {
                suggested = q.min;
            }
            if (q.max && suggested > q.max) {
                suggested = q.max;
            }
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.valid-number-with-example', {random_example_number: suggested}))
                .removeClass("hide");
            scope.addClass("new-error");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        if ((q.min || q.min === 0) && val < q.min) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.number-more-than-or-equal-to', {lowest_possible_number: q.min}))
                .removeClass("hide");
            scope.addClass("new-error");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        if ((q.max || q.max === 0) && val > q.max) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.number-less-than-or-equal-to', {highest_possible_number: q.max}))
                .removeClass("hide");
            scope.addClass("new-error");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        return true;
    };

    /**
     * Validate a date input
     * @returns {boolean} <b>True</b> if validated okay
     */
    cb.validateDate = function () {
        var q = cb.questions[cb.pos];
        var scope = $("#bark-question-" + cb.pos);
        var invalidError = _t('buyer_create-bark:error-messages.enter-valid-date-with-example', {the_date_today: Bark.date("l jS F Y")});
        var x;
        var date = ($(".bark-date", scope).data("datepicker") || "")
            .toString()
            .trim();
        var from;
        var to;
        var frombits;
        var tobits;
        var today;
        var unixtime;
        var datemin;
        var datemax;
        var tzoffset = -new Date().getTimezoneOffset() * 60;
        var errorDiv;
        if (
            $(".bark-date-unsure", scope).is(":checked") ||
            $(".bark-date-na", scope).is(":checked")
        ) {
            return true;
        }
        if ($(".bark-date-other", scope).is(":checked")) {
            if (!$.trim($(".bark-date-other-text input", scope).val()).length) {
                $(".inline-bark-modal-errors")
                    .text(_t('buyer_create-bark:error-messages.set-other-value'))
                    .removeClass("hide");

                errorDiv = document.querySelector(".inline-bark-modal-errors");

                if (!isFullyInViewport(errorDiv)) {
                    $("#inlineBarkModal").animate(
                        { scrollTop: errorDiv.offsetTop - 24 },
                        800
                    );
                }

                return false;
            }
            return true;
        }
        if (q.required && !$(".bark-date", scope).val().trim()) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.select-a-date'))
                .removeClass("hide");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        try {
            x = new Date(date);

            unixtime = x.getTime() / 1e3 + tzoffset;

            if (!unixtime) {
                // x.getTime() on a validate Date object will return a true-like value, therefore this value is invalid
                $(".inline-bark-modal-errors")
                    .text(invalidError)
                    .removeClass("hide");

                errorDiv = document.querySelector(".inline-bark-modal-errors");

                if (!isFullyInViewport(errorDiv)) {
                    $("#inlineBarkModal").animate(
                        { scrollTop: errorDiv.offsetTop - 24 },
                        800
                    );
                }

                return false;
            }
            today = window.strtotime(Bark.date("Y/m/d 00:00:00"));
            if (q.date_min) {
                if (q.date_min === "today") {
                    datemin = today;
                } else {
                    datemin = strtotime(q.date_min);
                    var tmpDate = new Date(datemin * 1000);
                    tmpDate.setMinutes(0);
                    tmpDate.setHours(0);
                    tmpDate.setSeconds(0);
                    tmpDate.setMilliseconds(0);
                    datemin = tmpDate.getTime() / 1e3 + tzoffset;
                }

                if (datemin === false) {
                    datemin = strtotime("now", q.date_min);
                }

                if (datemin !== false && unixtime < datemin) {
                    $(".inline-bark-modal-errors")
                        .text(_t('buyer_create-bark:error-messages.select-date-after', {
                            min_possible_date: Bark.date("jS M Y", (datemin + tzoffset) * 1e3)
                        }))
                        .removeClass("hide");

                    errorDiv = document.querySelector(
                        ".inline-bark-modal-errors"
                    );

                    if (!isFullyInViewport(errorDiv)) {
                        $("#inlineBarkModal").animate(
                            { scrollTop: errorDiv.offsetTop - 24 },
                            800
                        );
                    }

                    return false;
                }
            }
            if (q.date_max) {
                datemax =
                    q.date_max === "today" ? today : strtotime(q.date_max);
                if (datemax === false) {
                    datemax = strtotime("now", q.date_max);
                }
                if (datemax !== false && unixtime > datemax) {
                    $(".inline-bark-modal-errors")
                        .text(_t('buyer_create-bark:error-messages.enter-value', {
                            maximum_possible_date: Bark.date("jS M Y", (datemax + tzoffset) * 1e3)
                        }))
                        .removeClass("hide");

                    errorDiv = document.querySelector(
                        ".inline-bark-modal-errors"
                    );

                    if (!isFullyInViewport(errorDiv)) {
                        $("#inlineBarkModal").animate(
                            { scrollTop: errorDiv.offsetTop - 24 },
                            800
                        );
                    }

                    return false;
                }
            }
        } catch (e) {
            // Just in case some browsers throw an exception on an invalid date, simulate the same behaviour as in the above
            $(".inline-bark-modal-errors")
                .text(invalidError)
                .removeClass("hide");

            errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        if ($(".bark-date-time-container", scope).length) {
            // Check to see whether the time is valid
            from = $(".bark-date-time-from input", scope).val().trim();
            to = $(".bark-date-time-to input", scope).val().trim();
            if (from || to) {
                if (from) {
                    frombits = from.split(":");
                    if (
                        !from.match(/^\d\d:\d\d$/) ||
                        +frombits[0] < 0 ||
                        +frombits[0] > 23 ||
                        +frombits[1] < 0 ||
                        frombits[1] > 59
                    ) {
                        $(".inline-bark-modal-errors")
                            .text(_t('buyer_create-bark:error-messages.start-time-in-incorrect-format'))
                            .removeClass("hide");
                        return false;
                    }
                }
                if (to) {
                    tobits = to.split(":");
                    if (
                        !to.match(/^\d\d:\d\d$/) ||
                        +tobits[0] < 0 ||
                        +tobits[0] > 23 ||
                        +tobits[1] < 0 ||
                        tobits[1] > 59
                    ) {
                        $(".inline-bark-modal-errors")
                            .text(_t('buyer_create-bark:error-messages.end-time-in-incorrect-format'))
                            .removeClass("hide");
                        return false;
                    }
                }
                if (
                    from &&
                    to &&
                    strtotime(Bark.date(Bark.sprintf("Y/m/d %s", to))) <
                        strtotime(Bark.date(Bark.sprintf("Y/m/d %s", from)))
                ) {
                    $(".inline-bark-modal-errors")
                        .text(_t('buyer_create-bark:error-messages.end-time-before-start-time'))
                        .removeClass("hide");
                    return false;
                }
                return true;
            }
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:error-messages.no-start-or-end-time'))
                .removeClass("hide");
            return false;
        }
        return true;
    };

    /**
     * Validate the buyer info section
     * @returns {boolean} <b>True</b> on success
     */
    cb.validateBuyerInfo = function () {
        var test;
        var hasTelephone = $("#inline-bark-new-user-telephone").length;
        var tel = hasTelephone
            ? $("#inline-bark-new-user-telephone")
                  .val()
                  .trim()
                  .replace(/[^0-9]/g, "")
            : "";
        var buyerNameFieldExists = !!$("#inline-bark-new-user-name").length;
        var buyerName = ($("#inline-bark-new-user-name").val() || "").trim();

        if (
            buyerNameFieldExists &&
            (!buyerName.length || buyerName.toLowerCase() === "no")
        ) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.name.error-message'))
                .removeClass("hide");
            $("#inline-bark-new-user-name")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (
            buyerNameFieldExists &&
            buyerName.replace(/[^a-zA-Z]/g, "").length < 2
        ) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.name.error-message-longer-name'))
                .removeClass("hide");
            $("#inline-bark-new-user-name")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (buyerNameFieldExists && buyerName.match(/@|\.co.?/i)) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.name.error-message-email-in-name'))
                .removeClass("hide");
            $("#inline-bark-new-user-name")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (buyerNameFieldExists && buyerName.match(/[0-9]$/)) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.name.error-message-numbers-in-name'))
                .removeClass("hide");
            $("#inline-bark-new-user-name")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (
            $("#inline-bark-new-user-email").length &&
            !$("#inline-bark-new-user-email").val().trim().length
        ) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.email.error-message'))
                .removeClass("hide");
            $("#inline-bark-new-user-email")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (hasTelephone && !tel.length) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.phone.error-message'))
                .removeClass("hide");
            $("#inline-bark-new-user-telephone")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }

        if (hasTelephone) {
            test = window.basicPhoneCheck.test(tel);
            if (test.invalid) {
                $(".inline-bark-modal-errors")
                    .text(_t('buyer_create-bark:buyer-info.phone.error-message-invalid'))
                    .removeClass("hide");
                $("#inline-bark-new-user-telephone")
                    .closest(".new-project-field")
                    .addClass("new-error");
                return false;
            }
            if (test.badChars) {
                $(".inline-bark-modal-errors")
                    .text(_t('buyer_create-bark:buyer-info.phone.error-message-use-format'))
                    .removeClass("hide");
                $("#inline-bark-new-user-telephone")
                    .closest(".new-project-field")
                    .addClass("new-error");
                return false;
            }
        }
        return true;
    };

    /**
     * Validate the buyer's name
     * @returns {Boolean}
     */
    cb.validateAccountName = function () {
        var buyerName = ($("#inline-bark-new-user-name").val() || "").trim();
        var errors = cb.findAccountNameErrors(buyerName);

        if (errors.empty) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.name.error-message'))
                .removeClass("hide");
            $("#inline-bark-new-user-name")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (errors.tooshort) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.name.error-message-longer-name'))
                .removeClass("hide");
            $("#inline-bark-new-user-name")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (errors.isemail) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.name.error-message-email-in-name'))
                .removeClass("hide");
            $("#inline-bark-new-user-name")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (errors.hasnumbers) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.name.error-message-numbers-in-name'))
                .removeClass("hide");
            $("#inline-bark-new-user-name")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }

        return true;
    };

    /**
     * Find account name errors
     * @param {string} buyername The name to test
     * @returns {object} An object in the form<pre>
     *  {
     *      empty: boolean,
     *      hasnumbers: boolean,
     *      isemail: boolean,
     *      tooshort: boolean
     *  }
     */
    cb.findAccountNameErrors = function (buyername) {
        var output = {
            empty: false,
            hasnumbers: false,
            isemail: false,
            tooshort: false,
        };

        // Ensure that the name is trimmed
        buyername = buyername.trim();

        if (!buyername.length || buyername.toLowerCase() === "no") {
            output.empty = true;
        }
        if (buyername.length < 2) {
            output.tooshort = true;
        }
        if (buyername.match(/@|\.co.?/i)) {
            output.isemail = true;
        }
        if (buyername.match(/[0-9]/)) {
            output.hasnumbers = true;
        }
        return output;
    };

    /**
     * Validate the buyer's email
     * @returns {Boolean}
     */
    cb.validateAccountEmail = function () {
        if (
            $("#inline-bark-new-user-email").length &&
            !$("#inline-bark-new-user-email").val().trim().length
        ) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.email.error-message'))
                .removeClass("hide");
            $("#inline-bark-new-user-email")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        return true;
    };

    /**
     * Validate the buyer's telephone
     * @returns {Boolean}
     */
    cb.validateAccountTel = function () {
        var errors = cb.findAccountTelErrors(
            $("#inline-bark-new-user-telephone").val()
        );

        if (errors.empty) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.phone.error-message'))
                .removeClass("hide");
            $("#inline-bark-new-user-telephone")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (errors.invalid) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.phone.error-message-invalid'))
                .removeClass("hide");
            $("#inline-bark-new-user-telephone")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        if (errors.badchars) {
            $(".inline-bark-modal-errors")
                .text(_t('buyer_create-bark:buyer-info.phone.error-message-use-format'))
                .removeClass("hide");
            $("#inline-bark-new-user-telephone")
                .closest(".new-project-field")
                .addClass("new-error");
            return false;
        }
        return true;
    };

    /**
     * Find errors in the user's telephone number
     * @param {string} tel The telephone number to test
     * @returns {object} An object in the form<pre>
     *  {
     *      badchars: boolean,
     *      empty: boolean,
     *      invalid: boolean
     *  }
     */
    cb.findAccountTelErrors = function (tel) {
        var output = {
            badchars: false,
            empty: false,
            invalid: false,
        };
        var test;
        tel = tel.trim().replace(/[^0-9]/g, "");

        if (!tel.length) {
            output.empty = true;
        }

        test = window.basicPhoneCheck.test(tel);
        if (test.invalid) {
            output.invalid = true;
        }
        if (test.badChars) {
            output.badchars = true;
        }
        return output;
    };

    /**
     * Validate the project details section
     * @returns {boolean} <b>True</b> if passed validation
     */
    cb.validateAdditional = function () {
        var q = cb.questions[cb.pos];
        var pd = $("#project_detail");
        var detail = pd.val() || "";
        var errMsg;
        // Remove date first, then remove all non-alnum chars, and then match a sequence of numbers of at least 6 characters and at most 14
        var phoneInDetail = detail
            .replace(/\d{1,2}[\s\.\-\/]+\d{1,2}[\s\.\-\/]+\d{2,4}/gi, "")
            .replace(/[^A-Za-z0-9]/gi, "")
            .match(/[0-9]{6,14}/);

        if (q.required) {
            if (!detail.length) {
                errMsg = _t('buyer_create-bark:error-messages.enter-value');
            }
            if (!errMsg && detail.length < consts.MIN_DETAIL_LENGTH) {
                errMsg = _t('buyer_create-bark:error-messages.length-too-short', {min_characters_required: consts.MIN_DETAIL_LENGTH})
            }
        }
        if (!errMsg && phoneInDetail) {
            // There are six consecutive numbers
            errMsg = _t('buyer_create-bark:error-messages.phone-number-in-detail', {detected_phone_number: phoneInDetail[0]})
        }
        if (errMsg) {
            $(".inline-bark-modal-errors").text(errMsg).removeClass("hide");
            pd.closest(".inline-bark-q").addClass("new-error");

            var errorDiv = document.querySelector(".inline-bark-modal-errors");

            if (!isFullyInViewport(errorDiv)) {
                $("#inlineBarkModal").animate(
                    { scrollTop: errorDiv.offsetTop - 24 },
                    800
                );
            }

            return false;
        }
        return true;
    };

    cb.logValidationFailed = function () {
        let q = cb.questions[cb.pos];
        createBarkTracking.logModalPageEvent(
            $("#category_id").val(),
            cb.preBarkId,
            q.type,
            "fail",
            q,
            {
                question_number_total: cb.questionsLength,
                step_number: cb.pos + 1,
            }
        );
    };

    /**
     * Validate the answers to the current question
     * @returns {boolean} <b>True</b> if the answers are okay
     */
    cb.validateCurrent = function () {
        var q = cb.questions[cb.pos];

        var func = "validate" + Bark.ucfirst(q.type);

        var res = func in cb ? cb[func]() : true;

        if (res) {
            // Remove the error style
            $(".inline-bark-main-content .new-error").removeClass("new-error");
        }
        $(".inline-bark-modal-errors")[res ? "addClass" : "removeClass"](
            "hide"
        ); // Hide the main error on success

        if (!res) {
            var postParams = {};
            postParams["cid"] = $("#category_id").val();

            if (cb.questions[cb.pos].name) {
                postParams["qid"] = cb.questions[cb.pos].name;
            } else {
                postParams["qid"] = cb.questions[cb.pos].type;
            }

            if (cb.questions[cb.pos].type != "postcode") {
                flowQErrRec(postParams);
            }

            cb.logValidationFailed();
        }

        return res;
    };

    cb.userQuestionsCount = function () {
        var count = 0;

        for (var i = 0; i < cb.questions.length; i++) {
            // don't include questions like phone number, loading indicator
            if (
                !cb.questions[i].hasOwnProperty("omitFromCount") ||
                !cb.questions[i].omitFromCount
            ) {
                // don't include additional questions
                if (cb.questions[i].type !== "additional") {
                    count++;
                }
            }
        }

        return count;
    };

    function addBuyerAccountQuestionsAndContinue() {
        //If we have already added screens after the email one we want to delete them and go again. This can happen
        // when we find a match for a user and then they bail out and go back to the email screen.

        var index = cb.pos + 1;
        cb.questions = cb.questions.slice(0, index);

        if (!cb.userLoggedIn) {
            splitAccountPage();
        }

        cb.next(true);
    }

    /**
     * Process the current category: set the current questions from <code>data</code>, add in default questions, etc
     * @param {object} data Information about the current category
     */
    cb.processCategory = function (data) {
        var index = 0;
        var hideProgress;
        var splitaccountpage = false;
        cb.questions = data.custom_fields || [];
        cb.branching = data.branching || [];
        cb.categoryData.settings = data.settings || [];
        cb.questionsRequired = parseInt(data.custom_questions_required);
        cb.userLoggedIn = cb.categoryData.user.userLoggedIn;
        cb.userName =
            "userName" in cb.categoryData.user
                ? cb.categoryData.user.userName || null
                : false;
        cb.sellerLoggedIn = cb.categoryData.user.sellerLoggedIn;
        cb.categoryUrl = data.name_url;
        cb.questionsLength = 0;
        cb.buyerTelephone =
            "telephone" in cb.categoryData.user
                ? cb.categoryData.user.telephone || null
                : false;
        cb.showLocalCheck = data.show_local_checkbox;

        hideProgress = cb.questions.length === 0; // No point showing a progress bar if there is only one question
        if ($("#category_id_top").val() && $("#postcode_id_top").val()) {
            // The data was gathered from the top of the form
            $("#category_id").val($("#category_id_top").val());
            $("#postcode_id").val($("#postcode_id_top").val());
            $("#postcode_type").val($("#postcode_type_top").val());
            $("#category_name").val($("#category_name_top").val());
        }
        if (!isSkipToStep()) {
            ga("set", "dimension1", cb.categoryUrl);
        }
        if (!cb.userLoggedIn) {
            splitaccountpage = true;
        }

        cb.categoryData.currentCategoryId = Number($("#category_id").val());

        if (data.intro) {
            cb.introAvail = true;

            var introData = {
                has_custom_questions: false,
                is_local: true,
                type: "intro",
                hideNavigation: true,
                hideTop: true,
                hideBorder: true,
                omitFromCount: true,
            };

            if (cb.categoryData.parentCategoryName) {
                cb.questions.splice(1, 0, introData);
            } else {
                cb.questions.unshift(
                    Object.assign({ hideBack: true }, introData)
                );
            }
        }

        var modalText;
        try {
            modalText = cb.categoryData.categories[cb.lastCategory].modal_text;
            if (typeof modalText == "undefined" || modalText == null) {
                modalText = {};
            }
        } catch (e) {
            modalText = {};
        }

        try {
            cb.categPhotoAvail =
                typeof cb.categoryData.categoryHeader
                    .is_included_in_modal_experiment == "undefined"
                    ? 0
                    : cb.categoryData.categoryHeader
                          .is_included_in_modal_experiment;
            cb.modalCategPhoto =
                typeof cb.categoryData.categoryHeader.category_photo ==
                "undefined"
                    ? ""
                    : cb.categoryData.categoryHeader.category_photo;
            $(".modal-category-experiment-photo").html(
                '<img src="' + cb.modalCategPhoto + '" />'
            );
        } catch (e) {}

        let tenderBrowseExperimentVariant = cb.getExperimentVariant(
            "tender_browse_split"
        );

        if (cb.lastBarkMode == 'from-public-profile') {
            cb.questions.push({
                gaPath: "public-profile-quote", // Google Analytics Path
                hideNavigation: true,
                hideTop: true,
                hideProgress: true,
                omitFromCount: true,
                type: "publicProfileQuote",
                name: "public-profile-quote",
            });
        } else {
            if (
                tenderBrowseExperimentVariant &&
                tenderBrowseExperimentVariant >= 1
            ) {
                // Add a new question to the stack
                cb.questions.push({
                    gaPath: "loading-indicator", // Google Analytics Path
                    hideNavigation: true,
                    hideTop: true,
                    omitFromCount: true,
                    show: false,
                    showOnce: true,
                    type: "upsellLoading",
                    name: "upsell-loading-screen",
                });
                cb.questions.push({
                    gaPath: "tenderbrowse-experiment", // Google Analytics Path
                    fbPath: "tenderbrowse-experiment",
                    hideNavigation: true,
                    hideTop: true,
                    omitFromCount: true,
                    type: "tenderBrowse",
                    name: "tender-browse",
                });
            } else {
                if (!cb.userLoggedIn && !cb.browseModalSkipQuestions) {
                    // Only show the upsell screens when a user is not logged in
                    cb.questions.push({
                        gaPath: 'loading-indicator', // Google Analytics Path
                        hideNavigation: true,
                        hideTop: true,
                        omitFromCount: true,
                        show: false,
                        showOnce: true,
                        type: 'upsellLoading',
                        name: 'upsell-loading-screen'
                    });

                    cb.questions.push({
                        gaPath: 'upsell',
                        fbPath: 'Upsell',
                        hideTop: true,
                        omitFromCount: true,
                        type: 'upsell',
                        name: 'upsell-screen'
                    });
                }
            }
        }

        for (var i = 0; i < cb.questions.length; i++) {
            // Count how many real questions there are
            if (!cb.questions[i].omitFromCount) {
                cb.questions[i].index = ++index;
            }
            if (hideProgress) {
                // Hide progress globally
                cb.questions[i].hideProgress = true;
            }
            if (!Bark.in_array(cb.questions[i].type, ["additional"])) {
                // If not a special question and if the category says that all questions are required, all questions are required
                cb.questions[i].required = +data.custom_questions_required
                    ? true
                    : cb.questions[i].required;
            }
        }
        setQuestionsLength();

        if (!cb.userLoggedIn) {
            // Ask the for their email and then we will add more
            // pages whether they are an existing logged out user or not.
            addAccountEmailQuestion();
        } else {
            cb.questions.push(getBuyerInfoQuestion());
        }

        addAccountTypeListeners();

        cb.orgquestions = cb.questions.slice(0);

        // skip to step
        if (isSkipToStep()) {
            skipToStep({
                step: getNewRequestState().skipStep,
                onSkipStep: () => {
                    payload = getNewRequestState().payload;
                },
            });
        }

        cb.renderCurrent();

        try {
            if (!cb.userLoggedIn && !window.Bark.isMobile() && data.toast) {
                setTimeout(function () {
                    if (typeof $.toast !== "function") {
                        bugsnagClient.notify(new Error("No toast is loaded"));
                    } else {
                        toast =
                            typeof $.toast === "function" &&
                            $.toast({
                                heading: data.toast,
                                showHideTransition: "slide",
                                allowToastClose: true,
                                hideAfter: 6000,
                                stack: 1,
                                icon: "success",
                                position: "top-left",
                                loader: false,
                            });

                        $.post("/api/elo/", { c: "toast", n: "open" });

                        $(".close-jq-toast-single").click(function () {
                            $.post("/api/elo/", {
                                c: "toast",
                                n: "click",
                                i: "close",
                            });
                        });
                    }
                }, 1200);
            }
        } catch (e) {
            bugsnagClient.notify(e);
        }
    };

    function isAmerican(lcl) {
        if (lcl == "us" || lcl == "ca") return true;
        return false;
    }

    function addAccountEmailQuestion() {
        var emailLabel = _t('buyer_create-bark:separated-buyer-info.email.label-in-question-form');

        cb.questions.push({
            gaPath: "account__email",
            hideProgress: true,
            label: emailLabel,
            labelExtra: null,
            omitFromCount: true,
            type: "accountEmail",
        });
    }

    function addAccountNameQuestion() {
        var nameLabel = _t('buyer_create-bark:separated-buyer-info.name.label-in-question-form');

        cb.questions.push({
            gaPath: "account__name",
            hideProgress: true,
            label: nameLabel,
            labelExtra: null,
            omitFromCount: true,
            type: "accountName",
        });
    }

    /**
     * Add the three different sections of the account page
     */
    function splitAccountPage() {
        cb.questions.push({
            gaPath: "account__tel",
            hideProgress: true,
            label: _t('buyer_create-bark:separated-buyer-info.phone.label-in-question-form'),
            labelExtra: null,
            omitFromCount: true,
            telephone: cb.buyerTelephone,
            type: "accountTel",
        });

        addAccountNameQuestion();

        if (cb.getSettingBool("ask_business_individual") !== false) {
            addAccountTypeQuestion();
        }
    }

    function addAccountTypeListeners() {
        $(window)
            .off("BarkProgressToNextQuestion.init")
            .on("BarkProgressToNextQuestion.init", function (e) {
                var last = cb.questions[cb.pos] || {};
                var success;
                var failure;
                var validationerror;
                var spinner;
                var ignoresuggestion;

                if (last.type === "accountEmail") {
                    e.preventDefault();

                    if ($(".reveal-modal").length) {
                        spinner = Bark.getHtml(
                            "span",
                            null,
                            null,
                            "fa fa-spin fa-spinner"
                        );
                    } else {
                        spinner =
                            '<div class="spinner-border spinner-border-sm text-white" role="status">' +
                            `<span class="sr-only">${_t('common:loading-message')}</span>` +
                            "</div>";
                    }

                    ignoresuggestion = $(".inline-bark-btn-continue").hasClass(
                        "ignore-suggest"
                    );

                    if (
                        !($("#inline-bark-new-user-email").val() || "").trim()
                    ) {
                        $(".inline-bark-modal-errors")
                            .text(_t('buyer_create-bark:buyer-info.email.error-message'))
                            .removeClass("hide");
                        $("#inline-bark-new-user-email")
                            .closest(".inline-bark-q-cont")
                            .addClass("new-error");
                        cb.logValidationFailed();
                        return;
                    }

                    $(".inline-bark-btn-continue")
                        .html(spinner)
                        .prop("disabled", true)
                        .addClass("disabled");
                    $(".inline-bark-modal-email-errors").addClass("hide");
                    $(".inline-bark-main-content .new-error").removeClass(
                        "new-error"
                    );
                    $(".inline-bark-modal-errors").addClass("hide");

                    success = function () {
                        $(".inline-bark-btn-continue")
                            .html(cb.continueLabel)
                            .prop("disabled", false)
                            .removeClass("disabled");
                        saveCurrent(true);
                        findUserMatchByEmail(
                            $("#inline-bark-new-user-email").val(),
                            () => {
                                addSubmitBarkAsLoggedOutUserQuestion();
                                cb.next(true);
                            },
                            addBuyerAccountQuestionsAndContinue
                        );
                    };

                    failure = function (data) {
                        var emailErrorMsg = _t('buyer_create-bark:buyer-info.email.error-message-invalid');

                        if (data.did_you_mean && !isFrontendV2WithBootstrap()) {
                            emailErrorMsg = _t('buyer_create-bark:buyer-info.email.error-message-did-you-mean', {
                                suggested_email_address: data.did_you_mean
                            })
                            $(".inline-bark-modal-email-errors")
                                .removeClass("hide")
                                .find(".error-text")
                                .text(emailErrorMsg);
                            $(
                                ".inline-bark-modal-email-errors .email-suggest-confirm"
                            ).data({ "suggested-email": data.did_you_mean });
                            $(
                                ".email-suggest-confirm, .email-suggest-cancel"
                            ).addClass("inline-email");
                        } else {
                            $(".inline-bark-modal-errors")
                                .html(emailErrorMsg)
                                .removeClass("hide");
                        }

                        cb.logValidationFailed();

                        $(".inline-bark-btn-continue")
                            .html(cb.continueLabel)
                            .prop("disabled", false)
                            .removeClass("disabled");

                        recordFailedEmail(
                            $("#inline-bark-new-user-email").val()
                        );
                    };

                    validationerror = function (error) {
                        $(".inline-bark-modal-errors")
                            .text(error || _t('buyer_create-bark:buyer-info.email.error-message-invalid'))
                            .removeClass("hide");
                        $("#inline-bark-new-user-email")
                            .closest(".inline-bark-q-cont")
                            .addClass("new-error");
                        $(".inline-bark-btn-continue")
                            .html(cb.continueLabel)
                            .prop("disabled", false)
                            .removeClass("disabled");
                        recordFailedEmail(
                            $("#inline-bark-new-user-email").val()
                        );
                        cb.logValidationFailed();
                    };

                    validateEmailAddress(
                        ignoresuggestion,
                        success,
                        failure,
                        validationerror
                    );
                } else if (last.type === "accountTel") {
                    // The tel q was asked before the last question
                    e.preventDefault();
                    cb.validatedTel = false;
                    if (cb.validateAccountTel()) {
                        if ($(".reveal-modal").length) {
                            spinner = Bark.getHtml(
                                "span",
                                null,
                                null,
                                "fa fa-spin fa-spinner"
                            );
                        } else {
                            spinner =
                                '<div class="spinner-border spinner-border-sm text-white" role="status">' +
                                `<span class="sr-only">${_t('common:loading-message')}</span>` +
                                "</div>";
                        }

                        $(".inline-bark-btn-continue")
                            .html(spinner)
                            .prop("disabled", true)
                            .addClass("disabled");
                        $(".inline-bark-modal-email-errors").addClass("hide");
                        $(".inline-bark-main-content .new-error").removeClass(
                            "new-error"
                        );
                        $(".inline-bark-modal-errors").addClass("hide");

                        Bark.validate.ukTel(
                            $("#inline-bark-new-user-telephone").val(),
                            function (success, moderate, number, error, lineType) {
                                var phone = $(
                                    "#inline-bark-new-user-telephone"
                                ).val();
                                if (success) {
                                    cb.validatedTel = true;
                                    cb.telephoneLineType = lineType;

                                    if (cb.getExperimentVariant('contact_preferences')) {
                                        cb.categoryData.project_data['contact_pref_type_sms'] = Number(cb.telephoneLineType === 'mobile');
                                    }

                                    if (
                                        $(".inline-bark-btn-submit:visible")
                                            .length
                                    ) {
                                        submit();
                                    } else {
                                        $(".inline-bark-btn-continue")
                                            .html(cb.continueLabel)
                                            .prop("disabled", false)
                                            .removeClass("disabled");
                                        cb.next(true);
                                    }
                                } else if (!success && moderate) {
                                    cb.validatedTel = true;
                                    onValidateUkTelManualMod(number);
                                    if (
                                        $(".inline-bark-btn-submit:visible")
                                            .length
                                    ) {
                                        submit();
                                    } else {
                                        $(".inline-bark-btn-continue")
                                            .html(cb.continueLabel)
                                            .prop("disabled", false)
                                            .removeClass("disabled");
                                        cb.next(true);
                                    }
                                } else {
                                    $(".inline-bark-btn-continue")
                                        .html(cb.continueLabel)
                                        .prop("disabled", false)
                                        .removeClass("disabled");
                                    onValidateUkTelErr(error);
                                }
                            },
                            true,
                            'buyer'
                        );
                    } else {
                        cb.logValidationFailed();
                    }
                }
            });
    }

    function addSubmitBarkAsLoggedOutUserQuestion() {
        var submitQuestion = {
            gaPath: "account__buyer_logged_out_submit",
            hideProgress: true,
            label: "",
            labelExtra: null,
            omitFromCount: true,
            type: "submitExistingBuyer",
        };

        var loginQuestion = {
            gaPath: "account__buyer_logged_out_login",
            hideProgress: true,
            label: "",
            labelExtra: null,
            omitFromCount: true,
            type: "loginExistingBuyer",
        };

        var index = cb.pos + 1;

        cb.questions = cb.questions.slice(0, index);
        cb.questions.push(submitQuestion, loginQuestion);

        cb.isLoggedOutExistingBuyer = true;
    }

    function addAccountTypeQuestion() {
        cb.questions.push({
            gaPath: "account__buyer_type",
            hideProgress: true,
            label: _t('buyer_create-bark:one-last-thing'),
            labelExtra: null,
            omitFromCount: true,
            type: "accountType",
        });
    }

    /**
     * Get the buyer information question
     * @returns {object}
     */
    function getBuyerInfoQuestion() {
        var account_path = "account";
        var label = _t('buyer_create-bark:view-matches-now');
        var labelExtra = null;

        var modalText;
        try {
            modalText = cb.categoryData.categories[cb.lastCategory].modal_text;
            if (typeof modalText == "undefined" || modalText == null) {
                modalText = {};
            }
        } catch (e) {
            modalText = {};
        }

        if (modalText.final_heading?.value && modalText.final_subheading?.value) {
            label = modalText.final_heading.value;
            labelExtra = modalText.final_subheading.value;

            if (!modalText.final_subheading.show) labelExtra = null;
        }

        return {
            gaPath: account_path,
            name: 'buyerInfo',
            hideProgress: true,
            label: label,
            labelExtra: labelExtra,
            omitFromCount: true,
            telephone: cb.buyerTelephone,
            type: "buyerInfo",
        };
    }

    /**
     * Set the length of the questions
     */
    function setQuestionsLength() {
        cb.questionsLength = 0;
        for (var i = 0; i < cb.questions.length; i++) {
            // Count how many real questions there are
            if (!cb.questions[i].omitFromCount) {
                cb.questionsLength++;
                cb.questions[i].index = i + 1;
            }
        }
    }

    /**
     * If the user has selected a parent category, build a question packet from which they can choose a subcategory
     * @returns {object}
     */
    function buildCategorySelectionPacket() {
        var options = [];
        var i;
        var x;
        for (i = 0; i < cb.categoryData.order.length; i++) {
            x = cb.categoryData.order[i];
            options.push({
                id: x,
                label: cb.categoryData.categories[x].name,
                thumbnail: cb.categoryData.categories[x].thumbnail,
            });
        }
        return {
            hideNavigation: true,
            hideProgress: true,
            hideRuler: cb.categoryData.thumbnailsAll,
            label: cb.categoryData.parentCategoryName,
            labelExtra: _t('buyer_create-bark:select-category.label'),
            options: options,
            required: true,
            thumbnailsAll: cb.categoryData.thumbnailsAll,
            type: "CategorySelection",
        };
    }

    const getDefaultProjectData = () => {
        const output = {};

        if (cb.getExperimentVariant('contact_preferences')) {
            output['contact_pref_type_phone'] =  1;
            output['contact_pref_type_sms'] = Number(cb.telephoneLineType === 'mobile');
            output['contact_pref_type_email'] =  1;
        }

        return output;
    }

    /**
     * Show the modal
     * @param {object} data A plain object with which to build the modal (the return data from a POST '/validate-project/' XHR call)
     */
    cb.showModal = function ({
        flow = "",
        answers = {},
        data = {},
        trackStartBark = true,
        initialPayload,
        skipStep = "",
        preSelectAnswers = {},
    } = {}) {
        // set new request flow state
        setNewRequestState({
            flow,
            answers,
            payload: initialPayload,
            skipStep,
            preSelectAnswers,
        });

        //set tracking flow
        createBarkTracking.setFlow(isNewRequestFlow());
        //set category_id
        createBarkTracking.setCategoryId($("#category_id").val());
        //set country_id
        createBarkTracking.setCountryId(data?.values?.bark_country_id);

        cb.lastBarkMode = $("#bark_mode").val() || cb.lastBarkMode;
        $("#bark_mode").val("");
        cb.lastCategory = 0;
        cb.onProjectCreated = (data) => {};

        if (trackStartBark) {
            createBarkTracking.logStartBark(null, data?.values?.preBarkId);
        }

        $(".inline-bark-buttons-container .inline-bark-btn-continue").text(
            cb.continueLabel
        );
        $(".inline-bark-buttons-container .inline-bark-btn-submit").text(
            _t('buyer_create-bark:view-matches')
        );
        // START: Lifted from original code
        Bark.hideLoading();
        var error = false;
        var categoryLength = 0;

        if (data.errors["category_id"].length > 0) {
            error = true;
            $("#category_error")
                .text("*" + data.errors["category_id"])
                .show();
            $("#category_error").parent().addClass("new-error");
            $.post("/api/common/search_log/", {
                type: "category",
                text:
                    $("#category_name").val() ||
                    $("#category_name_hidden").val(),
                id: 0,
            });

            createBarkTracking.logModalPageEvent(
                null,
                data?.values?.preBarkId,
                "Invalid Category",
                "fail",
                {},
                {}
            );
        } else {
            $("#category_error").hide();
            $("#category_error").parent().removeClass("new-error");
        }
        if (data.errors["postcode"].length > 0) {
            error = true;
            $("#postcode_error")
                .text("*" + data.errors["postcode"])
                .show();
            $("#postcode_error").parent().addClass("new-error");
            $.post("/api/common/search_log/", {
                type: "postcode",
                text: $("#postcode").val(),
                id: 0,
            });
        } else {
            $("#postcode_error").hide();
            $("#postcode_error").parent().removeClass("new-error");
        }

        // END: Lifted from original code
        if (!error) {
            payload["analytics_variation"] = 0;
            cb.telephoneLineType = data.values?.user?.telephoneIsMobile ? 'mobile' : null;
            if ("preBarkId" in data["values"]) {
                cb.preBarkId = data["values"]["preBarkId"];
                cb.preProcessingFired = data["values"]["preProcessingFired"];
            } else {
                cb.preBarkId = null;
                cb.preProcessingFired = null;
            }
            cb.categoryData = data.values;
            cb.categoryData.project_data = getDefaultProjectData(); // Project Data
            var showUrgentFlow =
                cb.categoryData.experiments.urgent_barks &&
                cb.categoryData.experiments.urgent_barks.variant > 0 &&
                cb.categoryData.experiments.urgent_barks.project_eligible &&
                !cb.shownUrgent &&
                data.values.showUrgent &&
                isFrontendV2WithBootstrap() &&
                $("#urgentBark").html() !== "";

            if (
                cb.categoryData.experiments.CSAT_measurement &&
                cb.categoryData.experiments.CSAT_measurement.variant > 0
            ) {
                cb.CsatExperiment = new CsatExperiment(
                    cb.categoryData.experiments.CSAT_measurement
                );
            }

            try {
                for (let experiment_name in (cb.categoryData?.experiments || {})) {
                    const item = cb.categoryData?.experiments[experiment_name];
                    if (item.just_assigned) {
                        tracking(
                            "Experiment",
                            `Allocation - ${experiment_name}`,
                            {
                                variant: item.variant,
                            }
                        );
                    }
                }
            } catch (e) {
                bugsnagClient && bugsnagClient.notify(e);
            }

            $(".inline-bark-buttons-container .inline-bark-btn-submit").text(_t('buyer_create-bark:view-matches'));

            if (
                cb.categoryData.experiments.auto_continue_create_bark?.variant >
                0
            ) {
                cb.AutoContinueExperiment = new AutoContinueExperiment(
                    cb.categoryData.experiments.auto_continue_create_bark
                );
            }

            if (cb.categoryData.experiments.browse_list_v1) {
                cb.BrowseProjectExperiment = new BrowseProjectExperiment();
                cb.TenderBrowseExperiment = new TenderBrowseExperiment();
                cb.BrowseProjectExperiment.setupBrowseForCBShowModal(
                    trackStartBark,
                    data
                );
            }

            cb.continueLabel = _t('buyer_create-bark:buttons.continue-button');

            // We are doing an experiment here on pest control and we want to open an experiment modal rather than the normal one
            // if we are in this experiment.
            if (showUrgentFlow) {
                var locationFirst = false;
                if (data.values.locationId) {
                    if (data.values.locationId === -1) {
                        locationFirst = true;
                    }

                    var questionOptions = [
                        {
                            id: "custom_question_radio_1_yes",
                            type: "radio",
                            class: "inline-bark-radio",
                            name: "is-it-urgent",
                            value: "urgent",
                            labelClass: "inline-bark-q-label pr-4",
                            labelText: _t('buyer_create-bark:urgent-question-flow.is-urgent'),
                        },
                        {
                            id: "custom_question_radio_1_no",
                            type: "radio",
                            class: "inline-bark-radio",
                            name: "is-it-urgent",
                            value: "not-urgent",
                            labelClass: "inline-bark-q-label",
                            labelText: _t('buyer_create-bark:urgent-question-flow.not-urgent'),
                        },
                    ];

                    var urgentQuestion = window.Handlebars.compile(
                        document.getElementById("is-it-urgent-question")
                            .innerHTML
                    );
                    var urgentQuestionHtml = urgentQuestion({
                        questionOptions: questionOptions,
                    });

                    var locationQuestion = window.Handlebars.compile(
                        document.getElementById("urgent-location-question")
                            .innerHTML
                    );

                    var html;
                    if (locationFirst) {
                        $("#urgentBark .inline-bark-modal-hide-h1").text(_t('buyer_create-bark:hard-coded-questions.pest-controller.title'));
                        html = locationQuestion({}) + urgentQuestionHtml;
                    } else {
                        $("#urgentBark .inline-bark-modal-hide-h1").text(_t('buyer_create-bark:hard-coded-questions.pest-controller.is-urgent'));
                        html = urgentQuestionHtml;
                        $("#urgentBark .location-confirm-button").addClass(
                            "d-none"
                        );
                        $("#urgentBark .answer-button").removeClass("d-none");
                    }

                    $("#urgentBark .inline-bark-main-content").html(html);

                    if (locationFirst) {
                        $(".urgent-question").last().addClass("d-none");
                        setupDynamicPostCodeAutocomplete(
                            "#urgentBark #urgent-postcode"
                        );
                    }

                    if (cb.preBarkId) {
                        $("#urgentBark #pbid").val(cb.preBarkId);
                    }

                    $("#urgent-exp-id").val(
                        cb.categoryData.experiments.urgent_barks.id
                    );

                    $("#urgentBark").modal("show");
                    var pestControlId = "137";

                    // This experiment is only live in this category so while this is a nasty hard coding it ensures that
                    // the category id is set after good old stream complete has got rid of it for us.
                    $("#category_id").val(pestControlId);
                    cb.shownUrgent = true;
                    return;
                }
            } else {
                // Check if we are on an old foundation page or a new Bootstrap one reveal modal is a foundation class.
                if ($(".reveal-modal").length) {
                    $("#inlineBarkModal").foundation("reveal", "open");
                    $(".reveal-modal-bg").addClass("bark-modal-open");
                } else {
                    $("#inlineBarkModal").modal("show");
                }
            }

            // close any open postcode autocompletes
            $("#postcode,#postcode-auto").each(function () {
                var streamComplete = $(this).data("streamcomplete");
                streamComplete && !cb.shownUrgent && streamComplete.close();
            });

            $(window)
                .unbind("keydown.navigate")
                .on("keydown.navigate", function (e) {
                    if (e.which === consts.KEY_CODE_ENTER) {
                        // Progress to the next slide
                        // Test first to see if it is a text area, enter only progresses these fields if a control character is pressed
                        var canlisten =
                            e.target.tagName !== "TEXTAREA" ||
                            (e.target.tagName === "TEXTAREA" &&
                                (e.ctrlKey || e.metaKey));
                        canlisten =
                            canlisten &&
                            !$(e.target).hasClass("js-ignore-keyboard-events");

                        if (
                            typeof cb.questions[cb.pos].name !== "undefined" &&
                            cb.questions[cb.pos].name == "location"
                        ) {
                            setTimeout(function () {
                                canlisten &&
                                    $(".inline-bark-btn-continue").is(
                                        ":visible"
                                    ) &&
                                    $(".inline-bark-btn-continue")
                                        .eq(0)
                                        .click() &&
                                    e.preventDefault();
                            }, 250);
                        } else {
                            canlisten &&
                                $(".inline-bark-btn-continue").is(":visible") &&
                                $(".inline-bark-btn-continue").eq(0).click() &&
                                e.preventDefault();
                        }
                    }
                    if (
                        e.which === consts.KEY_CODE_BACKSPACE &&
                        !$(e.target).is(":input")
                    ) {
                        // If the user is not pointed at an input, prevent pressing backspace from taking the user back one page
                        e.preventDefault();
                    }
                });

            categoryLength = Bark.objectSize(data.values.categories);
            if (!categoryLength) {
                return;
            }

            if (categoryLength === 1) {
                // There is only one category so just go straight to it
                for (var x in data.values.categories) {
                    cb.lastCategory = parseInt(x);
                    // make sure the category id is set (it's possible that validate-project has infered it from what was typed)
                    var categoryIdElement = $("#category_id");
                    if (
                        !categoryIdElement.val() ||
                        categoryIdElement.val() != x
                    ) {
                        categoryIdElement.val(x);
                    }
                    cb.processCategory(data.values.categories[x]);

                    var postParams = {};
                    postParams["cid"] = categoryIdElement.val();
                    if (cb.questions[cb.pos].name) {
                        postParams["qid"] = cb.questions[cb.pos].name;
                    } else {
                        postParams["qid"] = cb.questions[cb.pos].type;
                    }
                    postParams["et"] = "start";
                    if (!isSkipToStep()) {
                        flowQrec(postParams);
                    }

                    return;
                }
            }

            cb.pos = 0;
            cb.questions = [buildCategorySelectionPacket()];
            cb.loggedRemarketing = false;
            cb.logRemarketing = false;

            // The questions have been reset, so any experiment variations have to be done here

            cb.renderCurrent();
        } else if (cb.isPresetCategory && !$("#category_id").val()) {
            $("#category_id").val(cb.isPresetCategory);
        }
    };

    /**
     * Start the Bark journey, i.e., get the data and process the payload and open the modal
     */
    cb.startBarkJourney = function ({
        flow = "",
        answers = {},
        finishedNewFlow = false,
    } = {}) {
        $(".bark-form").find("span.new-error").hide();
        Bark.showLoading();

        setTimeout(function () {
            var payload = {
                category_id: $("#category_id").val(),
                postcode_id: $("#postcode_id").val(),
                postcode_type: $("#postcode_type").val(),
                bark_mode: $("#bark_mode").val(),
                exp_ph: 0,
                category_name:
                    $("#category_name").val() ||
                    $("#category_name_hidden").val(),
            };

            // close the category autocomplete
            $("#category_name,#category_name_top").each(function () {
                var streamComplete = $(this).data("streamcomplete");
                streamComplete && streamComplete.close();
            });

            createBarkTracking.setCategoryId(payload["category_id"]);
            $.post(
                "/validate-project/",
                payload,
                (data) => showModal({ data }),
                "json"
            ).fail(function () {
                Bark.hideLoading();
                alert(_t('buyer_create-bark:error-messages.an-error-occurred'));
            });
        }, 340);
    };

    function resetExistingBuyerStatus(resetForm) {
        cb.isLoggedOutExistingBuyer = false;
        $(".inline-bark-btn-continue").removeClass(
            "js-existing-buyer-login js-existing-buyer-submit"
        );

        if (resetForm) {
            resetCreateBarkToInitialState();
        }
    }

    /**
     * Hide the create bark modal
     */
    cb.hide = function () {
        var categoryId = $("#category_id").val();
        var locale = (window.Bark.ENV.locale || "").toLowerCase();
        var postParams = {};
        if (cb.questions[cb.pos].name) {
            postParams["exqid"] = cb.questions[cb.pos].name;
        } else {
            postParams["exqid"] = cb.questions[cb.pos].type;
        }

        var d = "";

        if (cb.questions[cb.pos].type === "buyerInfo") {
            d = "close-popup-info";
            ga(
                "send",
                "pageview",
                Bark.sprintf(
                    "%s/%s/buyer-modal/%s/%s/%s/",
                    Bark?.ENV?.lang?.toLowerCase() || 'en',
                    locale,
                    categoryId,
                    cb.categoryUrl,
                    "close-popup-info"
                )
            );
            tagManager(
                "virtual_page_view",
                {
                    "virtual_page_path": Bark.sprintf(
                        "%s/%s/buyer-modal/%s/%s/%s/",
                        Bark?.ENV?.lang?.toLowerCase() || 'en',
                        locale,
                        categoryId,
                        cb.categoryUrl,
                        "close-popup-info"
                    )
                });
            $("#inlineBarkModalCloseMessage").text(_t('buyer_create-bark:premature-exit-flow.last-step-alert'));
        } else {
            d = "close-popup";
            ga(
                "send",
                "pageview",
                Bark.sprintf(
                    "%s/%s/buyer-modal/%s/%s/%s/",
                    Bark?.ENV?.lang?.toLowerCase() || 'en',
                    locale,
                    categoryId,
                    cb.categoryUrl,
                    "close-popup"
                )
            );
            tagManager(
                "virtual_page_view",
                {
                    "virtual_page_path": Bark.sprintf(
                        "%s/%s/buyer-modal/%s/%s/%s/",
                        Bark?.ENV?.lang?.toLowerCase() || 'en',
                        locale,
                        categoryId,
                        cb.categoryUrl,
                        "close-popup"
                    )
                });
            $("#inlineBarkModalCloseMessage").text(_t('buyer_create-bark:premature-exit-flow.mid-flow-alert'));
        }
        postParams["ext"] = d;
        postParams["cid"] = $("#category_id").val();
        postParams["et"] = d;
        postParams["s"] = "close-modal";

        // Need to perform a save here to track what the user answered
        //        saveCurrent();
        //        postParams['ans'] = cb.questions[cb.pos].currentAnswers ? JSON.stringify(cb.questions[cb.pos].currentAnswers) : null;

        flowQrec(postParams);

        if (
            cb.isLoggedOutExistingBuyer &&
            cb.questions[cb.pos].type === "loginExistingBuyer"
        ) {
            //We can go ahead and get rid of the modal because they are all done and have submitted their bark
            $("#inlineBarkModal").modal("hide");
            setTimeout(function () {
                cb.confirmHideModal();
            }, 1000);
        } else {
            if (!isFrontendV2WithBootstrap()) {
                $("#inlineBarkModalClose").foundation("reveal", "open");
            } else {
                $("#inlineBarkModal").modal("hide");
                $("#inlineBarkModalClose").modal("show");
            }
        }

        if (toast) {
            toast.reset();
        }

        createBarkTracking.logModalPageEvent(
            categoryId,
            cb.preBarkId,
            "Close modal",
            "view",
            {},
            {
                question_number_total: cb.questionsLength,
                step_number: cb.pos + 1,
                question_completion_percent:
                    cb.calculateQuestionCompletenessPercentage(
                        cb.pos,
                        cb.questionsLength
                    ),
                question_name: cb.questions[cb.pos].name,
            }
        );

        tagManager("bark-modal:close-pre_confirm");
    };

    cb.calculateQuestionCompletenessPercentage = function (
        questionNumber,
        countQuestions
    ) {
        if (questionNumber === 0) {
            return 0;
        }

        if (questionNumber >= countQuestions) {
            return 100;
        }

        return questionNumber / countQuestions;
    };

    cb.cancelHide = function (event) {
        var categoryId = $("#category_id").val();
        var locale = (window.Bark.ENV.locale || "").toLowerCase();

        var postParams = {};

        if (cb.questions[cb.pos].name) {
            postParams["exqid"] = cb.questions[cb.pos].name;
            postParams["exs"] = "questions";
        } else {
            postParams["exqid"] = cb.questions[cb.pos].type;
            postParams["exs"] = cb.questions[cb.pos].type;
        }

        if (typeof event !== "undefined") {
            event.preventDefault();
        }

        if (!isFrontendV2WithBootstrap()) {
            $("#inlineBarkModal").foundation("reveal", "open");
        } else {
            $("#inlineBarkModalClose").modal("hide");
            $("#inlineBarkModal").modal("show");
        }

        ga(
            "send",
            "pageview",
            Bark.sprintf(
                "%s/%s/buyer-modal/%s/%s/%s/",
                Bark?.ENV?.lang?.toLowerCase() || 'en',
                locale,
                categoryId,
                cb.categoryUrl,
                "close-cancel"
            )
        );
        tagManager(
            "virtual_page_view",
            {
                "virtual_page_path": Bark.sprintf(
                    "%s/%s/buyer-modal/%s/%s/%s/",
                    Bark?.ENV?.lang?.toLowerCase() || 'en',
                    locale,
                    categoryId,
                    cb.categoryUrl,
                    "close-cancel"
                )
            });

        postParams["cid"] = $("#category_id").val();

        if (isNaN(parseInt(postParams["cid"]))) {
            postParams["cid"] = cb.lastCategory;
        }

        postParams["et"] = "close-cancel";
        postParams["s"] = "close-modal";
        flowQrec(postParams);
        createBarkTracking.logModalPageEvent(
            categoryId,
            cb.preBarkId,
            "Close modal cancel",
            "success",
            {},
            {
                question_number_total: cb.questionsLength,
                step_number: cb.pos + 1,
                question_completion_percent:
                    cb.calculateQuestionCompletenessPercentage(
                        cb.pos,
                        cb.questionsLength
                    ),
                question_name: cb.questions[cb.pos].name,
            }
        );
        tagManager("bark-modal:close-cancelled");
    };

    cb.confirmHideModal = function (event) {
        var categoryId = $("#category_id").val();
        var locale = (window.Bark.ENV.locale || "").toLowerCase();

        //We are about to reset questions and the like to empty so we need to calculate how many questions they answered
        // first so that we can send that to heap.
        var questionCompletePercentage =
            cb.calculateQuestionCompletenessPercentage(
                cb.pos,
                cb.questionsLength
            );
        var questionName = cb.questions[cb.pos].name;
        var stepNumberBeforeReset = cb.pos + 1;

        if (typeof event !== "undefined") {
            event.preventDefault();
        }
        ga(
            "send",
            "pageview",
            Bark.sprintf(
                "%s/%s/buyer-modal/%s/%s/%s/",
                Bark?.ENV?.lang?.toLowerCase() || 'en',
                locale,
                categoryId,
                cb.categoryUrl,
                "close-done"
            )
        );
        tagManager(
            "virtual_page_view",
            {
                "virtual_page_path": Bark.sprintf(
                    "%s/%s/buyer-modal/%s/%s/%s/",
                    Bark?.ENV?.lang?.toLowerCase() || 'en',
                    locale,
                    categoryId,
                    cb.categoryUrl,
                    "close-done"
                )
            });
        cb.confirmHide();

        var postParams = {};
        postParams["cid"] = $("#category_id").val();

        if (isNaN(parseInt(postParams["cid"]))) {
            postParams["cid"] = cb.lastCategory;
        }

        postParams["et"] = "close-done";
        postParams["s"] = "close-modal";
        flowQrec(postParams);

        // If on mobile, we need to reset the location so that it is not required
        if (window.Bark.isMobile()) {
            $("#postcode_id").val("-1");
            $("#postcode_type").val("-1");
        }

        createBarkTracking.logModalPageEvent(
            categoryId,
            cb.preBarkId,
            "Close modal confirm",
            "success",
            {},
            {
                question_number_total: cb.questionsLength,
                step_number: stepNumberBeforeReset,
                question_completion_percent: questionCompletePercentage,
                question_name: questionName,
            }
        );

        tagManager("bark-modal:close-confirmed");
    };

    cb.confirmHide = function (event) {
        if (typeof event !== "undefined") {
            event.preventDefault();
        }
        ga("send", "pageview", window.location.pathname);
        cb.resetDataForModalHide();

        if (isFrontendV2WithBootstrap()) {
            $("#inlineBarkModalClose").modal("hide");
        } else {
            $("#inlineBarkModal").foundation("reveal", "close");
        }
        // this is a listener in legacy code
        $(window).trigger("createBarkModalClose");
    };

    cb.hideWithoutConfirm = function (event) {
        if (typeof event !== "undefined") {
            event.preventDefault();
        }
        cb.resetDataForModalHide();
        if (isFrontendV2WithBootstrap()) {
            $("#inlineBarkModalClose").modal("hide");
            $("#inlineBarkModal").modal("hide");
        } else {
            $("#inlineBarkModal").foundation("reveal", "close");
        }
    };

    cb.resetDataForModalHide = function () {
        payload = {};
        $(".reveal-modal-bg").removeClass("bark-modal-open");
        $(".inline-bark-main-content").contents().remove();
        $(".inline-bark-percentage-thumb").width(0);
        $(".inline-bark-modal-errors").addClass("hide");
        cb.dispatchEvent("onChangingQuestion");
        cb.pos = 0;
        $(window).unbind("keydown.navigate");
        $(
            ".inline-bark-modal-email-errors, .inline-bark-modal-errors"
        ).addClass("hide");
        if ($("#modalUploadFiles").length) {
            // Unbind Dropzone on modal exit
            Dropzone.forElement("#modalUploadFiles").destroy();
            $("#modalUploadFiles").data("dropzoned", null);
        }

        if (PhoneComplianceMessageIsEnabled(cb.categoryData?.features)) {
            PhoneComplianceMessageRemove($("#inlineBarkModal"));
        }
        //logged in buyer resets
        resetExistingBuyerStatus(true);
    };

    function flowQrec(params) {
        if (logging) return false;

        logging = true;

        var csrf_name = $('meta[name="csrf_name"]').attr("content");
        var csrf_value = $('meta[name="csrf_value"]').attr("content");

        params[csrf_name] = csrf_value;

        $.ajax({
            url: "/api/brkflow/",
            type: "POST",
            dataType: "JSON",
            data: params,
            success: function () {
                logging = false;
            },
            error: function () {
                logging = false;
            },
        });
    }

    function flowQErrRec(params) {
        if (errlogging) return false;

        errlogging = true;

        var csrf_name = $('meta[name="csrf_name"]').attr("content");
        var csrf_value = $('meta[name="csrf_value"]').attr("content");

        params[csrf_name] = csrf_value;

        $.ajax({
            url: "/api/brkflow-e/",
            type: "POST",
            dataType: "JSON",
            data: params,
            success: function () {
                errlogging = false;
            },
            error: function () {
                errlogging = false;
            },
        });
    }

    /**
     * Save the marketing landing page inlined question
     * onto the payload
     *
     * @param questionType
     */
    cb.saveMKpageInlined = function (questionType) {
        for (var p in presets) {
            if (questionType == "checkbox") {
                if (Bark.is_array(presets[p][0])) {
                    var vals = {};
                    for (var i = 0; i < presets[p][0].length; i++) {
                        vals[presets[p][0][i]] = "true";
                    }
                    payload[p] = vals;
                } else {
                    payload[p] = presets[p][0];
                }
            } else if (questionType == "select") {
                if (Bark.is_array(presets[p])) {
                    payload[p] = presets[p][0];
                }
            }
        }
    };

    /**
     * Save the answers to the current question. If there is an email address in the saved answers, this function will also send the
     *  answers as they are to the server where they will be saved in case the user abandons the process
     */
    function saveCurrent(suppressHeapEvent) {
        var q = cb.questions[cb.pos];
        var scope = $("#bark-question-" + cb.pos);
        var output = {
            name: q.name,
            type: q.type,
            res: {},
        };

        if (q.type === "accountEmail") {
            // Reset the canpresubmit flag in case the email has changed
            canpresubmit = true;
        }

        switch (q.type) {
            case "range":
                var rangeValue = cb.getRangeAnswerValue(q);
                output.res[q.name] = payload[q.name] = rangeValue;
                break;
            case "checkbox":
                var checkboxVals = {};
                $("input[type=checkbox]", scope).each(function () {
                    var key = this.id.replace(
                        /custom_question_checkbox_\d+_/,
                        ""
                    );

                    if (this.checked) {
                        checkboxVals[key] = "true";
                        if (key === "other") {
                            // Add in the 'other' field
                            output.res[q.name + "_other"] = payload[
                                q.name + "_other"
                            ] = sanitiseInput(
                                $("#" + this.id + "_value").val()
                            );
                        }
                    } else if (q.recordUnchecked) {
                        checkboxVals[key] = "false";
                    }
                });
                output.res[q.name] = payload[q.name] = checkboxVals;
                break;
            case "select":
            case "photoselect":
            case "intent":
                // Note, selects are rendered as radio boxes
                $("input[type=radio]", scope).each(function () {
                    if (this.checked) {
                        output.res[this.name] = payload[this.name] = this.value;

                        if (this.value === "other") {
                            output.res[this.name + "_other"] = payload[
                                this.name + "_other"
                            ] = sanitiseInput(
                                $(".inline-bark-q-radio-other", scope).val()
                            );
                        }
                        return false;
                    }
                });

                if (q.type === 'intent' && q.hasUrgent) {
                    appendToProjectData('is_urgent_request', Number($('#custom_question_intent').is(':checked')));
                    appendToProjectData('show_urgent_request', 1);
                }
                break;
            case "date":
                var date = (
                    $(".bark-date", scope).data("datepicker").textval || ""
                ).trim();
                var from;
                var to;
                if ($(".bark-date-unsure", scope).is(":checked")) {
                    output.res[q.name] = payload[q.name] = _t('buyer_create-bark:inputs.date-picker.preset-answers.not-sure');
                } else if ($(".bark-date-na", scope).is(":checked")) {
                    output.res[q.name] = payload[q.name] = _t('buyer_create-bark:inputs.date-picker.preset-answers.not-applicable');
                } else if ($(".bark-date-other", scope).is(":checked")) {
                    output.res[q.name] = payload[q.name] = sanitiseInput(
                        $.trim($(".bark-date-other-text input").val())
                    );
                } else {
                    if ($(".bark-date-time-container", scope).length) {
                        // There is a time string
                        from = $(".bark-date-time-from input", scope).val();
                        to = $(".bark-date-time-to input", scope).val();
                        if (from && to) {
                            date = _t('buyer_create-bark:inputs.date-picker.concatenated-time-strings.date-at-time-1-to-time-2', {
                                selected_date: date,
                                from_what_time_24_hr_format: from,
                                to_what_time_24_hr_format: to
                            });
                        } else if (from) {
                            date = _t('buyer_create-bark:inputs.date-picker.concatenated-time-strings.date-at-start-time-only', {
                                selected_date: date,
                                from_what_time_24_hr_format: from
                            });
                        } else if (to) {
                            date = _t('buyer_create-bark:inputs.date-picker.concatenated-time-strings.date-until-end-time-only', {
                                selected_date: date,
                                to_what_time_24_hr_format: until
                            });
                        }
                    }
                    output.res[q.name] = payload[q.name] = date;
                }
                break;
            case "number":
                output.res[q.name] = payload[q.name] = parseFloat(
                    $(".inline-bark-q-number input", scope).val().trim()
                );
                break;

            case "photocheck":
                var photoCheckboxVals = {};
                $("input[type=checkbox]", scope).each(function () {
                    if (this.checked) {
                        var key = this.id.replace(
                            /custom_question_checkbox_\d+_/,
                            ""
                        );
                        photoCheckboxVals[key] = "true";
                    }
                });
                output.res[q.name] = payload[q.name] = photoCheckboxVals;
                break;

            default:
                $(":input", scope).each(function () {
                    var val =
                        this.type === "checkbox"
                            ? this.checked
                            : sanitiseInput(this.value.trim());
                    if (
                        val !== "" &&
                        !Bark.in_array(this.id, ["inline-bark-teldesc"])
                    ) {
                        output.res[this.name] = payload[this.name] = val;
                    }
                });
                break;
        }

        if (!suppressHeapEvent) {
            recordQuestionSuccessInHeap(q, false);
        }

        if (q.saveAsProjectData) {
            appendToProjectData(q.name, payload[q.name]);
            delete payload[q.name];
        }

        if (cb.BrowseProjectExperiment?.shouldBuildPayload) {
            cb.BrowseProjectExperiment.saveInProgressPayload(payload);
        }

        if (!cb.userLoggedIn && payload.email) {
            // No user is logged in and an email address has been captured
            preSubmit();
        }
    }

    const appendToProjectData = (key, data) => {
        try {
            if (Bark.is_a(data, 'object')) {
                // Sanitise the {key: 'true'} values
                for (let x in data) {
                    if (data[x] === 'true') {
                        data[x] = 1;
                    } else if (data[x] === 'false') {
                        data[x] = 0;
                    }
                }

                Object.assign(cb.categoryData.project_data, data);
            } else {
                cb.categoryData.project_data[key] = data;
            }
        } catch (e) {
            bugsnagClient && bugsnagClient.notify(e);
            console.error(e);
        }
    };

    function recordQuestionSuccessInHeap(question, questionWasSkipped) {
        let answerString = payload[question.name];
        if (typeof answerString === "object") {
            answerString = Object.keys(answerString)
                .map(function (k) {
                    return k;
                })
                .join(",");
        }

        let experimentParams = getExperimentTrackingData(cb.categoryData);

        let params = {
					question_number_total: cb.questionsLength,
					answer: answerString,
					step_number: cb.pos + 1,
					...experimentParams,
					was_auto_skipped: !!questionWasSkipped,
					country_id: window.Bark?.ENV?.ccid,
				};

        createBarkTracking.logModalPageEvent(
            $("#category_id").val(),
            cb.preBarkId,
            question.type,
            "success",
            question,
            params
        );
    }

    /**
     * Strips HTML from text by temporarily creating an HTML element, inserting the content, then retrieving text-only
     * @param {string} rawText
     * @returns {string}
     */
    function sanitiseInput(rawText) {
        var sanitised = $("<div/>").html(rawText).text();
        return sanitised;
    }

    /**
     * Pre-submit a Bark
     */
    function preSubmit() {
        var clone = $.extend(true, {}, payload);
        if (!canpresubmit) {
            // We've tried presubmitting before, but the user has become a user since this tab was opened
            return;
        }

        clone.project_detail = clone.project_detail || "";
        clone.category_id = $("#category_id").val();
        clone.postcode_id = $("#postcode_id").val();
        clone.postcode_type = $("#postcode_type").val();
        clone.ENV = {
            lang: (window.Bark.ENV.lang || "").toLowerCase(),
            locale: (window.Bark.ENV.locale || "").toLowerCase(),
            campaign: window.Bark.ENV.campaign,
            tz: window.Bark.ENV.tz || "",
            ptcode: window.Bark.ENV.ptcode,
        };
        clone.meta = {
            project_data: cb.categoryData.project_data || {}
        };

        $("input[id^=file_id]").each(function () {
            clone.file_ids = clone.file_ids || [];
            clone.file_ids.push(+this.value);
        });

        $("input[id^=image_id]").each(function () {
            clone.image_ids = clone.image_ids || [];
            clone.image_ids.push(+this.value);
        });

        clone.preBarkId = cb.preBarkId;

        // Use Bark.json as it has CSRF built in
        Bark.json({
            url: "/json/project/abandoned-bark/",
            data: { payload: JSON.stringify(clone) },
        }).done(function (e) {
            if (e.result === "OK" && payload) {
                payload.abandonedbarkid = e.id;
            } else if (Bark.in_array(e.result, ["LOGGEDIN", "EXISTS"])) {
                // The user is logged in now so don't pre-submit
                canpresubmit = false;
            }
        });
    }

    /**
     * Submit the form
     */
    function submit() {
        if (submitting) {
            return false;
        }
        var timeout = 100;

        payload.is_local = true;

        if ($("#is_local").length && $("#is_local").is(":checked")) {
            payload.is_local = false;
            Bark.json({
                url: "/api/tsug/",
                data: {
                    payload: JSON.stringify(payload),
                    cid: $("#category_id").val(),
                    pid: $("#postcode_id").val(),
                    ptype: $("#postcode_type").val(),
                    preBarkId: cb.preBarkId,
                    preProcessingFired: cb.preProcessingFired,
                },
            });
            timeout += 1400;
        }

        $(window).trigger("beforeCreateBarkModalSubmit");
        payload.process = true;
        payload.project_detail = payload.project_detail || "";
        payload.project_detail = payload.project_detail || "";

        // Forward experiment IDs so that the records can be updated with the project and user ids
        if (cb.categoryData.experiments) {
            for (var experimentName in cb.categoryData.experiments) {
                payload["experiment_id_" + experimentName] =
                    cb.categoryData.experiments[experimentName].id;
            }
        }

        payload['project_data'] = cb.categoryData.project_data;

        if (Bark.GET("email_variant")) {
            payload["enroll_in_email_variant"] = parseInt(
                Bark.GET("email_variant")
            );
        }

        payload.preBarkId = cb.preBarkId;

        if ($("#inline-bark-teldesc").length) {
            payload.isoptout = +!$("#inline-bark-teldesc").is(":checked");
        }

        payload["userType"] = false;
        if ($("input[name=userType]:checked").length > 0) {
            payload["userType"] = $("input[name=userType]:checked").val();
        }

        cb.postBarkToApi(timeout);
    }

    /**
     * Append any Forced eXperiment data
     * @param {object} postData
     */
    const appendFxData = (postData) => {
        const items = {};

        if (!window.URLSearchParams) {
            return;
        }

        new URLSearchParams(window.location.search).forEach((value, key) => {
            const id = key.replace(/^fx_([a-zA-Z0-9]+)/, "$1");

            if (id) {
                items[id] = value;
            }
        });

        if (Object.keys(items).length) {
            postData.fxData = items;
        }
    };

    /**
     * Posts the Bark to the API to create it.
     * @param timeout How long to wait before submitting
     * @param overridePayload Override the local payload. Used by abandoned Barks
     */
    cb.postBarkToApi = function (timeout, overridePayload) {
        if (overridePayload) {
            payload = overridePayload;
        } else {
            const $postcodeId = $("#postcode_id");
            const $postcodeType = $("#postcode_type");

            const postcodeId = $postcodeId && $postcodeId.val() !== null && $postcodeId.val() !== ''
                ? $postcodeId.val()
                : payload.postcode_id

            const postcodeType = $postcodeType && $postcodeType.val() !== null && $postcodeType.val() !== ''
                ? $postcodeType.val()
                : payload.postcode_type

            payload.category_id = $("#category_id").val();
            payload.postcode_id = postcodeId;
            payload.postcode_type = postcodeType;
            payload.bark_mode = cb.lastBarkMode;
            payload.related_type = $("#related_type").val();
        }
        // For Abandoned Barks, pull the questions from the global variable
        if (!cb.questions && questions) {
            cb.questions = questions;
        }
        var answers = cb.getAnswersForSubmission(payload),
            postData;
        $(".inline-bark-modal-errors").addClass("hide");

        tagManager("bark-modal:submit");
        timeout = timeout || 100;
        submitting = true;

        postData = {
            abandonedbarkid: payload.abandonedbarkid,
            additional: payload.project_detail,
            bark: JSON.stringify(answers),
            buyer_verification_code: payload.buyer_verification_code,
            caid: payload.category_id,
            cid: window.Bark.ENV.ccid,
            email: payload.email,
            is_local: payload.is_local ? 1 : 0,
            lid: payload.postcode_id,
            ltype: payload.postcode_type,
            name: payload.name,
            pbid: payload.preBarkId,
            related_type: payload.related_type,
            source: payload.bark_mode,
            telephone: payload.telephone,
            userType: payload.userType ? payload.userType : null,
            tz: Bark.ENV.tz,
            project_data: payload?.project_data || null,
        };

        if ('tform_token' in payload) {
            postData.tform_token = payload.tform_token;
        }

        if ('isoptout' in payload) {
            postData.isoptout = payload.isoptout;
        }

        // Forward experiment IDs so that the records can be updated with the project and user ids
        if (cb.categoryData && cb.categoryData.experiments) {
            for (var experimentName in cb.categoryData.experiments) {
                postData["experiment_id_" + experimentName] =
                    cb.categoryData.experiments[experimentName].id;
            }
        }

        if (cb.lastBarkMode == 'from-public-profile') {
            postData['seller_profile_source'] = window.Bark.get_cookie('publicProfileIdhBarkSrc');
        }

        // Forward all tracking cookies
        var cookies = Bark.get_all_cookies();
        const userTracking = {
            trk_client_user_agent: navigator?.userAgent,
            trk_event_source_url: Bark.safeUrl(window?.location?.href),
        };
        const combinedTracking = Object.assign(cookies, userTracking);
        postData.tracking = Bark.filterTrackableParams(combinedTracking);
        postData.tracking["campaign"] = Bark.get_cookie("campaign");
        postData.tracking['ptcode'] = Bark.get_cookie('ptcode');

        // Add any additional data to the payload. This is the place to add experiment-specific fields
        postData.address_lookup_question = $(
            "#inlineBarkModal input#bark-address-lookup"
        ).val();

        // Business size experiment
        postData.what_size_is_the_business = $(
            "#inlineBarkModal select[name=what_size_is_the_business]"
        ).val();

        appendFxData(postData);

        setTimeout(function () {
            Bark.api(
                "bark/new",
                { ...postData, deprecated_suggestion_responses: "abandoned" },
                async function (data) {
                    if (data.status && data.data) {
                        // Record the new Bark placement in Google Analytics
                        ga("send", "event", "backend", "new_bark_placed", ""); //to be deprecated
                        tagManager('new_bark_placed', { event_category: 'backend' });

                        // Close out the Barkflow
                        flowQrec({
                            et: "submit",
                            cid: $("#category_id").val(),
                        });

                        createBarkTracking.logModalPageEvent(
                            postData.caid,
                            cb.preBarkId,
                            "Bark placed",
                            "success",
                            {},
                            {
                                project_id: data.data.project_id,
                            }
                        );

                        //Nothing else to do here
                        if (data.data.is_logged_out_existing_buyer) {
                            cb.projectDashboardPage = Bark.sprintf(
                                "/buyers/bark/%s/",
                                data.data.project_id_hash
                            );
                            $(".inline-bark-btn-continue")
                                .prop("disabled", false)
                                .removeClass(
                                    "disabled js-existing-buyer-submit"
                                );

                            await verifyExistingBuyerIfNeeded(data.data);

                            submitting = false;
                            payload = {};

                            return true;
                        }

                        if (data.data.experiments?.length) {
                            data.data.experiments.forEach((item) => {
                                cb.addExperimentData(item);

                                if (item.just_assigned) {
                                    tracking(
                                        "Seller List",
                                        `Allocation - ${item.experiment_name}`,
                                        {
                                            variant: item.variant,
                                        }
                                    );
                                }
                            });
                        }

                        // Store the analytics for use in the next page
                        Bark.set_cookie(
                            "new_bark_analytics",
                            data.data.analytics,
                            null,
                            "/"
                        );

                        // Auth and redirection
                        Bark.ENV.JWT = data.data.auth;

                        if (cb.onProjectCreated) {
                            cb.onProjectCreated(data);
                        }

                        // Determine the URL for this project in the buyer dashboard
                        if (
                            data.data.is_new_user &&
                            data.data.blt &&
                            data.data.bli
                        ) {
                            let { project_id_hash, blt, bli } = data.data;
                            cb.projectDashboardPage = `/buyers/bark/${project_id_hash}/?blt=${blt}&bli=${bli}`;
                            window.Bark.set_cookie('new_buyer_first_session', 1, 0, '/');
                        } else {
                            cb.projectDashboardPage = `/buyers/bark/${data.data.project_id_hash}/`;
                        }

                        if (!data.data.is_new_user) {
                            cb.toggleAdditionalDetails(false);
                        }

                        // if it's bootstrap, then the user is already a buyer, so we don't need to do any SMS/Email
                        // verification (yet)
                        if (
                            !cb.userLoggedIn &&
                            !cb.submittingAbandonedBark &&
                            !data.data.is_logged_out_existing_buyer
                        ) {
                            cb.contactVerification &&
                                cb.contactVerification.verifyIfNeeded(
                                    data.data
                                );
                        } else {
                            if (cb.shouldShowAdditionalDetails()) {
                                cb.showAdditionalDetails(
                                    data.data.project_id_hash
                                );
                            } else {
                                cb.redirectToProject();
                            }
                        }
                    } else {
                        // This shouldn't ever happen, but let's handle it if it does
                        bugsnagClient.notify(
                            new Error("Failed to create Bark"),
                            {
                                metaData: {
                                    requestPayload: postData,
                                    responsePayload: data,
                                },
                            }
                        );
                        $(".inline-bark-modal-errors")
                            .text(_t('buyer_create-bark:error-messages.error-submitting-request'))
                            .removeClass("hide");
                        $("#inlineBarkModalSpinner").hide();
                        $("#inlineBarkModalContent").show();
                        $(".inline-bark-buttons-container").show();
                        submitting = false;
                    }
                },
                function (data) {
                    var responseJSON = data.responseJSON || {};
                    // Handle where the user is not logged in, but the email address already exists
                    if (data.status === 401 && responseJSON.ppid) {
                        location.replace(
                            Bark.sprintf(
                                "/create-bark/login/%s/",
                                responseJSON.ppid
                            )
                        );
                        return;
                    }
                    bugsnagClient.notify(
                        new Error("Bark creation validation error"),
                        {
                            metaData: {
                                requestPayload: postData,
                                responsePayload: data,
                            },
                            severity: "info",
                        }
                    );
                    var errorMessage = _t('buyer_create-bark:error-messages.error-submitting-request');
                    if (responseJSON.error && responseJSON.error.errors) {
                        var messageParts = [];
                        for (var field in responseJSON.error.errors) {
                            messageParts.push(responseJSON.error.errors[field]);
                        }
                        errorMessage = messageParts.join(" ");
                    }

                    $(".inline-bark-modal-errors")
                        .text(errorMessage)
                        .removeClass("hide");
                    $("#inlineBarkModalSpinner").hide();
                    $("#inlineBarkModalContent").show();
                    // There might be a spinner in the button if this is a logged out existing buyer
                    if (cb.isLoggedOutExistingBuyer) {
                        $(".inline-bark-btn-continue")
                            .html(_t('buyer_create-bark:buttons.submit-request-button'))
                            .prop("disabled", false)
                            .removeClass("disabled js-existing-buyer-login");
                    }

                    $(".inline-bark-buttons-container").show();
                    submitting = false;
                },
                "POST"
            );
        }, timeout);
        return;
    };

    cb.toggleAdditionalDetails = function (state) {
        cb.additionalDetails = state;
    };

    cb.toggleSubmittingAbandonedBark = function (state) {
        cb.submittingAbandonedBark = state;
    };

    cb.shouldShowAdditionalDetails = function () {
        return (
            cb.additionalDetails &&
            !cb.categoryData?.categories[cb.lastCategory]?.exclude_additional_details_category
        );
    };

    /**
     * Get user tel
     * @returns {String}
     */
    cb.gut = () => {
        return payload && payload["telephone"];
    };

    cb.showAdditionalDetails = async function (projectHash) {
        const categoryId = $("#category_id").val();
        const pageTypeForTracking = "Additional Details";
        const { preBarkId } = cb;
        const { showLoading } = window.Bark;
        const customSubHeader = cb.getSetting("additional_details_prompt.sub_header");
        const allowAttachedFiles = cb.getSetting("additional_details_prompt.show_attach_files");

        const experimentParams = await AdditionalDetailsDesignPicker({
            subheadingMarkdown: customSubHeader,
            allowFiles: allowAttachedFiles
        });

        const additionalDetailOptions = Object.assign(
            {
                projectId: projectHash,
                placeholderText: cb.getSetting(
                    "additional_details_prompt.placeholder_text"
                ),
            },
            experimentParams
        );

        additionalDetailOptions.onInit = () => {
            createBarkTracking.logModalPageEvent(
                categoryId,
                preBarkId,
                pageTypeForTracking,
                "view",
                {},
                {
                    allowAttachedFiles: allowAttachedFiles,
                    usingCustomSubHeader: customSubHeader !== null
                }
            );
        };

        additionalDetailOptions.onBeforeSkip = () => {
            $(".modal").modal("hide");
            showLoading();
        };

        additionalDetailOptions.onSkip = () => {
            $.post("/api/elo/", {
                c: "additional_details_modal",
                n: "click",
                i: "skip",
            });
            createBarkTracking.logModalPageEvent(
                categoryId,
                preBarkId,
                pageTypeForTracking,
                "skip"
            );

            cb.redirectToProject();
        };

        additionalDetailOptions.onSubmit = () => {
            tagManager("bark-modal:addition-details:submit");
            createBarkTracking.logModalPageEvent(
                categoryId,
                preBarkId,
                pageTypeForTracking,
                "submit"
            );

            $(".modal").modal("hide");
            showLoading();
        };

        additionalDetailOptions.onSubmitSuccess = () => {
            tagManager("bark-modal:addition-details:submit:success");
            createBarkTracking.logModalPageEvent(
                categoryId,
                preBarkId,
                pageTypeForTracking,
                "success"
            );
            cb.redirectToProject();
        };

        additionalDetailOptions.onSubmitFail = () => {
            tagManager("bark-modal:addition-details:submit:error");
            createBarkTracking.logModalPageEvent(
                categoryId,
                preBarkId,
                pageTypeForTracking,
                "error"
            );
            cb.redirectToProject();
        };

        await AdditionalDetails(additionalDetailOptions);
    };

    cb.redirectToProject = function () {
        if (cb.projectDashboardPage) {
            window.location.replace(cb.projectDashboardPage);
        } else {
            window.location.replace("/buyer/dashboard/");
        }
    };

    /**
     * Get the HTML that will be appended to the submit form
     * @param {object} data The payload data
     * @returns {html}
     */
    cb.getSubmitFormHtml = function (data) {
        var x;
        var y;
        var cur;
        var sub;
        var html = "";
        var i;

        for (x in data) {
            cur = data[x];
            if (Bark.is_a(cur, "object")) {
                for (y in cur) {
                    sub = cur[y];
                    html += Bark.getHtml("input", null, null, null, {
                        name: x + "[" + y + "]",
                        value: sub,
                        type: "hidden",
                    });
                }
            } else if (Bark.is_a(cur, "array")) {
                for (i = 0; i < cur.length; i++) {
                    html += Bark.getHtml("input", null, null, null, {
                        name: x + "[]",
                        value: cur[i],
                        type: "hidden",
                    });
                }
            } else {
                html += Bark.getHtml("input", null, null, null, {
                    name: x,
                    value: cur,
                    type: "hidden",
                });
            }
        }
        return html;
    };

    cb.getAnswersForSubmission = function (data) {
        var answers = {},
            response,
            questionId,
            answerId,
            questionIndex,
            question;

        // Loop through each of the actual category questions and get the answer provided
        for (questionIndex in cb.questions) {
            question = cb.questions[questionIndex];
            if (question.name && data[question.name]) {
                questionId = question.name;
                response = data[questionId];
                if (question.type === "range") {
                    answers[questionId] = response;
                } else if (Bark.is_a(response, "object")) {
                    answers[questionId] = [];
                    for (answerId in response) {
                        if (answerId === "other") {
                            answers[questionId].push({
                                type: "other",
                                value: data[question.name + "_other"],
                            });
                        } else {
                            answers[questionId].push({
                                type: question.type,
                                value: answerId,
                            });
                        }
                    }
                } else if (Bark.is_a(response, "array")) {
                    answers[questionId] = [];
                    for (answerId in response) {
                        answers[questionId].push({
                            type: question.type,
                            value: response[answerId],
                        });
                    }
                } else {
                    if (
                        response === "other" &&
                        data[question.name + "_other"]
                    ) {
                        answers[questionId] = {
                            type: "other",
                            value: data[question.name + "_other"],
                        };
                    } else {
                        answers[questionId] = {
                            type: question.type,
                            value: response,
                        };
                    }
                }
            }
        }

        return answers;
    };

    /**
     * Record link clicks for experiments
     * @param {string} value The value to save
     * @param {integer} sessionId The experiments_session_data ID
     * @param {string} action The action taken, default is 'click_link'
     */
    cb.recordExperimentAction = function (value, sessionId, action) {
        var pagename = window.location.pathname;

        action = action || "click_link";

        Bark.json({
            url: "/api/exp-act/",
            data: {
                xpid: sessionId,
                act: action,
                actv: value,
                pid: 0,
                pg: pagename,
            },
        });
    };

    function recordFailedMailgun(email) {
        var postParams = {};
        var csrf_name = $('meta[name="csrf_name"]').attr("content");
        var csrf_value = $('meta[name="csrf_value"]').attr("content");

        postParams[csrf_name] = csrf_value;
        postParams["email"] = email;

        $.ajax({
            url: "/api/failed-mgun/",
            type: "POST",
            dataType: "JSON",
            data: postParams,
            success: function () {},
        });
    }

    function findUserMatchByEmail(email, successCallback, notFoundCallback) {
        var postParams = {};
        var csrf_name = $('meta[name="csrf_name"]').attr("content");
        var csrf_value = $('meta[name="csrf_value"]').attr("content");
        var spinner = "";

        postParams[csrf_name] = csrf_value;
        postParams["email"] = email;
        if ($(".reveal-modal").length) {
            spinner = Bark.getHtml("span", null, null, "fa fa-spin fa-spinner");
        } else {
            spinner =
                '<div class="spinner-border spinner-border-sm text-white" role="status">' +
                `<span class="sr-only">${_t('common:loading-message')}</span>` +
                "</div>";
        }
        $(".inline-bark-btn-continue")
            .html(spinner)
            .prop("disabled", true)
            .addClass("disabled");

        $.ajax({
            url: "/check-bark-email/",
            type: "POST",
            dataType: "JSON",
            data: postParams,
            success: function (data) {
                if (data.existing_user === false) {
                    $(".inline-bark-btn-continue")
                        .html(cb.continueLabel)
                        .prop("disabled", false)
                        .removeClass("disabled");
                    notFoundCallback(true);
                } else {
                    $(".inline-bark-btn-continue")
                        .html(cb.continueLabel)
                        .prop("disabled", false)
                        .removeClass("disabled");
                    cb.loggedOutUser = {
                        id: data.user_id,
                        name: data.existing_user_first_name,
                        avatar: data.avatar_url,
                    };
                    cb.telephoneLineType = data.has_mobile ? 'mobile' : 'unknown';
                    successCallback();
                }
            },
            error: function (error) {
                $(".inline-bark-btn-continue")
                    .html(cb.continueLabel)
                    .prop("disabled", false)
                    .removeClass("disabled");
                notFoundCallback(true);
                cb.loggedOutUser = false;
            },
        });
    }

    function recordFailedEmail(email) {
        var postParams = {};
        var csrf_name = $('meta[name="csrf_name"]').attr("content");
        var csrf_value = $('meta[name="csrf_value"]').attr("content");

        postParams[csrf_name] = csrf_value;
        postParams["email"] = email;

        $.ajax({
            url: "/check-bark-email/",
            type: "POST",
            dataType: "JSON",
            data: postParams,
            success: function () {},
            error: function () {},
        });
    }
    /**
     * Hook for when a UK telephone has been validated
     * @param {boolean} success <b>True</b> if the telephone number validated okay
     * @param {boolean} moderate [unknown]
     * @param {string} number The telephone number that was validated
     */
    function onValidateUkTel(success, moderate, number, error) {
        if (success) {
            submit();
        } else if (!success && moderate) {
            onValidateUkTelManualMod(number);
            submit();
        } else {
            onValidateUkTelErr(error);
        }
    }

    function onValidateUkTelManualMod(number) {
        $("#project-create-form").append(
            '<input type="hidden" name="_automoderate" value="1">'
        );

        cb.logValidationFailed();
    }

    function onValidateUkTelErr(error) {
        $("#inline-bark-new-user-telephone")
            .closest(".inline-bark-q-cont")
            .addClass("new-error");
        var msg = _t('buyer_create-bark:buyer-info.phone.error-message-telephone-number-is-not-valid');

        if (error) {
            msg = error;
        }

        $(".inline-bark-modal-errors").text(msg).removeClass("hide");
        $("#inlineBarkModalSpinner").hide();
        $("#inlineBarkModalContent").show();
        $(".inline-bark-buttons-container").show();

        cb.logValidationFailed();
    }

    function renderSearchResponseSubheadingText() {
        var text = _t('buyer_create-bark:click-continue-for-quotes');
        var modalText = getModalText();

        if (typeof modalText != "undefined" && modalText) {
            text = "";

            if (modalText.search_response_subheading.show) {
                text = modalText.search_response_subheading.value;
            }
        }

        return text;
    }

    function renderSearchResponseHeadingText() {
        var text = _t('buyer_create-bark:we-have-professionals-available');
        var modalText = getModalText();

        if (typeof modalText != "undefined" && modalText) {
            text = modalText.search_response_heading.value;
        }

        return text;
    }

    function renderLoadingText() {
        if (toast) toast.reset();

        var text = _t('buyer_create-bark:searching-for-professionals-near-you');
        var modalText = getModalText();

        if (typeof modalText != "undefined" && modalText) {
            text = modalText.searching_for_prof.value;
        }

        return "<h4>" + text + "</h4>";
    }

    function getModalText() {
        var result = false;

        try {
            result =
                Bark.createBark.categoryData.categories[
                    Bark.createBark.lastCategory
                ].modal_text;
        } catch (e) {}

        return result;
    }

    function isBuyerModalDirty() {
        var test_payload = typeof payload === "undefined" ? {} : payload;

        if (Object.keys(test_payload).length && !submitting) {
            return _t('buyer_create-bark:premature-exit-flow.are-you-sure');
        }
    }

    /**
     * Get a date corrected for timezone
     * @param {string} format The format to set
     * @param {string} date The date to format
     * @returns {string} The formatted date string
     */
    function getLocalDateTime(format, date) {
        return Bark.date(
            format,
            strtotime(date) * 1e3 +
                -new Date(date).getTimezoneOffset() * 60 * 1e3
        );
    }

    /**
     * Validate email address
     * @param {boolean} ignoreSuggestions
     * @param {callback} success The success callback
     * @param {callback} error The error callback
     * @param {callback} validationerror The callback to run when validation breaks
     * @returns {undefined}
     */
    function validateEmailAddress(
        ignoreSuggestions,
        success,
        error,
        validationerror
    ) {
        // Validate the email address before we go anywheres

        $("#inline-bark-new-user-email").email_validator({
            success: function (data) {
                var accept_email = data.is_valid;

                try {
                    if (
                        !accept_email &&
                        data.error_message == "Error starting domain analysis"
                    ) {
                        accept_email = true;
                    }
                } catch (e) {}

                if (accept_email) {
                    success && success();
                } else {
                    error && error(data);
                }
            },
            error: function (err) {
                // @warning shit and unsafe
                if (err === "Error occurred, unable to validate address.") {
                    // Fallback to our basic validator
                    success && success();
                    recordFailedMailgun($("#inline-bark-new-user-email").val());
                } else {
                    validationerror && validationerror(err);
                }
            },
        });
    }

    /**
     * Bind events on to relevant items on the page
     * @note WHEN ADDING NEW EVENTS, MAKE SURE TO UNBIND THEM FIRST USING <code>$().off()</code> BEFORE BINDING THEM. THIS IS BECAUSE
     *  THIS FUNCTION IS CALLED MORE THAN ONCE. A PATTERN TO USE IS <code>$().off('event.custom_namespace').on('event.custom_namespace')</code>.
     *  USING A NAMESPACE PREVENTS THE ACCIDENTAL UNBINDING OF A PREVIOUSLY SET EVENT HANDLER
     */
    cb.bindEvents = function () {
        if ($("input#bark-address-lookup").length) {
            var loc = (window.Bark.ENV.locale || "").toLowerCase();
            var opts = {
                types: ["address"],
            };
            if (loc) {
                opts.componentRestrictions = { country: loc };
            }
            var input = $("input#bark-address-lookup").get()[0];
            var autocompleteauto = new google.maps.places.Autocomplete(
                input,
                opts
            );
            autocompleteauto.setFields(["address_component"]);
            autocompleteauto.addListener("place_changed", function () {
                var preciseAddressFound = false;
                $("#address-lookup-text-value").val(
                    $("input#bark-address-lookup").val()
                );
                $("#address-lookup-error").val("");
                var place = autocompleteauto.getPlace();
                if (!place || !place.address_components) {
                    $("#address-lookup-error").val(_t('buyer_create-bark:error-messages.invalid-location'));
                    return;
                }
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (
                        !preciseAddressFound &&
                        addressType === "street_number"
                    ) {
                        preciseAddressFound = true;
                    }
                    if (addressType === "postal_code") {
                        var postcode =
                            place.address_components[i]["short_name"];
                        Bark.api(
                            "location",
                            {
                                term: postcode,
                                coid: cb.categoryData.bark_country_id,
                            },
                            function (e) {
                                if (e.data && e.status && e.status === true) {
                                    $("#postcode_id").val(e.data[0].id);
                                    payload.postcode_id = e.data[0].id;
                                    $("#postcode_type").val(e.data[0].type);
                                    payload.postcode_type = e.data[0].type;
                                    $("#postcode").val(postcode);
                                    // remove location question if it's after this one:
                                    for (
                                        var i = cb.pos;
                                        i < cb.questions.length;
                                        i++
                                    ) {
                                        if (
                                            cb.questions[i].type === "postcode"
                                        ) {
                                            cb.questions.splice(i, 1);
                                        }
                                    }
                                }
                            },
                            function (err) {
                                var message =
                                    "error with address lookup for zip: " +
                                    $("#postcode").val() +
                                    " and address " +
                                    $("input#bark-address-lookup").val();
                                if (err.error && err.error.message)
                                    message += " - " + err.error.message;
                                window.bugsnagClient &&
                                    window.bugsnagClient.notify(
                                        new Error(message)
                                    );
                            },
                            "POST",
                            Bark.apiVersionHeader("v1")
                        );
                    }
                }
                if (!preciseAddressFound) {
                    $("#address-lookup-error").val(_t('buyer_create-bark:error-messages.enter-precise-location'));
                }
            });
        }

        if ($("input.js-range-slider").length) {
            cb.rangeSlider.bindEvents();
        }

        // Start bark_user_type_experiment event
        $("#inlineBarkModal")
            .off("click.acctType")
            .on("click.acctType", ".account-type-button", function () {
                $(this).find(".custom-control-input").prop("checked", true);
                $(".account-type-button").removeClass("border-primary");
                $(this).addClass("border-primary");
                payload["userType"] = $(this)
                    .find(".custom-control-input")
                    .val();
            });
        // End bark_user_type_experiment event

        if ($("body").hasClass("mobile")) {
            $(".choose-category.pickbyimage .opts label")
                .unbind("click.lblcheck")
                .on("click.lblcheck", function () {
                    try {
                        var opt = $(this)
                            .parent()
                            .find(
                                'input[type=radio][name="choose-category-option"]'
                            );

                        if (opt.length) {
                            opt.prop("checked", true);
                        }
                    } catch (error) {}
                });
        }

        if ($(".inline-bark-q-text #postcode-auto").length) {
            setupDynamicPostCodeAutocomplete("postcode-auto");
        }

        if (
            $("#modalUploadFiles").length &&
            !$("#modalUploadFiles").data("dropzoned")
        ) {
            Bark.initModalUpload(null, null, "#project-create-form");
            $("#modalUploadFiles").data({ dropzoned: true });
        }

        // Click a radio/checkbox when its neighbouring "other" <input> is focused on.
        $("input.inline-bark-q-label-input")
            .unbind("focusin.select")
            .on("focusin.select", function (e) {
                e.stopPropagation();
                e.preventDefault();
                var scope = $(this).closest(".inline-bark-q");
                $(
                    ".inline-bark-checkbox,.inline-bark-radio",
                    scope
                )[0].checked = true;
                return false;
            });

        // Click a checkbox when its container is clicked on.
        $(".inline-bark-q.can-highlight")
            .unbind("click.select")
            .on("click.select", function (e) {
                e.stopPropagation();
                e.preventDefault();

                // Only run when the div or label is clicked, not when one of the div's other child elements is clicked.
                // For example, when a <label for="..."> is clicked, it also triggers a click on the <input name="...">.
                var isParentEl =
                    e.target.tagName === "DIV" &&
                    e.target.classList.contains("inline-bark-q");
                var isLabel =
                    e.target.tagName === "LABEL" &&
                    e.target.classList.contains("inline-bark-q-label");
                if (!isParentEl && !isLabel) {
                    return;
                }

                var errCont = $(this).closest(".new-error");
                var radiosCont = $(this).closest(".inline-bark-q-radio");

                if (errCont.length || radiosCont.length) {
                    // If there was an error, e.g. the user tried to continue with the "Other" value not being filled in,
                    // hide the error message when another option has been selected.
                    $(".inline-bark-modal-errors").addClass("hide");
                }

                if (radiosCont.length) {
                    // Remove errors whenever a radio is checked.
                    radiosCont
                        .closest(".bark-modal-question")
                        .find(".new-error")
                        .removeClass("new-error");
                }

                errCont.removeClass("new-error");

                var checkboxesAndRadios = $(
                    "input[type=checkbox],input[type=radio]",
                    this
                ).toArray();
                if (checkboxesAndRadios.length !== 1) {
                    throw new Error(
                        "Expected 1 checkbox/radio element, but there are: " +
                            checkboxesAndRadios.length
                    );
                }

                checkboxesAndRadios.forEach(function (checkboxOrRadio) {
                    switch (checkboxOrRadio.type) {
                        case "radio":
                            checkboxOrRadio.checked = true;
                            break;
                        case "checkbox":
                            checkboxOrRadio.checked = !checkboxOrRadio.checked;
                            break;
                        default:
                            throw new Error(
                                "Unsupported element type: " +
                                    checkboxOrRadio.type
                            );
                    }

                    checkboxOrRadio.focus();
                });
            });

        // Submit
        $(".inline-bark-close-btn-quit")
            .unbind("click.submit")
            .on("click.submit", function (event) {
                cb.confirmHideModal(event);
            });

        // Submit
        $(".inline-bark-close-btn-continue")
            .unbind("click.submit")
            .on("click.submit", function (event) {
                cb.cancelHide(event);
            });

        // Submit
        $(".inline-bark-modal-email-errors .email-suggest-confirm")
            .unbind("click.submit")
            .on("click.submit", function () {
                var t = $(this);
                $("#inline-bark-new-user-email").val(t.data("suggested-email"));
                $(".inline-bark-modal-email-errors").addClass("hide");
                if (t.hasClass("inline-email")) {
                    $(".inline-bark-btn-continue").click();
                } else {
                    // Only perform the submit if the user is viewing the account page all as one
                    $(".inline-bark-btn-submit").trigger("click.submit");
                }
            });

        // Submit
        $(".inline-bark-modal-email-errors .email-suggest-cancel")
            .unbind("click.ignore")
            .on("click.ignore", function () {
                $(".inline-bark-modal-email-errors").addClass("hide");
                if ($(this).hasClass("inline-email")) {
                    $(".inline-bark-btn-continue")
                        .addClass("ignore-suggest")
                        .click();
                } else {
                    $(".inline-bark-btn-submit")
                        .addClass("ignore-suggest")
                        .click();
                }
            });

        // Submit
        $("#inline-bark-new-user-email")
            .off("change.remignore")
            .on("change.remignore", function () {
                $(
                    ".inline-bark-btn-submit, .inline-bark-btn-continue"
                ).removeClass("ignore-suggest");
            });

        $("#inlineBarkModal .js-existing-buyer-submit")
            .off('click.submitExisting')
            .on('click.submitExisting', function (event) {
                var spinner = window.Handlebars.compile(
                    document.getElementById("bark-button-spinner").innerHTML
                );
                var spinnerHtml = spinner({
                    isBootstrap: isFrontendV2WithBootstrap(),
                });

                $(".inline-bark-btn-continue")
                    .html(spinnerHtml)
                    .prop("disabled", true)
                    .addClass("disabled");
                var $continueButton = $(
                    ".inline-bark-buttons-container .inline-bark-btn-continue"
                );

                $continueButton.addClass("js-existing-buyer-login");
                submit();

                var user = cb.loggedOutUser;
                var userId = user ? user.id : 0;

                $.post("/api/elo/", {
                    c: "rebark_submit",
                    n: "clicked",
                    i: userId,
                });
            });

        $("#inlineBarkModal .js-existing-buyer-login")
            .off("click.login")
            .on("click.login", function (event) {
                event.preventDefault();
                event.stopPropagation();

                var user = cb.loggedOutUser;
                var userId = user ? user.id : 0;

                $.post("/api/elo/", {
                    c: "rebark_login",
                    n: "clicked",
                    i: userId,
                });

                cb.redirectToProject();
            });

        // Submit
        $(".inline-bark-btn-submit")
            .unbind("click.submit")
            .on("click.submit", function () {
                var successcallback;
                var failurecallback;
                var validationerror;
                var inexperiment = true;

                if (cb.validateCurrent()) {
                    $("body,html").animate({ scrollTop: 0 }, 800);
                    $(this).closest(".new-error").removeClass("new-error");
                    saveCurrent();
                    $("#inlineBarkModalContent").hide();
                    $("#inlineBarkModalSpinner").show();
                    $(".inline-bark-buttons-container").hide();

                    if (
                        $("#inline-bark-new-user-email").length &&
                        !inexperiment
                    ) {
                        // There is a user email field and this field has not yet been checked

                        successcallback = function () {
                            Bark.validate.barkEmail(
                                $("#inline-bark-new-user-email").val(),
                                function (status, error) {
                                    if (!status) {
                                        $(".inline-bark-modal-errors")
                                            .text(error)
                                            .removeClass("hide");
                                        $("#inline-bark-new-user-email")
                                            .closest(".inline-bark-q-cont")
                                            .addClass("new-error");
                                        $("#inlineBarkModalSpinner").hide();
                                        $("#inlineBarkModalContent").show();
                                    } else {
                                        Bark.validate.ukTel(
                                            $(
                                                "#inline-bark-new-user-telephone"
                                            ).val(),
                                            onValidateUkTel,
                                            true,
                                            'buyer'
                                        );
                                    }
                                }
                            );
                        };

                        failurecallback = function (data) {
                            var emailErrorMsg = _t('buyer_create-bark:buyer-info.email.error-message-email-is-not-valid');

                            if (data.did_you_mean) {
                                emailErrorMsg = _t('buyer_create-bark:buyer-info.email.error-message-did-you-mean', {
                                    suggested_email_address: data.did_you_mean
                                });
                                $(".inline-bark-modal-email-errors")
                                    .removeClass("hide")
                                    .find(".error-text")
                                    .text(emailErrorMsg);
                                $(
                                    ".inline-bark-modal-email-errors .email-suggest-confirm"
                                ).data({
                                    "suggested-email": data.did_you_mean,
                                });
                            } else {
                                $(".inline-bark-modal-errors")
                                    .text(emailErrorMsg)
                                    .removeClass("hide");
                            }

                            $("#inline-bark-new-user-email")
                                .closest(".inline-bark-q-cont")
                                .addClass("new-error");
                            $("#inlineBarkModalSpinner").hide();
                            $("#inlineBarkModalContent").show();

                            // Record the failed email
                            recordFailedEmail(
                                $("#inline-bark-new-user-email").val()
                            );
                        };

                        validationerror = function (error) {
                            $(".inline-bark-modal-errors")
                                .text(error)
                                .removeClass("hide");
                            $("#inline-bark-new-user-email")
                                .closest(".inline-bark-q-cont")
                                .addClass("new-error");
                            $("#inlineBarkModalSpinner").hide();
                            $("#inlineBarkModalContent").show();

                            // Record the failed email
                            recordFailedEmail(
                                $("#inline-bark-new-user-email").val()
                            );

                            //Bark.validate.ukTel($('#inline-bark-new-user-telephone').val(), onValidateUkTel);
                        };

                        validateEmailAddress(
                            $(this).hasClass("ignore-suggest"),
                            successcallback,
                            failurecallback,
                            validationerror
                        );
                    } else if (
                        !cb.validatedTel &&
                        !cb.isLoggedOutExistingBuyer
                    ) {
                        Bark.validate.ukTel(
                            $("#inline-bark-new-user-telephone").val(),
                            onValidateUkTel,
                            true,
                            'buyer'
                        );
                    } else {
                        submit();
                    }
                }
            });

        // Remove errors on focus
        $(".inline-bark-main-content :input")
            .unbind("focus.removeerr")
            .on("focus.removeerr", function () {
                $(".inline-bark-modal-email-errors").addClass("hide");
                $(".inline-bark-main-content .new-error").removeClass(
                    "new-error"
                );
                $(".inline-bark-modal-errors").addClass("hide");
            });

        // Close the modal
        $(".reveal-modal span.bark-icon-cross-o")
            .unbind("click.close")
            .on("click.close", function () {
                cb.hide();
            });

        $("#inlineBarkModal .close-modal-cross")
            .unbind("click.close")
            .on("click.close", function () {
                cb.hide();
            });

        // Close the modal
        $("#inlineBarkModal i.fa-times")
            .unbind("click.close")
            .on("click.close", function () {
                cb.hide();
            });

        $(".choose-category .next-category-chosen")
            .unbind("click.choosecategory")
            .on("click.choosecategory", function () {
                var checked = $(".choose-category input[type=radio]:checked");
                var categoryId = +checked.val();
                var category;
                var questions;
                var categoryPacket;
                if (!categoryId) {
                    $(".inline-bark-modal-errors")
                        .removeClass("hide")
                        .text(_t('buyer_create-bark:error-messages.select-a-category'));
                    return false;
                }
                cb.categoryData.currentCategoryId = categoryId;
                category = cb.categoryData.categories[categoryId];
                if (!category.processed) {
                    // This category hasn't been processed yet
                    category.processed = true;
                    categoryPacket = buildCategorySelectionPacket();
                    // Prepend the category selector. If there are no custom_fields, just use the category_packet
                    questions = category.custom_fields
                        ? Bark.array_unshift(
                              category.custom_fields,
                              categoryPacket
                          )
                        : [categoryPacket];
                    category.custom_fields = questions;
                    cb.processCategory(category);
                } else {
                    cb.questions = category.custom_fields;
                    setQuestionsLength();
                }
                if (cb.lastCategory && cb.lastCategory !== categoryId) {
                    $(".bark-modal-question").not("#bark-question-0").remove();
                }
                cb.lastCategory = categoryId;
                $("#category_name,#category_name_top").val(category.name);
                $("#category_id,#category_id_top").val(categoryId);

                // see if the buyer will be required to enter location later in the process
                var locationSet = true;
                for (var pos = 0; pos < cb.questions.length; pos++) {
                    if (cb.questions[pos].type === "postcode") {
                        locationSet = false;
                        break;
                    }
                }

                Bark.json({
                    url: "/api/tsugcatup/",
                    data: {
                        cid: categoryId,
                        preBarkId: cb.preBarkId,
                        locationSet: locationSet,
                    },
                }).done(function (e) {
                    if (e.success && e.preProcessingFired) {
                        cb.preProcessingFired = true;
                    }
                });

                cb.next();
            });

        $(".photoselect-check label")
            .unbind("click.phcheck")
            .on("click.phcheck", function () {
                $(".inline-bark-modal-errors").addClass("hide");
                var t = $(this);
                var id = t.attr("inpid");
                var checked = $("input#" + id).prop("checked");

                $("input#" + id).prop("checked", !checked);

                if (!checked) {
                    t.parent(".opts").addClass("selectedpic");
                } else {
                    t.parent(".opts").removeClass("selectedpic");
                }
            });

        $(".photoselect-radio label")
            .unbind("click.phselect")
            .on("click.phselect", function () {
                $(".inline-bark-modal-errors").addClass("hide");
                var t = $(this);
                var id = t.attr("inpid");

                var checked = $("input#" + id).prop("checked");

                if (!checked) $("input#" + id).prop("checked", !checked);

                $(".photoselect .opts").each(function () {
                    $(this).removeClass("selectedpic");
                });

                t.parent(".opts").addClass("selectedpic");
            });

        // Auto adjust the height of any textareas
        $(".inline-bark-main-content textarea")
            .unbind("keyup.resize")
            .on("keyup.resize", function () {
                this.style.height = "110px";
                this.style.height = this.scrollHeight + "px";
            });

        $(".inline-bark-q-date .bark-date.no-pikaday")
            .off("change.update")
            .on("change.update", function () {
                var t = $(this);
                var picker = t.data("datepicker");
                picker.date = this.value;
                picker.textval = getLocalDateTime(picker.format, this.value);
            });

        // Add a date picker
        $(".inline-bark-q-date .bark-date").each(function () {
            var formatDate = "l jS F Y";
            if (
                cb.categoryData.bark_country_id &&
                (cb.categoryData.bark_country_id == 237 ||
                    cb.categoryData.bark_country_id == 40)
            ) {
                formatDate = "l F jS, Y";
            }

            var picker;
            var t = $(this);
            var opts = {};
            var mindate;
            var maxdate;
            var today;
            var tzoffset;
            if (!t.data("datepicker")) {
                mindate = t.data("mindate");
                maxdate = t.data("maxdate");
                today = window.strtotime(Bark.date("Y/m/d 00:00:00"));
                tzoffset = -new Date().getTimezoneOffset() * 60;

                if (!t.hasClass("no-pikaday")) {
                    opts = {
                        field: this,
                        from: "D MMM YYYY",
                        onSelect: function () {
                            // Adjust for timezone
                            var pikadaystring = picker.toString();
                            t[0].value = getLocalDateTime(
                                formatDate,
                                pikadaystring
                            );
                            picker.textval = getLocalDateTime(
                                formatDate,
                                pikadaystring
                            );
                        },
                    };
                }

                if (mindate) {
                    mindate = mindate === "today" ? today : strtotime(mindate);
                    if (mindate === false) {
                        mindate = strtotime("now", mindate);
                    }
                    if (mindate) {
                        opts.rawMinDate = Bark.date(
                            "Y-m-d",
                            (mindate + tzoffset) * 1e3
                        );
                        opts.minDate = new Date(opts.rawMinDate);
                    }
                }
                if (maxdate) {
                    maxdate = maxdate === "today" ? today : strtotime(maxdate);
                    if (maxdate === false) {
                        maxdate = strtotime("now", maxdate);
                    }
                    if (maxdate) {
                        opts.rawMaxDate = Bark.date(
                            "Y-m-d",
                            (maxdate + tzoffset) * 1e3
                        );
                        opts.maxDate = new Date(opts.rawMaxDate);
                    }
                }

                if (t.hasClass("no-pikaday")) {
                    if (opts.rawMinDate) {
                        t.attr({ min: opts.rawMinDate });
                    }
                    if (opts.rawMaxDate) {
                        t.attr({ max: opts.rawMaxDate });
                    }
                    t.data({
                        datepicker: {
                            date: "",
                            format: formatDate,
                            toString: function () {
                                return this.date;
                            },
                        },
                    });
                } else {
                    picker = new Pikaday(opts);
                    t.data({ datepicker: picker }); // Make sure that this is only initialised once
                    if(t.val()){
                        const dateFormatted = moment(t.val(), 'dddd Do MMMM YYYY').format('ddd MMMM DD YYYY');
                        picker.setDate(new Date(dateFormatted));
                    }
                }
            }
        });

        $(".bark-date-unsure, .bark-date-na")
            .unbind("change.unsure")
            .on("change.unsure", function () {
                var t = $(this);
                var scope = t.closest(".bark-modal-question");
                if (this.checked) {
                    // Disable the inputs above
                    $("input", scope).not(this).attr({ disabled: "disabled" });
                    $(
                        "input.bark-date-other,input.bark-date-na,input.bark-date-unsure"
                    ).removeAttr("disabled");
                    $(".bark-date-main-cont", scope).css({ opacity: 0.5 });
                    $(".bark-date-time-container", scope).css({ opacity: 0.5 });

                    $("input.bark-date-other,.bark-date-unsure, .bark-date-na")
                        .not(this)
                        .prop("checked", false);
                    $(".bark-date-other-text", scope).hide();
                } else {
                    // Enable the inputs
                    $("input", scope).not(this).removeAttr("disabled");
                    $(".bark-date-main-cont", scope).css({ opacity: 1 });

                    $(".bark-date-time-container", scope).css({ opacity: 1 });
                }
            });

        $(".bark-date-other")
            .unbind("change.other")
            .on("change.other", function () {
                var t = $(this);
                var scope = t.closest(".bark-modal-question");
                if (this.checked) {
                    // Disable the inputs above
                    $("input", scope)
                        .not(this)
                        .not(".bark-date-other-text input")
                        .attr({ disabled: "disabled" });
                    $("input.bark-date-unsure,input.bark-date-na")
                        .removeAttr("disabled")
                        .prop("checked", false);
                    $(".bark-date-main-cont", scope).css({ opacity: 0.5 });
                    $(".bark-date-other-text", scope).show();
                    $(".bark-date-other-text", scope)
                        .children("input")
                        .removeAttr("disabled");
                } else {
                    // Enable the inputs
                    $("input", scope).not(this).removeAttr("disabled");
                    $(".bark-date-main-cont", scope).css({ opacity: 1 });
                    $(".bark-date-other-text", scope).hide();
                }
            });

        $(".check-showmore span")
            .unbind("click.showhidecheck")
            .on("click.showhidecheck", function () {
                var t = $(this);
                var collapse = t.data("collapse") + consts.MAX_VIS_CATEGORIES;
                var scope = t.closest(".bark-modal-question");
                $(".inline-bark-q-checkbox", scope).each(function (i) {
                    if (i < collapse) {
                        $(this).show();
                    }
                });
                t.data({ collapse: collapse });

                if (collapse >= $(".inline-bark-q-checkbox", scope).length) {
                    t.hide();
                }
            });

        $(".phc-showmore span")
            .unbind("click.showhide")
            .on("click.showhide", function () {
                var t = $(this);
                var collapse = t.data("collapse") + consts.MAX_VIS_CATEGORIES;
                var scope = t.closest(".bark-modal-question");
                $(".opts", scope).each(function (i) {
                    if (i < collapse) {
                        $(this).show();
                    }
                });
                t.data({ collapse: collapse });

                if (collapse >= $(".opts", scope).length) {
                    t.hide();
                }
            });

        $(".radio-showmore span")
            .unbind("click.showhide")
            .on("click.showhide", function () {
                var t = $(this);
                var collapse = t.data("collapse") + consts.MAX_VIS_CATEGORIES;
                var scope = t.closest(".bark-modal-question");
                $(".inline-bark-q-radio", scope).each(function (i) {
                    if (i < collapse) {
                        $(this).show();
                    }
                });
                t.data({ collapse: collapse });

                if (collapse >= $(".inline-bark-q-radio", scope).length) {
                    t.hide();
                }
            });

        $(".choose-category-showmore span")
            .unbind("click.showhide")
            .on("click.showhide", function () {
                var t = $(this);
                if (t.hasClass("showing")) {
                    // Hide the extras
                    $(".choose-category.pickbyimage > .opts").each(function (
                        i
                    ) {
                        if (i >= consts.MAX_VIS_CATEGORIES) {
                            $(this).css({ display: "none" });
                        }
                    });
                    t.text(_t('buyer_create-bark:links.show-more-link')).removeClass("showing");
                    $("body,html").animate({ scrollTop: 0 }, 800); // Scroll to top
                } else {
                    $(".choose-category.pickbyimage > .opts").css({
                        display: "inline-block",
                    });
                    t.text(_t('buyer_create-bark:links.show-less-link')).addClass("showing");
                }
            });

        // Continue button
        $(".inline-bark-btn-continue")
            .off("click.cont")
            .on("click.cont", function () {
                const currentQuestion = cb.questions[cb.pos];
                if (!cb.isLoggedOutExistingBuyer || currentQuestion?.name === 'contact_preferences') {
                    cb.next();
                }
            });

        // Back button
        $(".inline-bark-btn-back")
            .unbind("click.prev")
            .on("click.prev", function () {
                cb.prev();
                createBarkTracking.logModalPageEvent(
                    $("#category_id").val(),
                    cb.preBarkId,
                    cb.questions[cb.pos].type,
                    "back"
                );
            });

        $("#inline-bark-close")
            .off("click.closemodal")
            .on("click.closemodal", function () {
                ga("send", "pageview", window.location.pathname);
            });

        // Telephone fields
        $(".telephone-field")
            .off("keyup.filterinput")
            .on("keyup.filterinput", function (e) {
                const BACKSPACE = 8;
                const LEFT = 37;
                const RIGHT = 39;

                if ([BACKSPACE, LEFT, RIGHT].includes(e.keyCode)) {
                    return false;
                }

                this.value = new AsYouType((Bark.ENV.locale || 'GB').toUpperCase()).input(
                    this.value,
                );
            })
            // Handle a paste
            .off("paste.cleanseinput")
            .on("paste.cleanseinput", function (e) {
                try {
                    var cleansedInput = (e.originalEvent || e).clipboardData
                            .getData("text/plain")
                            .replace(/[^0-9\+\(\) -]/g, ""),
                        startPosition = this.selectionStart,
                        endPosition = this.selectionEnd,
                        currentValue = $(this).val();
                    $(this).val(
                        currentValue.slice(0, startPosition) +
                            cleansedInput +
                            currentValue.slice(endPosition)
                    );
                    e.preventDefault();
                    return false;
                } catch (ignore) {}
            });
        // BrowseExperiment navigations
        cb.BrowseProjectExperiment?.bindCreateBarkModalEventListeners();

        bindEventsToLocationControls();
    };

    /**
     * Show the additional details modal if appropriate, otherwise redirect to the dashboard
     * @param projectIdHash
     */
    cb.showAdditionalDetailsOrRedirect = function (projectIdHash) {
        if (cb.shouldShowAdditionalDetails()) {
            cb.showAdditionalDetails(projectIdHash);
        } else {
            cb.redirectToProject();
        }
    };

    // Main
    $(function () {
        window.app_locale = $('meta[name="app_locale"]').attr("content");
        window.onbeforeunload = isBuyerModalDirty;

        // if we're on a pre-selected category page, record the pre-set in case an error causes it to be cleared
        cb.isPresetCategory =
            $("#category_id").val() && !$("#category_name").is(":visible")
                ? $("#category_id").val()
                : false;

        // Unbind the back button, just in case we've overridden it (TenderBrowse)
        $(".inline-bark-btn-back").off("click");
        bindEventsToLocationControls();
    });

    function bindEventsToLocationControls() {
        $(".postcode-lookup-container").each(function () {
            var container = $(this);
            $("a", container)
                .off("click.get-location")
                .on("click.get-location", function () {
                    var link = $(this);
                    if (link.hasClass("disabled")) {
                        return;
                    }
                    link.addClass("disabled");
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var getLocationUrl =
                                "/api/get-location/" +
                                position.coords.latitude +
                                "/" +
                                position.coords.longitude +
                                "/";
                            $.ajax({
                                url: getLocationUrl,
                                type: "GET",
                                dataType: "JSON",
                                success: function (data) {
                                    link.removeClass("disabled");
                                    if (data.success === true) {
                                        if (
                                            cb.categoryData.bark_country_id ===
                                            parseInt(data.result.countryId, 10)
                                        ) {
                                            $("input.postcode-auto").val(
                                                data.result.name
                                            );
                                            $("input[name='postcode_id']").val(
                                                data.result.id
                                            );
                                            $(
                                                "input[name='postcode_type']"
                                            ).val("c");
                                        } else {
                                            link.addClass("error").text(_t('buyer_create-bark:error-messages.location-outside-country'));
                                        }
                                    }
                                },
                                error: function (error) {
                                    link.addClass("error").text(_t('buyer_create-bark:error-messages.location-not-found'));
                                },
                            });
                        },
                        function (error) {
                            link.addClass("error").text(_t('buyer_create-bark:error-messages.location-not-found'));
                        }
                    );
                    return false;
                });
        });
        $(".button-confirm-location-yes")
            .off("click.confirmLocationResponse")
            .on("click.confirmLocationResponse", function () {
                $(".confirm-location-yes").prop("checked", true);
                cb.next();
            });
        $(".button-confirm-location-no")
            .off("click.confirmLocationResponse")
            .on("click.confirmLocationResponse", function () {
                $(".confirm-location-no").prop("checked", true);
                cb.next();
            });
        $(".change-location-link")
            .off("click.confirmLocationResponse")
            .on("click.confirmLocationResponse", function () {
                $(".confirm-location-no").prop("checked", true);
                cb.next();
            });
    }

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    function isFrontendV2WithBootstrap() {
        return $(".reveal-modal").length === 0;
    }

    cb.isFrontendV2WithBootstrap = isFrontendV2WithBootstrap;

    function isFullyInViewport(elem) {
        var bounding = elem.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <=
                (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    cb.isFullyInViewport = isFullyInViewport;

    const getExperimentTrackingData = function ({ experiments }) {
        if (!experiments) return {};

        let experimentsArray = [];
        Object.keys(experiments).map((name) => {
            experimentsArray.push({
                name: name,
                data: experiments[name],
            });
        });

        let experimentParams = {};

        // old school loop for ie 11 support
        for (var i = 0; i < experimentsArray.length; i++) {
            let experiment = experimentsArray[i];
            experimentParams = {
                ...experimentParams,
                [`${experiment.name}`]: `variant-${experiment.data?.variant}`,
            };
        }

        return experimentParams;
    };

    /**
     * =================================
     * skippable questions
     * =================================
     */

    /**
     * get skipped data question
     */
    cb.getPageSkippedQuestions = () => {
        // get data
        var selected = [];
        $("input.bark-page-question:checked").each(function () {
            const questionName = $(this).data("questionCode");
            const exists = selected[questionName];
            const inputValue = $(this).val();
            if (exists) {
                selected[questionName].value.push(inputValue);
            } else {
                selected[questionName] = {
                    name: questionName,
                    value: [inputValue],
                };
            }
            if (
                inputValue === "other" &&
                $(this).parent().find(".bark-page-other-question").val() !== ""
            ) {
                selected[questionName + "_other"] = {
                    name: questionName + "_other",
                    value: $(this)
                        .parent()
                        .find(".bark-page-other-question")
                        .val(),
                };
            }
        });

        return selected;
    };
}
