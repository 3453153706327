import { barkApi } from '../components/legacy-scripts/barklib';

export async function getUserExperiments() {
	return await barkApi(
		'/user/experiments',
		{},
		() => {},
		() => {},
		'GET',
	);
}
