import { bugsnagClientNotify } from "@bark/bark-core";

export const getRecaptchaToken = () => {
  return new Promise((resolve, reject) => {
    const keyId = $('#ml-recaptcha-script').data('key');

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(keyId, { action: 'login' });
        resolve(token);
      } catch (error) {
        bugsnagClientNotify('getRecaptchaToken not successful', error);
        reject(error);
      }
    });
  });
};
