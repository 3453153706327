import { setupDynamicPostCodeAutocomplete, _t } from '@bark/bark-core';
import { initBuyerAppBranchIO } from "@bark/bark-core/src/helpers/";

export default function () {
	var accordianExpanded = false;
	var sellerCompanyNameMaxHeight = 0;

	$(window).resize(function () {
		showOrHideReadMore();
	});

	$(document).ready(function () {
		showOrHideReadMore();

		if ($('#postcode-auto').length > 0) {
			setupDynamicPostCodeAutocomplete();
		}

		if ($('#bark_mode').val() === 'directory-top-level-cat') {
			$('#project-create-form #category_id').val('');
		}

		$('.read-more-qa').click(function (event) {
			event.preventDefault();
			$('.question-answer-block.hidden').slideToggle();
			if ($(this).hasClass('less')) {
				$(this).removeClass('less');
				$(this).html(_t('directory:question-and-answers.show-more'));
			} else {
				$(this).addClass('less');
				$(this).html(_t('directory:question-and-answers.show-less'));
			}
		});

		$('.expand-all').click(function (event) {
			var scope = $(this).closest('.directory-accordion-container');

			event.preventDefault();

			if (!accordianExpanded) {
				$('.directory-accordion .collapse', scope).removeAttr('data-parent');
				$('.directory-accordion .collapse', scope).collapse('show');

				accordianExpanded = true;
				$(this).text(_t('directory:accordion.collapse-all'));
			} else {
				$('.directory-accordion .collapse', scope).attr('data-parent', '#accordion');
				$('.directory-accordion .collapse', scope).collapse('hide');

				accordianExpanded = false;
				$(this).text(_t('directory:accordion.expand-all'));
			}
		});

		$('.read-more-link').click(function (event) {
			event.preventDefault();
			var self = $(this);

			var readMoreSection = $(this).closest('.read-more-section');

			if (self.hasClass('super-long')) {
				readMoreSection
					.find('.alternative-link')
					.removeClass('d-none')
					.addClass('d-block');
			}
		});

		var readMoreSections = document.getElementsByClassName('read-more-section');

		if (readMoreSections.length > 0) {
			[].slice.call(readMoreSections).forEach(function (section) {
				if (isOverflowing(section.getElementsByClassName('collapse')[0])) {
					section.getElementsByClassName('read-more-link')[0].classList.add('overflow');
				}

				if (needsAlternateCta(section.getElementsByClassName('collapse')[0])) {
					section.getElementsByTagName('p')[0].classList.add('super-long');
					section.getElementsByClassName('read-more-link')[0].classList.add('super-long');
				}
			});
		}

		$('.seller-name-wrapper').each(function () {
			if ($(this).height() > sellerCompanyNameMaxHeight) {
				sellerCompanyNameMaxHeight = $(this).height();
			}
		});

		$('.seller-name-wrapper').height(sellerCompanyNameMaxHeight);

		$('.seller-collapsible-qa-container').each(function () {
			var container = $(this);
			$('a.read-more-collapsible-qa', container).on('click', function (e) {
				container.toggleClass('expanded');
				$(this).text(
					container.hasClass('expanded')
						? _t('directory:long-text.read-less')
						: _t('directory:long-text.read-more'),
				);
				e.preventDefault();
				return false;
			});
		});

		$('.company-avatar-column img.rounded-circle')
			.not('.lazy')
			.on('error', function () {
				fallbackAvatarImage($(this));
			});
		$('.img-wrapper.seller-avatar img.rounded-circle')
			.not('.lazy')
			.on('error', function () {
				fallbackAvatarImage($(this));
			});
		$('.img-wrapper.card-avatar img.rounded-circle')
			.not('.lazy')
			.on('error', function () {
				fallbackAvatarImage($(this));
			});
		$('.company-avatar-column img.rounded-circle').each(function () {
			if (
				$(this).hasClass('error') ||
				($(this).get()[0].complete &&
					$(this).get()[0].naturalWidth === 0 &&
					$(this).get()[0].naturalHeight === 0)
			) {
				// dnr = Do Not Report - this is used for bugsnag as this annoyingly throws a bugsnag error
				$(this).trigger('error', 'dnr');
			}
		});
		$('.img-wrapper.seller-avatar img.rounded-circle').each(function () {
			if (
				$(this).hasClass('error') ||
				($(this).get()[0].complete &&
					$(this).get()[0].naturalWidth === 0 &&
					$(this).get()[0].naturalHeight === 0)
			) {
				$(this).trigger('error', 'dnr');
			}
		});
		$('.img-wrapper.card-avatar img.rounded-circle').each(function () {
			if (
				$(this).hasClass('error') ||
				($(this).get()[0].complete &&
					$(this).get()[0].naturalWidth === 0 &&
					$(this).get()[0].naturalHeight === 0)
			) {
				$(this).trigger('error', 'dnr');
			}
		});

		$('.js-faq-accordion-header')
			.off('click.trx')
			.on('click.trx', function () {
				var id = $(this).data('id');
				$.post('/api/elo/', {
					c: 'directory-faq',
					n: 'click',
					i: 'qa-' + id,
				});
			});
        initBuyerAppBranchIO(30);
	});

	var alt = '';

	function fallbackAvatarImage(el) {
		el.addClass('d-none');
		el.parent().find('.no-image-avatar').removeClass('d-none');
		el.parent().find('.no-image-avatar').addClass('d-inline-flex');
		if (alt !== el.prop('alt')) {
			alt = el.prop('alt');
			bugsnagClient.notify(new Error('seller-profile-image:failed'), {
				metaData: { 'alt-text': alt, location: 'directory' },
				severity: 'info',
			});
		}
	}
	function showOrHideReadMore() {
		// If there is a collapsable element and it is not expanded then you can think about hiding the read more
		if (
			$('.seller-collapsible-qa').length > 0 &&
			!$('.seller-collapsible-qa-container').hasClass('expanded')
		) {
			var collapsable = document.querySelector('.seller-collapsible-qa');
			var contentWrapper = document.querySelector('.questions-wrapper');
			var maxHeight = parseInt(getComputedStyle(collapsable).maxHeight, 10);

			if (contentWrapper.scrollHeight <= maxHeight) {
				document.querySelector('.read-more-collapsible-qa').classList.add('d-none');
			} else {
				document.querySelector('.read-more-collapsible-qa').classList.remove('d-none');
			}
		}
	}

	function isOverflowing(element) {
		return (
			element.scrollHeight > element.clientHeight ||
			element.scrollWidth > element.clientWidth
		);
	}

	function needsAlternateCta(element) {
		return element.scrollHeight > 400;
	}

	function equalizeCompanyNameHeigths() {}
}
