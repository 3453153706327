import { tracking } from '../analytics/bark-tracking';

function status(response) {
	if (response.status >= 200 && response.status < 300) {
		return Promise.resolve(response);
	} else {
		return Promise.reject(new Error(response.statusText));
	}
}

const json = (response) => response.json();

function hasHadAReactLoadError(appName) {
    if (!window.appLoadedFallback) {
        window.appLoadedFallback = {}
    }
	return window.appLoadedFallback[appName];
}


export const validateProject = (createBarkFormData) => {
	const { locale, lang } = Bark.ENV;

	return fetch(`/${lang}/${locale}/validate-project/`, {
		method: 'POST',
		body: createBarkFormData,
		redirect: 'follow',
		credentials: 'same-origin',
		mode: 'same-origin',
	})
		.then(status)
		.then(json);
};
