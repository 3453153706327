import { bugsnagClientNotify } from '../bugsnag/bugsnag-client';

export function setCookie({ name, value, expiryDays, overwrite = false }) {
	// Creating this variable rather than using a default as I want it to be a session only
	// cookie if people don't provide expiryDays
	const computedDays = expiryDays ?? 30;

	try {
		if (!overwrite) {
			const existingCookie = getCookie(name);
			if (existingCookie !== null) return;
		}

		const date = new Date();
		date.setTime(date.getTime() + computedDays * 24 * 60 * 60 * 1000);
		const expires =
			typeof expiryDays === 'undefined' ? '' : `; expires=${date.toUTCString()}`;
		document.cookie = `${name}=${value}${expires}; path=/`;
	} catch (e) {
		bugsnagClientNotify(`Failed to store the ${name} cookie`, { error: e });
	}
}

export function getCookie(name) {
	const nameEQ = `${name}=`;
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

export function deleteCookie(name) {
	setCookie(name, '', -1);
}
