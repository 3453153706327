
export const showAlertAndHideAfterSeconds = (queryElement, seconds = 2) => {
  const element = $(queryElement);

  //show
  element.show();

  //hide
  element.fadeTo(seconds * 1000, 500).slideUp(500, function(){
    element.slideUp(500);
  });

}
export const showAlertEmailError = () => {
  const emailElement = document.querySelector('#email');
  const emailInputErrorElement = document.querySelector('.error-email');
  emailElement.classList.add('is-invalid');
  emailInputErrorElement.classList.remove('hidden');
}
export const showAlertMagicLinkError = async (text = null) => {
  await useNamespace('common_login');
  const formErrorElement = document.querySelector('.error-box-form');
  const formErrorTextElement = formErrorElement.querySelector('.error');
  formErrorElement.classList.remove('hidden');
  if(text){
    formErrorTextElement.innerHTML = text;
  } else {
    formErrorTextElement.innerHTML = _t('common_login:login-form.magic-link-error')
  }
}

export const clearAllAlerts = () => {
  const emailElement = document.querySelector('#email');
  const emailInputErrorElement = document.querySelector('.error-email');
  const formErrorElement = document.querySelector('.error-box-form');
  emailElement.classList.remove('is-invalid');
  emailInputErrorElement.classList.add('hidden');
  formErrorElement.classList.add('hidden');
}

export const getTranslatedAlertTextFromErrorCode = async (errorCode = null) => {
    await useNamespace('common_login');
    switch (errorCode) {
        case 'email_not_found':
            return _t('common_login:errors.email-not-found');
        case 'could_not_create_token':
            return _t('common_login:errors.could-not-create-token');
        case 'token_expired':
            return _t('common_login:errors.link-expired');
        case 'invalid_link':
            return _t('common_login:errors.invalid-link');
        case 'link_already_used':
            return _t('common_login:errors.link-already-used');
    }
    return _t('common_login:errors.unknown');
}
