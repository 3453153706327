export default function () {

    $('span.mob-init-search').on('click', function(e) {
        $('#modalSmall .modal-body').html('');
        $('#inpMobSearch').val('');
        $("#modalSmall")
            .modal('show')
            .off('shown.bs.modal')
            .on('shown.bs.modal', function () {
                $('#inpMobSearch').focus();
            });
    });

    $(document).on('click', 'span.mob-search-result', function (e) {
        e.preventDefault();
        var t = $(this);
        var categId = t.data('caid');

        if (!categId)
            return false;

        $('#category_id').val(categId);
        $('#postcode_id').val('-1');
        $('#postcode_type').val('-1');
        $('#category_name_hidden').val('');
        $("#modalSmall").modal('hide')
        $('#bark_submit').trigger('click');

    });

    $('#inpMobSearch').on('keyup', function(e) {

        var t = $(this);
        var value = t.val();

        if (value.length < 2) {
            return false;
        }

        var payload = {
            coid: window.Bark.ENV.ccid,
            term: value,
        };

        Bark.api('services/', payload, function (resp) {
            if (!resp.status)
                return false;

            var data = resp.data;

            if (data.length == 0) {
                $('#modalSmall .modal-body').html($('<p>').css('color', 'red').text(_t('common:no-matching-services-found-warning')));
                return false;
            }

            var html = '';
            for(var i=0; i< data.length; i++) {
                html = html + '<span data-caid="'+data[i].id+'" class="d-flex mob-search-result">'+data[i].name+'</span>';
            }
            $('#modalSmall .modal-body').html(html);

        }, function(e) {
            console.log('fail');
            console.log(e);
        }, 'GET');

    });

    // Prevent the page behind the nav menu from scrolling
    $('#navbarSupportedContent').on('shown.bs.collapse', function () {
        if($('#__rctSellerEnquiriesBanner').length && !!$('#__rctSellerEnquiriesBanner').html().length){
            $("#navbarSupportedContent").css('top', $('#bark-header').height());
        }
        $('body').addClass('no-scroll');
    });

    $('#navbarSupportedContent').on('hidden.bs.collapse', function () {
        $('body').removeClass('no-scroll');
    });
}
