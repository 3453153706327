import { barkApi, bugsnagClientNotify } from "@bark/bark-core";

const getRecaptchaToken = () => {
    return new Promise((resolve, reject) => {
        try {
            const keyId = $('#ml-recaptcha-script').data('key');
            grecaptcha.enterprise.ready(async () => {
                try {
                    const token = await grecaptcha.enterprise.execute(keyId, { action: 'magic_link' });
                    resolve(token);
                } catch (e) {
                    reject()
                }
            });
        } catch (e) {
            reject()
        }
    })
}

export const sendMagicLink = async (email) => {
    const captchaToken = await getRecaptchaToken();
    return new Promise(resolve => {
        barkApi(
            `auth/get-mgk`,
            {
                email,
                captchaToken,
                platform: 'web',
            },
            (res) => {
                resolve(res);
            },
            (error) => {
                if (error?.status === 422) {
                    resolve(false);
                } else {
                    console.error(error);
                    bugsnagClientNotify('sendMagicLink not successful', error);
                    resolve(false);
                }
            },
            'POST'
        );
    })
};

export const resendByExpiredMagicLink = async (magicToken) => {
    const captchaToken = await getRecaptchaToken();
    return new Promise(resolve => {
        barkApi(
            `auth/get-mgk/resend/`,
            {
                mgk: magicToken,
                captchaToken,
                platform: 'web',
            },
            (res) => {
                resolve(res);
            },
            (error) => {
                console.error(error);
                bugsnagClientNotify('sendMagicLink not successful', error);
                resolve(false);
            },
            'POST'
        );
    })
};
