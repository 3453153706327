export const showLoading = () => {
  const btnSubmitElement = document.querySelector('.btn-submit');
  const btnLoadingElement = document.querySelector('.btn-loading');
  btnSubmitElement.classList.add('hidden');
  btnLoadingElement.classList.remove('hidden');
};

export const hideLoading = () => {
  const btnSubmitElement = document.querySelector('.btn-submit');
  const btnLoadingElement = document.querySelector('.btn-loading');
  btnSubmitElement.classList.remove('hidden');
  btnLoadingElement.classList.add('hidden');
};
