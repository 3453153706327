import { initChameleon } from '@bark/bark-core';
import { DirectoryPagination, DirectoryV3, DirectoryOpenBark } from './helpers';
export const isDirectory = !!document.querySelector('.directory-page');

export const initDirectory = () => {
	console.log('directory page start...');

	/* run scripts */
	DirectoryPagination();
	DirectoryV3();
	DirectoryOpenBark();

	/* run services */
	initChameleon();
};
