/**
 * Returns an object containing key-value pairs from the document cookie
 * where the key matches specified patterns used for purchase attribution.
 * @returns {Object.<string, string>} An object containing the filtered key-value pairs.
 */
export const getLinkAttribution = () => {
    const cookies = document.cookie.split(';');
    const attributionCookies = {};

    const patterns = [
        '^utm_',    // ^ means starts with
        '^trk_',
        '^fbclid',
        '^_fbp$',   // $ means ends with
        '^_fbc$',
        '^aff_trk_[1-4]',
        '^campaign$',
    ];

    cookies.forEach(cookie => {
        const [key, value] = cookie.trim().split('=');
        const trimmedKey = key.trim();
        patterns.forEach(pattern => {
            if (trimmedKey.match(new RegExp(pattern))) {
                attributionCookies[trimmedKey] = value;
            }
        });
    });

    return attributionCookies;
};
