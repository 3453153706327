import { isChameleonActive } from './helpers/check';
import { getUserData } from './helpers/user';

export const initChameleon = () => {
  if (isChameleonActive()) {
    const userId = window?.Bark?.USER?.userId || null;
    const secureUserId = window?.Bark?.USER?.secureUserIdForChameleon || null;

    if (userId && secureUserId) {
        const chameleonExperiments = window?.Bark?.USER?.chameleonExperiments || {};
        const chameleonUserData = window?.Bark?.USER?.chameleonUserData || {};

        chmln.identify(userId, {
          ...getUserData(),
          ...chameleonExperiments,
          ...chameleonUserData
      });
    }

  }
};
